<template lang="pug">
v-form(v-model="valid" ref="form")
  v-row
    v-col(cols="6")
      v-autocomplete(
        :items="registrationCircles"
        :item-text="getCircle"
        item-value="codigo"
        :rules="circleRules"
        v-model="selectedCircle"
        label="Seleccione el circulo"
        required)

    v-col(cols="6")
      v-text-field(
        v-model="recordText"
        :rules="nameRules"
        label="Número matricula"
        required)
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'FmiPicker',
  model: {
    prop: 'fmi',
    event: 'change'
  },
  props: {
    minCharacters: {
      type: Number,
      default: 3
    },
    label: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      nameRules: [
        v => !!v || 'Matrícula requerida',
        v => v.length <= 10 || 'Matrícula debe tener menos de 10 caracteres',
        v => /^[0-9]*$/.test(v) || 'Solo debe contener numeros',
      ],
      circleRules: [
        v => !!v || 'Escoja un circulo registral',
      ],
      selectedCircle: null,
      recordText: '',
      fmi: null,
      valid: false,
    };
  },
  mounted() {
    this.getCircles();
  },
  computed: {
    ...mapState('vur', ['registrationCircles']),
  },
  watch: {
    selectedCircle() {
      this.setFmi();
    },
    recordText() {
      this.setFmi();
    },
  },
  methods: {
    ...mapMutations('cadastral', ['CLEAR_CITIES']),
    ...mapActions('cadastral', ['getCities']),
    ...mapActions('vur', ['getCircles']),
    getCircle(e) {
      return `${e.codigo} - ${e.nombre}`
    },
    setFmi() {
      this.fmi = `${this.selectedCircle}-${this.recordText}`;
      this.$emit('change', this.fmi);
    },
    validate() {
      return this.$refs.form.validate();
    },
  }
};
</script>
  