import CadastralService from '@/services/CadastralService.js';

const files = {
  namespaced: true,
  state: {
    filteredCities: [],
  },
  getters: {
    processedFiles(state) {
      return state.fileList.filter((x) => x.processed === true);
    },
    rawFiles(state) {
      return state.fileList.filter((x) => x.processed === false);
    }
  },
  mutations: {
    CLEAR_CITIES(state) {
      state.filteredCities = [];
    },
  },
  actions: {
    async getCities({ commit, state }, search) {
      try {
        const token = localStorage.getItem('token');
        const response = await CadastralService.getCities(search, token);
        if (response) {
          state.filteredCities = response.data;
        }
      } catch (error) {
          commit('ui/SHOW_ALERT',{
            alertMessage: `Ocurrio un error al conseguir las ciudads
              (${error.response.status} - ${error.response.data.detail})`,
            alertType: 'error'
          }, {root: true});
      }
    }
  }
};
  
export default files;
