<template lang="pug">
v-container(class="text-center" fluid)
  h1 Consulta masiva de Folios VUR (máximo 50)
  vur-massive-manager
</template>

<script>
import VurMassiveManager from '@/components/vur/VurMassiveManager';
import { mapMutations } from 'vuex';

export default {
  name: 'VurMassiveSearch',

  components: {
    VurMassiveManager
  },

  data: () => ({
  }),  

  mounted() {
    console.log(this.$route);
    // constante para conservarcertificados cuamdo viene de descargar pdfs
    const preserveList = this.$route.params.preserveList || false;
    if (!preserveList) {
      // limpiar certificados
      this.CHANGE_VALUE({
        varName: 'certificates',
        value: []
      });
    }
    this.CHANGE_VALUE({
      varName: 'loadingVur',
      value: false
    });
  },

  methods: {
    ...mapMutations('vur', ['CHANGE_VALUE']),
  }
};
</script>
  