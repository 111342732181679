import apiClient from './config.js';

export default {
  async getCities(search, token) {
    apiClient.interceptors.request.use((config) => {
      // config.headers.post['Content-Type'] = 'multipart/form-data';
      config.headers.get['Accept'] = 'application/json',
      config.headers.get['Content-Type'] =  'application/json'
      config.headers.get['Authorization'] = `Bearer ${token}`;
      return config;
    });
    return await apiClient.get(`political_division/cities?search=${search}`);
  },
};