import apiClient, { authHeaders } from './config.js';

export default {
  // Servicio para traer las variables de los reportes de folios disponibles
  async getFolioVariables(token) {    
    return await apiClient.get('reports/variables', authHeaders(token));
  },
  // Servicio para traer las plantillas de reortes masivos de folios
  async getFolioReports(token) {    
    return await apiClient.get('reports/templates', authHeaders(token));
  },
  async sendReport(file, selectedVariables, reportId, token) { 
    /**
     * Servicio que envia los datos para generar el reporte
     *
     * @param {file} File el archivo excel con el listado
     * @param {Array} selectedVariables listado de variables seleccionadas
     * @param {token} string token de usuario generado por la aplicacion
     * @returns {HttpResponse} No retorna nada
     * @example
     * sendReport(
     *  File(),
     *  [
     *    {
     *      variable: 'numero_matricula',
     *      selected_option: null
     *    }
     *  ],
     * 'dhlfgdnfudygufymguyfgf')
     * @author Luis Merizalde <luis.merizalde@arcerojas.com>
     */
    const formData = new FormData();
    formData.append('file', file);
    formData.append('selected_variables', JSON.stringify(selectedVariables));
    formData.append('report_id', reportId);
    apiClient.interceptors.request.use((config) => {
      config.headers = { ...{ 
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }, ...config.headers };
      return config;
    });
    return await apiClient.post('reports/generate_report', formData);
  },
};