<template lang="pug">
v-container(fill-height fluid)
  v-card(class="mx-auto my-auto meteo-form pa-4 text-center" max-width="400" min-width="350" outlined)
    v-alert(
      v-if="errorMessage"
      border="top"
      color="red lighten-2"
      dark) {{errorMessage}}
    v-form(ref="form" v-model="valid" lazy-validation)
      v-text-field(
        v-model="email"
        :rules="nameRules"
        label="Correo"
        required)
      v-text-field(
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required]"
        :type="show ? 'text' : 'password'"
        name="input-10-2"
        label="Contraseña"
        hint="At least 8 characters"
        v-model="password"
        class="input-group--focused"
        @click:append="show = !show")
      v-btn(
        :disabled="!valid"
        color="success"
        class="mr-4"
        @click="login") Ingresar
</template>

<script>
export default {
  name: 'LoginForm',
  data: () => ({
    errorMessage: '',
    email: '',
    valid: true,
    show: false,
    password: '',
    rules: {
      required: (value) => !!value || 'Campo obligatorio.',
      min: (v) => v.length >= 8 || 'Minimo 8 caracteres',
      emailMatch: () => ('The email and password you entered don\'t match'),
    },
    nameRules: [
      (v) => !!v || 'El nombre de usuario es obligatorio',
    ],
  }),
  methods: {
    login() {
      console.log('antes validate', this.valid);
      const isValid = this.$refs.form.validate();
      if (isValid === false) {
        return;
      }
      const { email, password } = this;
      this.$store.dispatch('auth/login', { email, password })
        .then((resp) => {
          console.log('entro login then', resp);
          if (resp !== 'success') {
            this.errorMessage = resp;
          } else {
            // si el logueo es exitoso volver a recargar las
            // aplicaciones para obtener las correspondientes
            // al usuario en cuestión
            this.errorMessage = '';
            // ingresar a la lista de folios
            this.$router.push({name: 'FoliosList'});
          }
        })
        .catch((err) => {
          console.log('entro login catch', err);
        });
    }
  }
}
</script>
