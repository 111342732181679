<template lang="pug">
v-container(fill-height fluid)
  v-card(class="mx-auto my-auto meteo-form pa-4 text-center" max-width="400" min-width="350" outlined)
    v-alert(
      v-if="errorMessage"
      border="top"
      color="red lighten-2"
      dark) {{errorMessage}}
    v-form(ref="form" v-model="valid" lazy-validation)
      v-text-field(
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required]"
        :type="show ? 'text' : 'password'"
        name="input-10-2"
        label="Contraseña"
        hint="At least 8 characters"
        v-model="password"
        class="input-group--focused"
        @click:append="show = !show")
      v-text-field(
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, rules.password]"
        :type="show1 ? 'text' : 'password'"
        name="input-10-2"
        label="Nueva Contraseña"
        v-model="newPassword"
        class="input-group--focused"
        @click:append="show1 = !show1")
      v-text-field(
        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required]"
        :type="show2 ? 'text' : 'password'"
        name="input-10-2"
        label="Repetir Contraseña"
        hint="At least 8 characters"
        v-model="confirmPassword"
        class="input-group--focused"
        @click:append="show2 = !show2")
      v-btn(
        :disabled="!valid"
        color="success"
        class="mr-4"
        @click="login") Cambiar
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'LoginForm',
  data: () => ({
    errorMessage: '',
    valid: true,
    show: false,
    show1: false,
    show2: false,
    password: '',
    newPassword: '',
    confirmPassword: '',
    rules: {
      required: (value) => !!value || 'Campo obligatorio.',
      password: (v) => /[0-9a-zA-Z]{8,}/g.test(v) || 'Mínimo 8 caracteres entre letras y numeros',
      min: (v) => v.length >= 8 || 'Minimo 8 caracteres',
      emailMatch: () => ('The email and password you entered don\'t match'),
    },
    nameRules: [
      (v) => !!v || 'El nombre de usuario es obligatorio',
    ],
  }),
  methods: {
    ...mapMutations('auth', ['LOGOUT']),
    login() {
      console.log('antes validate', this.valid);
      this.errorMessage = '';
      const isValid = this.$refs.form.validate();
      if (isValid === false) {
        return;
      }
      if (this.newPassword !== this.confirmPassword) {
        this.errorMessage = 'Las nuevas contraseñas no son iguales';
        return
      }
      const { password, newPassword } = this;
      this.$store.dispatch('auth/changePassword', { password, newPassword })
        .then((resp) => {
          console.log('entro changePassword then', resp);
          if (resp !== 'success') {
            this.errorMessage = resp;
          } else {
            // si el logueo es exitoso volver a recargar las
            // aplicaciones para obtener las correspondientes
            // al usuario en cuestión
            this.errorMessage = '';
            // ingresar a la lista de folios
            this.LOGOUT();
          }
        })
        .catch((err) => {
          console.log('entro login catch', err);
        });
    }
  }
}
</script>
    