<template lang="pug">
v-row
  v-col(cols="6")
    v-img(src="@/assets/report.png" max-width="200")
  v-col(cols="6")
    v-form(ref="form")
      v-text-field(
        v-model="reportOwner"
        :rules="rules"
        label="Persona que realiza el reporte")
      v-select(
        v-model="selectedProject"
        :items="reports"
        item-text="customer_name"
        item-value="id"
        label="Formato del reporte")
      v-text-field(
        v-model="reportProject"
        :rules="rules"
        label="Proyecto para el que se realiza el reporte")
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'ReportPreview',
  computed: {
    reportOwner: {
      get() {
        return this.$store.state.restrictions.reportOwner;
      },
      set(value) {
        return this.$store.commit('restrictions/CHANGE_VALUE', {
          value,
          varName: 'reportOwner'
        });
      }
    },
    reportProject: {
      get() {
        return this.$store.state.restrictions.reportProject;
      },
      set(value) {
        return this.$store.commit('restrictions/CHANGE_VALUE', {
          value,
          varName: 'reportProject'
        });
      }
    },
    ...mapState('restrictions', ['reports']),
    selectedProject:{
      get(){
        return this.$store.state.restrictions.selectedProject;
      },
      set(value){
        return this.$store.commit('restrictions/CHANGE_VALUE', {
          varName: 'selectedProject', 
          value,
        })
      }
    }
  },
  data() {
    return {
      selected: [],
      rules: [],
      projectOptions: []
    };
  },
  methods: {
    handleReportChange() {
      // Emite un evento con el índice del reporte seleccionado
      this.$emit('reportSelected', this.selectedProject);
    },
  },
}
</script>

<style scoped>
.identification-card {
  border-radius: 15px!important;
}
.text-id-1 {
  font-size: 0.8rem;
  font-weight: 500;
}
.text-id-2 {
  font-size: 0.65rem;
  font-weight: 500;
}
.text-id-3 {
  font-size: 0.65rem;
  font-weight: 300;
}
.text-id-info {
  font-weight: 500;
}
.ellipse {
  background: #F57F17;
  width: 100%;
  height: 100px;
  bottom: 0px;
  z-index: 0;
  border-top-left-radius: 75%;
  border-top-right-radius: 30%;
  left: 0px;
  position: absolute;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
.overlap {
  position: absolute;
  z-index: 1;
}
</style>
  