<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Realizar Consulta Manual</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" @submit.prevent="handleSubmit">
          <h2 class="Titulo">Información Persona A Consultar</h2><br>
          <div class="informacion">
            <span>Nombre Completo: <br> {{ fullName }}</span><br><br>
            <span>Identificación: <br> {{ fullIdentification || 'No se ha digitado número de identificación' }}</span>
          </div>
          <v-select
            v-model="selectedSourceIndex"
            :items="inactiveSourceOptions"
            label="Seleccionar Lista"
            item-text="name"
            item-value="index"
            required
          ></v-select>
          <br>
          <v-btn @click="redirectToLink()" color="primary" :disabled="!selectedSourceIndex">Abrir Enlace</v-btn>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from 'vuex';


export default {
  name: 'FormRestrictiveList',
  data() {
    return {
      dialog: false,
      selectedSourceIndex: null,
    };
  },
  computed: {
    ...mapState('restrictions', ['targetData', 'sources']),
    fullName() {
      let names = [this.targetData.firstName, this.targetData.secondName, this.targetData.firstSurname, this.targetData.secondSurname];
      names = names.filter((x) => x !== '');
      return names.join(" ");
    },
    fullIdentification() {
      return [this.targetData.identificationType, this.targetData.identification].join(" ");
    },
    inactiveSourceOptions() {
      // Filtrar solo las fuentes inactivas y mapearlas a opciones seleccionables
      return this.sources
        .map((source, index) => ({
          name: source.name,
          index: index,
          active: source.active
        }))
        .filter(source => !source.active);
    },
    currentSourceUrl() {
      if (this.selectedSourceIndex !== null && this.selectedSourceIndex < this.sources.length) {
        return this.sources[this.selectedSourceIndex].url;
      }
      return 'No hay URLs disponibles';
    }
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.selectedSourceIndex = null;
      this.status = null;
      this.file = null;

    },
    redirectToLink() {
      const url = this.currentSourceUrl;
      if (url && url !== 'No hay URLs disponibles') {
        window.open(url, '_blank');
      } else {
        alert('Seleccione una lista.');
      }
    },
    handleIdentificationChange(newIdentification) {
      this.documentNumber = newIdentification;
    },
  }
};
</script>

<style>
.informacion {
  border: solid 1px #2196f3;
  box-shadow: 1px 4px 4px #2196f3;
  text-align: center;
  font-weight: 500;
  padding: 10px;
  background-color: rgb(253, 253, 253);
  font-size: 18px;
  color: #000;
  margin-bottom: 20px;
}
.Titulo {
  text-align: center;
  color: #000;
}
</style>
