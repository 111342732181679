<template lang="pug">
v-row(class="mb-3 justify-space-around")
  v-col(v-for="(c, i) in conventions" :key="`convention-item-${i}`")
    v-icon(
      :size="size"
      :color="c.color") {{c.icon}}
    span {{c.text}}
</template>

<script>
export default {
  name: 'ListConventionIcon',
  props: {
    /**
     * listado de convensiones
     * @example [{icon:'mdi-alert', text: 'Alerta', color: 'indigo'}]
     */
    conventions: {
      type: Array,
      required: true
    },
    /**
     * el tamaño del icono
     */
    size: {
      type: String,
      default: '35'
    },
  }
  
}
</script>
