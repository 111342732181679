import apiClient from './config.js';

export default {
  // servicio para obtener el listado de listas restrictivas
  async getLists(token) {
    apiClient.interceptors.request.use((config) => {
      config.headers = { ...{ 
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }, ...config.headers };
      return config;
    });
    return await apiClient.get('restrictive_lists/sources');
  },
  // servicio para cargar una consulta de listas restrictivas
  async uploadSearch(searchData, token) {
    apiClient.interceptors.request.use((config) => {
      config.headers = { ...{ 
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }, ...config.headers };
      return config;
    });
    return await apiClient.post('restrictive_lists/upload_search',
      JSON.stringify(searchData));
  },
  // servicio para obtener el listado de reportes de listas restrictivas
  async getReports(token) {
    apiClient.interceptors.request.use((config) => {
      config.headers = { ...{ 
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }, ...config.headers };
      return config;
    });
    return await apiClient.get('restrictive_lists/reports');
  },
};