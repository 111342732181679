<template lang="pug">
v-card
  a(href="" id="export" ref="exportLink" hidden)
  v-card-title
    v-text-field(
      dense
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details)
  v-data-table(
    dense
    :headers="headers"
    :items="reportData.report_data"
    :search="search")
    template(
      v-if="headers.filter((x) => x.value === 'complementacion').length > 0"
      v-slot:item.complementacion="{ item }")
      text-cutter(
        v-if="item.complementacion"
        :text="item.complementacion"
        :text-length="50"
        modal-title="Complementación")
    template(
      v-if="headers.filter((x) => x.value === 'linderos_geos').length > 0"
      v-slot:item.linderos_geos="{ item }")
      span {{ item.linderos_geos }}
      v-btn(
        v-if="item.linderos_geos.includes('urn:ogc:def:crs:OGC:1.3:CRS84')"
        class="ml-2 white--text"
        small
        @click="downloadJson(item)"
        color="blue"
      ) Descargar GeoJSON
    template(
      v-if="headers.filter((x) => x.value === 'cabida').length > 0"
      v-slot:item.cabida="{ item }")
      text-cutter(
        v-if="item.cabida"
        :text="item.cabida"
        :text-length="50"
        modal-title="Cabida")
    template(
      v-if="headers.filter((x) => x.value === 'linderos_tecnicos').length > 0"
      v-slot:item.linderos_tecnicos="{ item }")
      text-cutter(
        v-if="item.linderos_tecnicos"
        :text="item.linderos_tecnicos"
        :text-length="50"
        modal-title="Linderos Tecnicos")
</template>

<script>
import { mapState } from 'vuex';
import TextCutter from '@/components/TextCutter';

export default {
  name: 'ReportPreview',

  components: {
    TextCutter,
  },

  data () {
    return {
      search: '',
    }
  },

  computed: {
    ...mapState('reports', ['reportData']),
    headers() {
      const headers = this.reportData.headers.map((x) => {return {text: x.name, value: x.variable}});
      return headers;
    }
  },

  methods: {
    downloadJson(item) {
      const jsonFile  = item.linderos_geos;
      const today = new Date();
      let fileName = '';
      if  (item.numero_matricula) {
        fileName = item.numero_matricula;
      } else {
        let formatDate = today.toISOString().replace('T', '_').replace('Z', '');
        fileName = `coordenadas_predio_${formatDate}`;
      }
      var convertedData = 'application/json;charset=utf-8,' + encodeURIComponent(jsonFile);
      document.getElementById('export').setAttribute('href', 'data:' + convertedData); 
      document.getElementById('export').setAttribute('download',
        `${fileName}.geojson`);
      this.$refs.exportLink.click();
    }
  }
}
</script>