<template lang="pug">
v-card
  v-toolbar(
    height="40"
    color="cyan"
    dark)
    v-toolbar-title Información adicional del predio
  div(v-if="currentSearch.registros")
    v-card-subtitle(class="pt-2 pb-0") Tipo Predio
    v-card-text(class="text--primary text-h4")
      div {{currentSearch.tipo_predio}}

    v-card-subtitle(class="pt-2 pb-0") Direccion
    v-card-text(class="text--primary text-h6") {{currentSearch.registros.direccion}}

    v-card-subtitle(class="pt-2 pb-0") Destino Económico
    v-card-text(class="text--primary text-h6") {{currentSearch.registros.destino_economico}}

    v-card-subtitle(class="pt-2 pb-0") Área terreno
    v-card-text(class="text--primary text-h6") {{currentSearch.registros.area_terreno.toFixed(2).replace('.', ',')}} m<sup>2</sup>

    v-card-subtitle(class="pt-2 pb-0") Área construida
    v-card-text(class="text--primary text-h6") {{currentSearch.registros.area_construida.toFixed(2).replace('.', ',')}} m<sup>2</sup>

    v-card-subtitle(class="pt-2 pb-0") Código catastral
    v-card-text(class="text--primary text-h6") {{currentSearch.registros.numero_predial_nacional}}

    v-card-subtitle(class="pt-2 pb-0") Municipio
    v-card-text(class="text--primary text-h6") {{currentSearch.registros.municipio}}
  div(v-else class="my-3 text-center")
    span(class="text-h6") No se encontro información de registros 1.
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'RecordOneInfo',
  computed: {
    ...mapState('folios', ['currentSearch']),
  }
}
</script>
  