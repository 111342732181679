<template lang="pug">
v-card
  v-card-title CONVENCIONES DEL FOLIO
  v-row(class="mb-3 ml-3 justify-start")
    v-col(v-if="currentFolio.es_vur" cols="2")
      v-icon(
        :size="size"
        color="red darken-2") mdi-database-search
      span Datos provenientes VUR
    v-menu(offset-y)
      template(v-slot:activator="{ on, attrs }")
        v-col(v-if="hasErrors" title="Ver ERRORES" cols="2" v-bind="attrs" v-on="on")
          v-icon(
            class="hithere"
            :size="size"
            color="red accent-4") mdi-alert
          span(class="red--text font-weight-bold") ERROR(ES) REGISTRAL(ES)
      v-list(v-for="(error, i) in listErrors" :key="`error-${i}`" dense)
        v-list-item(class="py-0" style="max-height:20px!important")
          v-list-item-icon
            annotation-badge(
              :show-annotation="true"
              :annotation-number="parseInt(error.annotation)")
          v-list-item-content
            v-list(class="pt-0" v-for="m in error.messages" dense :key="`error-${i}-${m}`")
              v-list-item(class="red--text font-weight-bold") {{ m }}
    v-col(v-if="currentFolio.geom" cols="2")
      v-icon(
        :size="size"
        color="orange") mdi-map-marker-radius
      span Predio georreferenciado
    span(v-if="currentFolio.transfer_info" cols="2")
      v-menu(offset-y)
        template(v-slot:activator="{ on, attrs }")
          v-col(
            title="Ver TRASLADOS"
            v-if="currentFolio.transfer_info.length > 0"
            v-bind="attrs"
              v-on="on")
            v-icon(
              class="hithere"
              :size="size"
              color="indigo") mdi-file-swap
            span Folio con Traslados
        v-list(v-for="t in currentFolio.transfer_info" :key="`tras-${t.matricula_origen}`")
          v-row(class="mx-2")
            v-col 
              div(class="text-caption font-weight-light") Circulo Origen
              div(class="text-body-1") {{t.circulo_origen}}
              div(class="text-caption font-weight-light") Resoluciones Circulo Origen
              ul
                li(v-for="r in t.resolucion_origen" :key="`tras-ro-${t.resolucion_origen}`") {{ r }}
            v-col 
              div(class="text-caption font-weight-light") Matricula Origen
              div(class="text-body-1") {{t.matricula_origen}} 
              div(class="text-caption font-weight-light") Resoluciones Circulo Destino
              ul
                li(v-for="r in t.resolucion_destino" :key="`tras-rd-${t.resolucion_destino}`") {{ r }}
                v-col(v-if="currentFolio.es_vur" cols="2")
    v-col(
      :set="info=getStatus(currentFolio.fecha_impresion)"
      v-if="currentFolio.fecha_impresion" cols="2")
      v-icon(
        :size="size"
        :color="info.color"
        :title="info.title") mdi-traffic-light
      span {{info.message}}
    v-col(cols="2")
      v-btn(
      color="info"
      class="mb-2 white--text"
      @click="analysisFolio(currentFolio.id)") Ver análisis jurídico
        v-icon(
          right
          dark) mdi-scale-balance
</template>

<script>
import { mapState } from 'vuex';
import folioMixin from '@/mixins/folioMixin.js';
import AnnotationBadge from '../annotations/AnnotationBadge.vue';

export default {
  name: 'FolioDetailConventions',

  mixins: [folioMixin],
 
  props: {
    /**
     * el tamaño del icono
     */
    size: {
      type: String,
      default: '35'
    },
  },

  components: {
    AnnotationBadge,
  },

  computed: {
    ...mapState('folios', ['currentFolio']),
    hasErrors() {
      const folioError = this.currentFolio.errors.length > 0 ? true : false;
      const filteredAnnotations = this.currentFolio.anotaciones.filter((x) => x.errors.length > 0);
      const annotationError = filteredAnnotations.length > 0 ? true : false;
      return folioError || annotationError;
    },
    listErrors() {
      const errors = [];
      for (let index = 0; index < this.currentFolio.errors.length; index++) {
        const element = this.currentFolio.errors[index];
        errors.push({
          annotation: 0,
          messages: [element]
        })
      }
      const filteredAnnotations = this.currentFolio.anotaciones.filter((x) => x.errors.length > 0)
      for (let index = 0; index < filteredAnnotations.length; index++) {
        const element = filteredAnnotations[index];
        errors.push({
          annotation: element.numero,
          messages: element.errors
        })
      }
      return errors
    }
  }

}
</script>
