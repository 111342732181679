<template lang="pug">
div(class="text-center" style="margin-top: 30vh")
  div(class="loader mx-auto" style="width: 60px; height: 120px;")
  span(class="text-h4 font-weight-light fade-in") Cargando...
</template>

<script>
export default {
  name: 'LoadingContent',
  props: {
  }
  
}
</script>
  