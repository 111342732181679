import { makeid } from '../../utils.js';
import FilesService from '@/services/FilesService.js';

const files = {
  namespaced: true,
  state: {
    fileList: [],
    showFilesPreview: false,
    loadingFiles: false,
    // Ruta del archico zip en el servidor
    zipPath: '',
    // Indica cuando se esta generando el ZIP
    loadingDownload: false,
  },
  getters: {
    processedFiles(state) {
      return state.fileList.filter((x) => x.processed === true);
    },
    rawFiles(state) {
      return state.fileList.filter((x) => x.processed === false);
    },
    successFiles(state) {
      return state.fileList.filter((x) => x.error === false && x.processed === true && x.progress === 100).length;
    },
    errorFiles(state) {
      return state.fileList.filter((x) => x.error === true && x.progress === 100).length;
    }
  },
  mutations: {
    APPEND_FILE(state, file) {
      const fileId = makeid(8);
      state.fileList.push({
        key: fileId,
        processed: false,
        file,
        progress: 0,
        message: 'Recibido',
        ready: false,
        folioId: '',
        error: false,
        initProcess: null,
        endProcess: null,
      });
    },
    REMOVE_FILE(state, fileKey) {
      state.fileList = state.fileList.filter((x) => x.key !== fileKey);
      if (state.fileList.length == 0) {
        state.showFilesPreview = false;
      }
    },
    RESTART_FILE(state, fileKey) {
      const processedFile = state.fileList.filter((x) => x.key === fileKey)[0];
      processedFile.progress = 0;
      processedFile.endProcess = null;
      processedFile.error = false;
      processedFile.message = '';
      processedFile.processed = false;
      processedFile.initProcess = null;
    },
    SHOW_DRAG_PREV(state, status) {
      state.showFilesPreview = status;
    },
    UPDATE_FILES(state) {
      const processDate = new Date();
      state.fileList.map((x) => {
        x.processed = true;
        x.initProcess = processDate;
      });
    },
    UPDATE_PROGRESS(state, {progress, message, fileId}) {
      const processedFile = state.fileList.filter((x) => x.key === fileId)[0];
      processedFile.progress = progress;
      processedFile.message = message;
    },
    FINISH_FOLIO(state, {message, fileId, folioId}) {
      const processDate = new Date();
      const processedFile = state.fileList.filter((x) => x.key === fileId)[0];
      processedFile.progress = 100;
      processedFile.message = message;
      processedFile.ready = true;
      processedFile.folioId = folioId;
      processedFile.endProcess = processDate;
    },
    ERROR_FOLIO(state, {fileId, message}) {
      const processDate = new Date();
      const processedFile = state.fileList.filter((x) => x.key === fileId)[0];
      processedFile.progress = 100;
      processedFile.endProcess = processDate;
      processedFile.error = true;
      processedFile.message = message;
    },
    REMOVE_SUCCESS_FILES(state) {
      state.fileList = state.fileList.filter((x) => !(x.error === false && x.processed === true && x.progress === 100))
    },
    REMOVE_ERROR_FILES(state) {
      state.fileList = state.fileList.filter((x) => x.error !== true);
    },
    // mutacion para establecer los valores de cualquier variable
    CHANGE_VALUE(state, {value, varName}) {
      state[varName] = value;
    },
  },
  actions: {
    async uploadFolios({ commit, state }) {
      try {
        state.loadingFiles = true;
        const rawFiles = state.fileList.filter((x) => x.processed === false);
        const token = localStorage.getItem('token');
        const response = await FilesService.uploadFolios(rawFiles, token);
        if (response) {
          commit('UPDATE_FILES');
          commit('ui/SHOW_ALERT',{
            alertMessage: 'Se cargaron correctamente los folios en el sistema',
            alertType: 'success'
          }, {root: true});
          commit('SHOW_DRAG_PREV', false);
        }
      } catch (error) {
          commit('ui/SHOW_ALERT',{
            alertMessage: `Ocurrio un error almacenar los folios en el sistema
              (${error.response.status} - ${error.response.data.detail})`,
            alertType: 'error'
          }, {root: true});
      }
      state.loadingFiles = false;
    },
    /**
     * Acción para consultar los PDFs diponibles de los folios de matricula
     * disponibles en el sistema
     *
     * @param {file} File El archivo excel con el listado del pdf
     * @returns {} No retorna nada
     * @example
     * $store.dispatch('uploadFile', {
     *  file: File(),
     * })
     * @author Luis Merizalde <luis.merizalde@arcerojas.com>
     */
    async uploadPdfList({ commit }, file) {
      const result = {};
      try {
        const token = localStorage.getItem('token');
        const response = await FilesService.uploadPdfList(file, token);
        if (response) {
          result.data = response.data;
          commit('ui/SHOW_ALERT',{
            alertMessage: 'Se cargo correctamente el listado en el sistema',
            alertType: 'success'
          }, {root: true});
          
        }
      } catch (error) {
          let resError = ''
          if (error.response.data) {
            resError = `Ocurrio un error al cargar el listado en el sistema
            (${error.response.status} - ${error.response.data.detail})`
          } else {
            resError = `Ocurrio un error al cargar el listado en el sistema
            (${error.message})`
          }
          commit('ui/SHOW_ALERT',{
            alertMessage: resError,
            alertType: 'error'
          }, {root: true});
          result.error = resError;
      }
      return result;
    },

    /**
     * Acción para descargar los pdfs de los folios seleccionados por el
     * usuario
     *
     * @param {pdfList} Array El listado de matriculas inmobiliarias
     * @returns {} No retorna nada
     * @example
     * $store.dispatch('downloadPdfs', ['070-26232', '132-1'])
     * @author Luis Merizalde <luis.merizalde@arcerojas.com>
     */
    async downloadPdfs({ commit, state }, pdfList) {
      const result = {};
      state.loadingDownload = true;
      try {
        const token = localStorage.getItem('token');
        const response = await FilesService.downloadPdfs(pdfList, token);
        if (response) {
          state.zipPath = response.data.zipPath;
          commit('ui/SHOW_ALERT',{
            alertMessage: 'Se cargo correctamente el listado en el sistema',
            alertType: 'success'
          }, {root: true});
        }
      } catch (error) {
          let resError = ''
          if (error.response.data) {
            resError = `Ocurrio un error al descargar los PDF
            (${error.response.status} - ${error.response.data.detail})`
          } else {
            resError = `Ocurrio un error al descargar los PDF
            (${error.message})`
          }
          commit('ui/SHOW_ALERT',{
            alertMessage: resError,
            alertType: 'error'
          }, {root: true});
          result.error = resError;
      }
      state.loadingDownload = false;
      return result;
    },
  }
};
  
export default files;
