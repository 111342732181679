<template lang="pug">
v-app  
  v-app-bar(
    app
    style="z-index: 100"
    color="primary"
    dark)
    v-app-bar-nav-icon(
      v-if="isLoggedIn"
      @click.stop="TOGGLE_SIDEBAR(true)")
    div(class="d-flex align-center")
      v-img(
        alt="ARkandha Logo"
        class="shrink mr-2"
        contain
        src="./assets/arkandha.png"
        transition="scale-transition"
        width="40")

      div
        span(class="text-h5") ARkandha<sup>®</sup>
        span(class="subtitle-1 ml-2") {{appName}}

    v-spacer

    div(v-if="isLoggedIn")
      v-btn(
        to="/search"
        text)
        span(class="mr-2") Buscar por NPN
        v-icon mdi-book-search-outline
      v-btn(
        to="/vur"
        text)
        span(class="mr-2") Consulta VUR
        v-icon mdi-database-search-outline
      v-btn(
        v-if="hasPermission('can_add_folio')"
        to="/upload"
        text)
        span(class="mr-2") Cargar
        v-icon mdi-upload
      v-btn(
        to="/folios"
        text)
        span(class="mr-2") Lista Folios
        v-icon mdi-grid
      v-btn(
        @click="userLogout"
        text)
        span(class="mr-2") Salir
        v-icon mdi-logout
  side-bar
  v-alert(
    class="app-alert"
    v-if="showAlert"
    dense
    text
    :color="alertType"
    v-html="alertMessage")

  common-side-dialog
  common-modal
  common-bottom-modal

  v-main(class="mt-4")
    v-container(fluid)
      router-view
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import CommonModal from './components/CommonModal';
import CommonSideDialog from './components/CommonSideDialog';
import { socketUrl } from '@/env.js';
import SideBar from './components/layout/SideBar.vue';
import CommonBottomModal from './components/CommonBottomModal';

export default {
  name: 'App',
  components: {
    CommonModal,
    SideBar,
    CommonSideDialog,
    CommonBottomModal,
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'hasPermission']),
    ...mapState('ui', ['showAlert', 'alertType', 'alertMessage']),
  },
  methods: {
    ...mapMutations('auth', ['LOGOUT']),
    ...mapMutations('ui', ['TOGGLE_SIDEBAR']),
    ...mapMutations('websocket', ['SOCKET_ON_CLOSE', 'SOCKET_ON_OPEN']),
    ...mapActions('websocket', ['socketMessage']),
    ...mapActions('auth', ['userLogout']),
    connect() {
      const token = localStorage.getItem('token');
      console.log('el valor del token', token);
      if (!!token === false) {
        return;
      }
      const clientUrl = `${socketUrl}/ws/${token}`;
      const notificationSocket = new WebSocket(
        clientUrl
      );

      notificationSocket.onopen = (event) => {
        console.log(`Conexion socket abierta exitosamente para ${clientUrl}`, event);
        this.SOCKET_ON_OPEN(notificationSocket);
      };

      notificationSocket.onmessage = (event) => {
        console.log('¡mensaje websocker recibido!', event);
        console.log(event.data);
        this.socketMessage(event.data);
      };

      notificationSocket.onclose = (event) => {
        console.log('¡Websocket cerrado!', event);
        this.SOCKET_ON_CLOSE();
        this.connect();
      };
    }
  },
  watch: {
    $route(to, from) {
      if (to.path === '/folios' && from.path === '/login') {
        if (this.isLoggedIn === true) {
          this.connect();
        }
      }
    }
  },
  data: () => ({
    appName: process.env.VUE_APP_AR_NAME,
  }),
  created() {
    if (this.isLoggedIn === true) {
      this.connect();
    }    
  },
};
</script>

<style scoped>
  .app-alert {
    margin-top: 70px!important;
    position: fixed;
    width: 100%;
    z-index: 1000;
  }
</style>
