<template lang="pug">
v-card
  v-toolbar(
    class="mb-2"
    height="40"
    :color="folioColor"
    dark)
    v-toolbar-title Comparación con información de registro
  div(v-if="currentFolio.registro_1")
    v-row
      v-col(cols="5")
        v-subheader(class="text-h6") Según el Folio
      v-col(cols="2")
        v-subheader(class="text-h6") Coincidencia
      v-col(cols="5")
        v-subheader(class="text-h6") Según la autoridad catastral
    record-one-comparison(
      title="Código catastral"
      :folio-info="currentFolio.codigo_catastral"
      :cadastral-info="currentFolio.registro_1.npn"
    )
    record-one-comparison(
      title="Código catastral anterior"
      :folio-info="currentFolio.codigo_catastral_antiguo"
      :cadastral-info="currentFolio.registro_1.npn_anterior"
    )
    record-one-comparison(
      title="Dirección"
      :folio-info="currentFolio.direccion"
      :cadastral-info="currentFolio.registro_1.direccion"
    )
    record-one-comparison(
      title="Área terreno"
      :folio-info="currentFolio.area_terreno > -1 ? `${currentFolio.area_terreno.toFixed(2).replace('.', ',')} m²` : 'Sin datos'"
      :cadastral-info="`${currentFolio.registro_1.area_terreno.toFixed(2).replace('.', ',')} m²`"
    )
    record-one-comparison(
      title="Área construida"
      :folio-info="currentFolio.area_construida > -1 ? `${currentFolio.area_construida.toFixed(2).replace('.', ',')} m²` : 'Sin datos'"
      :cadastral-info="`${currentFolio.registro_1.area_construida.toFixed(2).replace('.', ',')} m²`"
    )
    record-one-comparison(
      title="Destino económico"
      :folio-info="currentFolio.actividad_economica === '' ? 'Sin datos' : currentFolio.actividad_economica"
      :cadastral-info="currentFolio.registro_1.destino_economico"
    )
    record-one-comparison(
      title="Ubicación"
      :folio-info="`${currentFolio.departamento} - ${currentFolio.municipio_txt}`"
      :cadastral-info="`${currentFolio.registro_1.municipio_registro_1.dpto_municipio.nombre} - ${currentFolio.registro_1.municipio_registro_1.nombre}`"
    )
  div(v-else class="my-3 text-center")
    span(class="text-h6") No se encontro información relacionanda para este predio
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import RecordOneComparison from '@/components/folios/RecordOneComparison.vue'

export default {
  name: 'FolioRecordOne',
  components: {
    RecordOneComparison
  },
  computed: {
    ...mapGetters('folios', ['folioColor']),
    ...mapState('folios', ['currentFolio']),
  }
}
</script>
