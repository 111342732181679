<template lang="pug">
v-card(
  max-width="auto"
  class="mx-auto")
  v-app-bar(
    dark
    dense
    :color="sectionColor")  
    v-btn(icon)
      v-icon {{ sectionIcon }}
    v-toolbar-title(class="text-body") {{ sectionTitle }} ({{ fields.length }})

  v-list(
    v-if="fields.length > 0"
    dense
    subheader)
    v-list-item-group
      v-list-item(
        :title="f.desc"
        class="py-0 my-0"
        v-for="f in fields"
        :key="f.name")
        v-list-item-action
          v-checkbox(:input-value="f.checked" @click="updateVariable(f.key, 'checked', !f.checked)")
        v-list-item-content(class="text-left")
          v-list-item-title {{f.name}}
        v-list-item-action(v-if="f.options")
          v-menu(open-on-hover offset-y)
            template(v-slot:activator="{ on, attrs }")
              v-btn(
                icon
                title="Ver Opciones"
                v-bind="attrs"
                v-on="on")
                v-icon(:color="`${f.selectedOption ? 'blue' : 'grey'} lighten-1`") mdi-cog
            v-list(dense v-model="f.selectedOption")
              v-list-item(
                v-for="option in f.options"
                @click="updateVariable(f.key, 'selectedOption', option)"
                :key="option")
                v-list-item-title {{ option }} {{ f.selectedOption === option ? '(X)': '' }}
  span(class="text-h6 my-5 font-weight-light" v-else)
    | Aún no hay campos implementados para esta categoría
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: "ReportFolioSection",

  props: {
    /**
     * El titulo de la sección
     */
     sectionTitle: {
      type: String,
      required: true,
    },
    /**
     * los elementos que conforman la sección de folios
     */
     fields: {
      type: Array,
      required: true,
    },
    /**
     * los elementos que conforman la sección de folios
     */
    sectionColor: {
      type: String,
      required: false,
      default: 'pink'
    },
    /**
     * El icono de la cabecera de la sección
     */
     sectionIcon: {
      type: String,
      required: false,
      default: 'mdi-list-status'
    }
  },

  data: () => ({
  }),

  methods: {
    ...mapMutations('reports', ['UPDATE_MANAGER_VARIABLE']),
    updateVariable(variableKey, varAttr, varValue) {
      this.UPDATE_MANAGER_VARIABLE({
        variableKey,
        varAttr,
        varValue
      });
    }
  }
}
</script>
