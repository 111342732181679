import apiClient, { authHeaders } from './config.js';

export default {
  async getCircles(token) {    
    return await apiClient.get('vur/circles', authHeaders(token));
  },
  async validateCertificate(certificateNumber) {
    const [circle, number] = certificateNumber.split('-') 
    const params = new URLSearchParams({ circle, number });
    return await apiClient.get(`vur/pre_consulta_fmi?${params.toString()}`);
  },
  async getVurCredentials(token) {
    return await apiClient.get(`vur/get_credentials`, authHeaders(token));
  },
  async changeVurCredentials(username, password) {
    const data = {
      username,
      password,
      email: localStorage.getItem('email')
    }
    return apiClient.post('vur/change_credentials', data);
  },
  // servicio para enviar la lista de folios a descargar en el VUR
  async uploadCertificates(certificates, token, isMassive) {
    const validCertificates = certificates.map((x) => {
      return {
        key: x.id,
        registration_number: x.certificate
      };
    })
    apiClient.interceptors.request.use((config) => {
      config.headers = { ...{ 
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }, ...config.headers };
      return config;
    });
    return await apiClient.post(`vur/upload_certificates/${isMassive}`,
      JSON.stringify(validCertificates));
  },
  async uploadFile(file, token) {
    const formData = new FormData();
    formData.append('file', file.file);
    apiClient.interceptors.request.use((config) => {
      config.headers = { ...{ 
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }, ...config.headers };
      return config;
    });
    return await apiClient.post('vur/file_record_list', formData);
  },
  // servicio para consultar los datos del propietario
  async sendVurPerson(subjectData, token) {
    apiClient.interceptors.request.use((config) => {
      config.headers = { ...{ 
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }, ...config.headers };
      return config;
    });
    return await apiClient.post(`vur/search_people`,
      JSON.stringify(subjectData));
  },

  /**
   * Servicio para descargar la tradicion del folio desde el VUR
   *
   * @param {propertyRecord} string El número del folio de matrícula inmobiliaria
   * @param {downloadParent} bool Cuando se vana descargar las matriculas matrices
   * @param {downloadChildren} bool Cuando se vana descargar las matriculas segregadas
   * @param {token} string token de usuario generado por la aplicacion
   * @returns {HttpResponse} No retorna nada
   * @example
   * searchVurFamily('070-26232', true, false, 'dhlfgdnfudygufymguyfgf')
   * @author Luis Merizalde <luis.merizalde@arcerojas.com>
   */
  async searchVurFamily(propertyRecord, downloadParent, downloadChildren, token) {
    apiClient.interceptors.request.use((config) => {
      config.headers = { ...{ 
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }, ...config.headers };
      return config;
    });
    const requestData = {
      fmi: propertyRecord,
      download_parent: downloadParent,
      download_children: downloadChildren
    }
    return await apiClient.post(`vur/vur_family`,
      JSON.stringify(requestData));
  },
  
  /**
   * Servicio para completar la tradicion del folio desde el VUR
   *
   * @param {propertyRecord} string El número del folio de matrícula inmobiliaria
   * @param {downloadParent} bool Cuando se vana descargar las matriculas matrices
   * @param {downloadChildren} bool Cuando se vana descargar las matriculas segregadas
   * @param {missingChildren} bool Cuando se vana descargar las matriculas segregadas
   * @param {missingParent} bool Cuando se vana descargar las matriculas segregadas
   * @param {token} string token de usuario generado por la aplicacion
   * @returns {HttpResponse} No retorna nada
   * @example
   * searchVurFamily('070-26232', true, false, 'dhlfgdnfudygufymguyfgf')
   * @author Luis Merizalde <luis.merizalde@arcerojas.com>
   */
  async completeVurFamily(propertyRecord, downloadParent, downloadChildren,
    missingChildren, missingParent, token) {
    apiClient.interceptors.request.use((config) => {
      config.headers = { ...{ 
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }, ...config.headers };
      return config;
    });
    const requestData = {
      fmi: propertyRecord,
      download_parent: downloadParent,
      download_children: downloadChildren,
      missing_children: missingChildren,
      missing_parent: missingParent,
    }
    return await apiClient.post(`vur/complete_vur_family`,
      JSON.stringify(requestData));
  },
  
  /**
   * Servicio para reconstruir el PDF de un folio cuya ultima fuente de datos
   * sea el VUR
   *
   * @param {folioId} string El número del folio de matrícula inmobiliaria
   * @param {token} string token de usuario generado por la aplicacion
   * @returns {HttpResponse} No retorna nada
   * @example
   * reBuildPdf('5e8c9923-4a33-40ae-b530-ccd56056a709', 'dhlfgdnfudygufymguyfgf')
   * @author Luis Merizalde <luis.merizalde@arcerojas.com>
   */
  async reBuildPdf(folioId, token) {
    apiClient.interceptors.request.use((config) => {
      config.headers = { ...{ 
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }, ...config.headers };
      return config;
    });
    return await apiClient.get(`vur/rebuild_pdf/${folioId}`);
  }
};