import apiClient from './config.js';

export default {
  async uploadFile(file, token) {
    const formData = new FormData();
    formData.append('file', file.file);
    apiClient.interceptors.request.use((config) => {
      config.headers = { ...{ 
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }, ...config.headers };
      return config;
    });
    return await apiClient.post('ant/report_api_excel', formData);
  },
};