<template lang="pug">
div()
  v-list(
    class="truncated-folio-detail-height scrollable"
    style="max-height: 55vh"
    v-if="annotationList.length > 0"
    three-line
    id="wrapper-tradition"
    ref="wrapperTradition")
    template(v-for="(item, index) in formatTradition")
      v-subheader(
        v-if="item.header"
        :key="item.header")
          v-icon(color="blue" size="30") mdi-file-certificate-outline
          span(class="blue--text font-weight-medium text-h6") {{item.header}}
          v-menu(open-on-hover offset-y)
            template(v-slot:activator="{ on, attrs }")
              v-btn(
                v-if="item.segregations.length > 0"
                class="ml-1"
                color="primary"
                dark
                small 
                v-bind="attrs"
                v-on="on") anotaciones
            v-list
              v-list-item(
                v-for="(subitem, index) in item.segregations"
                :key="index")
                v-list-item-title (<b>{{ subitem.number }}</b>) - {{ subitem.folio }}
          v-spacer 
          div(
            title="Ver folio"
            class="mr-2 clickable"
            v-if="item.folioId"
            @click="detailFolio(item.folioId)")
            v-icon(class="mr-1" color="green") mdi-checkbox-outline 
            | En el sistema
      v-divider(
        v-else-if="item.divider"
        :key="index"
        :inset="item.inset")
      v-list-item(
        v-else
        :key="item.title")
        annotation-badge(
          :annotation-number="item.numero"
          :number-cancellation="item.cancelacion ? item.cancelacion.numero  : 0"
          :show-annotation="true"
          :folio-number="item.folio"
          :annotation="item")
          
        v-list-item-content
          v-list-item-title(v-html="item.especificacion")
          v-list-item-subtitle(
            v-if="item.fecha_doc_anotacion"
            v-html="`${item.documento} DEL ${item.fecha_doc_anotacion} ${item.datos_documento}`")
  div(
    v-else
    class="text-center"
    style="margin-top: 50% !important"
    )
    v-icon(size="40") mdi-file-certificate
    br
    span Seleccione un folio para ver la cadena de tradición
  //- v-skeleton-loader(
    class="mt-2"
    v-else
    v-bind="attrs"
    :type="skeletonArray")
</template>

<script>
import AnnotationBadge from '../annotations/AnnotationBadge.vue';

export default {
  name: 'FolioTraditionList',

  props: {
    annotationList: {
      required: true,
      type: Array
    }
  },

  components: {
    AnnotationBadge
  },

  data: () => ({
    skeletonItems: [
      'list-item-avatar-two-line',
      'divider',
      'list-item-avatar',
      'divider',
      'list-item-avatar-two-line',      
      'divider',
      'list-item-avatar',
      'divider',
      'list-item-avatar-two-line',      
      'divider',
      'list-item-avatar',
      'divider',
      'list-item-avatar-two-line',      
      'divider',
      'list-item-avatar',
    ],
  }),

  updated() {
    // console.log('UPDATED LIST TRADITION');
    // const el = document.getElementById('wrapper-tradition');
    // console.log(el);
    // console.log('el.scrollHeight', el.scrollHeight);
    // el.scrollTop = el.scrollHeight;
    // console.log('el.scrollTop', el.scrollTop);

    
    // const el = this.$refs.wrapperTradition;
    // el.scrollTop = 0;
  },

  computed: {
    skeletonArray() {
      return this.skeletonItems.join(', ');
    },
    formatTradition() {
      const tradition = [];
      for (let index = 0; index < this.annotationList.length; index++) {
        const folio = this.annotationList[index];
        // separar las anotaciones de cada folio
        const folioNumber = folio.folio.replace('_', '-');
        tradition.push({
          header: folioNumber,
          folioId: folio.folioId,
          segregations: folio.segregations
        })
        // agragar las anotaciones de tradición de cada folio
        if (folio.annotations.length > 0) {
          for (let index_2 = 0; index_2 < folio.annotations.length; index_2++) {
            const annotation = folio.annotations[index_2];
            tradition.push({...annotation, folio: folioNumber});
            tradition.push({divider: true, inset: true});
          }
        } else {
          tradition.push({
            especificacion: 'FOLIO SIN ANOTACIONES',
            numero: 0
          });
          tradition.push({divider: true, inset: true});
        }
        
      }
      return tradition;
    }    
  },

  methods: {
    detailFolio(folioId) {
      const url = `${window.location.origin}/folios/${folioId}/detail`;
      window.open(url, "_blank");
    }
  }
};
</script>