import { mapActions } from 'vuex';
const mapComponentModule = {
  'RestrictiveList': {
    module: 'restrictions',
    loadingAction: 'reportLoading',
  },
  'VurManager': {
    module: 'vur',
    loadingAction: 'loadingVur',
  },
  'VurMassiveManager': {
    module: 'vur',
    loadingAction: '',
  },
  'FolioFamily': {
    module: 'vur',
    loadingAction: 'loadingVurFamily',
  },
  'VurOwnerManager': {
    module: 'vur',
    loadingAction: 'loadingVurOwners',
  },
  'RestrictiveLists': {
    module: 'restrictions',
    loadingAction: 'reportLoading',
  },
};
export default {
  data(context) {
    const tempArray = context.$vnode.tag.split('-');
    const currentComp = tempArray[tempArray.length - 1];
    return {
      // indica que la cancelación esta cargando
      loadingCancel: false,
      // indica el nombre de la variable de carga en el componente
      loadingAction: mapComponentModule[currentComp].loadingAction,
      // el modulo del store que instancia este mixin
      module: mapComponentModule[currentComp].module,
    };
  },
  async created() {
    // agregar evento para cancelar la tarea en caso de recarga
    window.addEventListener('beforeunload', await this.cancelTask)
  },
  async beforeDestroy() {
    // cancelar la tarea asincrona si se esta ejecutandose
    await this.cancelTask();
  },
  methods: {
    ...mapActions('core', ['cancelCeleryTask']),
    // metodo para cancelar por url
    async cancelTask() {
      if (this.celeryTaskId !== '') {
        await this.cancelCeleryTask({
          taskId: this.$store.state[this.module].celeryTaskId,
          module: this.module
        })
      }
    },
    // metodo para cancelar el accion en la misma vista con botón
    async actionCancel() {
      // indicar que esta cargando
      this.loadingCancel = true;
      // cancelar al atarea en el servidor
      await this.cancelTask();
      // indicar que la accion termiono
      this.loadingCancel = false;
      // limpiar el id de la tarea celery
      this.$store.commit(`${this.module}/CHANGE_VALUE`, {
        value: '',
        varName: 'celeryTaskId'
      });
      if (this.loadingAction !== '') {
        this.$store.commit(`${this.module}/CHANGE_VALUE`, {
          value: false,
          varName: this.loadingAction
        });
      }
    }
  }
};