<template lang="pug">
v-container(class="text-center" fluid)
  h1 Consulta de propietarios VUR
  vur-owner-manager
</template>

<script>
import { mapMutations } from 'vuex';
import VurOwnerManager from '@/components/vur/VurOwnerManager';
import { maxQueryCertificates } from '@/config.js';

export default {
  name: 'VurOwner',

  components: {
    VurOwnerManager
  },

  data: () => ({
    maxQueryCertificates,
  }),

  mounted() {
    // limpiar certificados
    this.CHANGE_VALUE({
      varName: 'certificates',
      value: []
    });
    // realizar limpieza de la funcionalidad celery
    this.CHANGE_VALUE({
      varName: 'loadingVurOwners',
      value: false
    });
    this.CHANGE_VALUE({
      varName: 'celeryTaskId',
      value: ''
    });
  },

  methods: {
    ...mapMutations('vur', ['CHANGE_VALUE']),
  }
};
</script>
  