<template lang="pug">
bar(
  :chart-options="chartOptions"
  :chart-data="chartData"
  :chart-id="chartId"
  :dataset-id-key="datasetIdKey"
  :plugins="plugins"
  :css-classes="cssClasses"
  :styles="styles"
  :width="width"
  :height="300")
</template>

<script>
import { mapGetters } from 'vuex';
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'AnnotationHistogram',
  components: {
    Bar
  },
  props: {
    chartId: {
      type: String,
      default: 'annotation-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('annotations', ['annotationNames', 'annotationColors', 'annotationsByCategory']),
    chartData() {
      return {
        labels: this.annotationNames,
        datasets: [
          {
            label: 'Anotaciones',
            backgroundColor: this.annotationColors,
            data: this.annotationsByCategory
          }
        ]
      }
    }
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  }
};
</script>
