<template lang="pug">
v-card
  v-toolbar(
    height="50"
    color="blue-grey darken-2"
    dark)
    v-toolbar-title Mapa de los círculos registrales colombianos
  iframe(
    width="100%"
    height="720"
    frameborder="0"
    :src="mapUrl"
    allowfullscreen
    webkitallowfullscreen
    mozallowfullscreen
    oallowfullscreen
    msallowfullscreen)
</template>

<script>
export default {
  name: 'RegistrationCircles',
  data: () => ({
    mapUrl: 'https://aldaisabelv.carto.com/viz/e85340ba-bcf6-11e6-ba9b-0e3ff518bd15/embed_map'
  })
};
</script>
