<template lang="pug">
v-container(fluid)
  a(ref="downloadLink" hidden :href="csvData" download="massive_report.csv")
  v-alert(
    class="text-h4"
    border="bottom"
    color="blue-grey"
    dark
    v-html="alertMessage")
  v-card
    v-form
      v-container
        v-row
          v-col(cols="12" md="4")
            excel-file-input(v-model="file" label="Listado predios")
          v-col(cols="12" md="4")
            v-radio-group(v-model="radioField")
              template(v-slot:label)
                div Seleccione el campo de búsqueda
              v-radio(value="npn")
                template(v-slot:label)
                  div Número Predial Nacional <strong class="primary--text">30 dígitos</strong>
              v-radio(value="npn_anterior")
                template(v-slot:label)
                  div Número Predial Anterior <strong class="primary--text">20 dígitos</strong>
              v-radio(value="matricula_inmobiliaria")
                template(v-slot:label)
                  div Folio de Matrícula Inmobiliaria
          v-col(cols="12" md="4")
            v-btn(
              :disabled="loading"
              :loading="loading"
              color="green"
              class="ma-2 white--text"
              @click="search")
              v-icon(left dark) mdi-magnify
              | consultar
            v-btn(
              v-if="!!csvData"
              color="orange"
              class="ma-2 white--text"
              @click="download")
              v-icon(left dark) mdi-download
              | Descargar Reporte

</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ExcelFileInput from '@/components/ui/ExcelFileInput.vue';

export default {
  name: 'MassiveFolioSearch',
  data: () => ({
    radioField: 'npn',
    loading: false,
    queryString: '',
    alertMessage: `Consulta Masiva<br>
    <span class="text-body-1">Permite obtener la información disponible en ARkandha de múltiples registros</span><br>
    <span class="text-body-1"><b>1- </b>Debe cargar un archivo excel con los datos de número predial, número predial anterior ó FMI desde la celda <b>A1</b> del documento.</span><br>
    <span class="text-body-1"><b>2- </b> Seleccione el tipo de dato que esta subiendo.</span><br>
    <span class="text-body-1"><b>3- </b> Presione en botón <b>CONSULTAR</b>.</span><br>
    <span class="text-body-1"><b>4- </b> Descargue el reporte en formato CSV.</span><br>
    `,
    // control excel
    file: null,
  }),
  components: {    
    ExcelFileInput
  },
  computed: {
    ...mapState('folios', ['csvData']),
  },
  methods: {
    ...mapActions('folios', ['masiveFolioSearch']),
    ...mapMutations('ui', ['SHOW_ALERT']),
    async search() {
      if (this.file === null || this.file === undefined) {
        this.SHOW_ALERT({
          alertMessage:'Debe cargar un archivo de excel',
          alertType: 'error'
        });
        return;
      }
      this.loading = true;

      try {
        await this.masiveFolioSearch({
          file: this.file,
          field: this.radioField
        });
      } catch (error) {
        this.SHOW_ALERT({
          alertMessage:'Ocurrio un error al cargar la información',
          alertType: 'error'
        });
      }      
      this.loading = false;
    },
    download() {
      this.$refs.downloadLink.click();
    }
  }
};
</script>
  