<template lang="pug">
v-card(
  class="mx-auto"
  max-width="70%")
  v-list-item(two-line)
    v-list-item-content
      v-list-item-title(class="text-h5") Consultar Certificados de Tradición y Libertad
      v-list-item-subtitle
        | Para buscar los archivos cargue el archivo Excel con el listado de matrículas comenzando desde la celda <b>A1</b>

  vur-excel-list
  v-divider

  div(class="d-flex flex-row")
    v-list-item
      v-list-item-icon
        v-icon(color="green") mdi-check-circle
      v-list-item-subtitle <b>{{ validCertificates }}</b> Válido(s)

    v-list-item
      v-list-item-icon
        v-icon(color="yellow" style="background: black; border-radius: 20px;") mdi-alert-circle
      v-list-item-subtitle <b>{{ invalidCertificates }}</b> No verificado(s)

    v-list-item
      v-list-item-icon
        v-icon(color="blue") mdi-database-check
      v-list-item-subtitle <b>{{ processedCertificates }}</b> Procesados(s)

  vur-massive-list(class="scrollable truncated-folio-detail-height mr-4 mb-2" :massive="true")
  v-divider

  v-card-actions
    //- TODO boton para cancelar la descarga masiva
    //- v-btn(
      class="white--text"
      :loading="loadingCancel"
      :disabled="loadingCancel"
      v-if="celeryTaskId !== ''"
      class="ma-2"
      color="red"
      @click="customActionCancel")
      v-icon(class="mr-1" dark) mdi-cancel
      | Cancelar
    v-spacer
    //- TODO certificados validos
      v-btn(
        v-if="hasPermission('can_use_vur')"
        :disabled="!validCertificates > 0"
        @click="sendCertificates"
        class="white--text"
        color='green') Consultar
    v-btn(
      v-if="invalidCertificates > 0"
      @click="CLEAR_CERTIFICATES('invalid')"
      class="yellow"
      color="black"
    ) Limpiar no verificados
    v-btn(
      @click="CLEAR_CERTIFICATES('processed')"
      v-if="processedCertificates > 0"
      class="blue white--text"
    ) Limpiar procesados
    v-btn(
      :loading="loadingVur"
      v-if="hasPermission('can_use_vur')"
      @click="sendCertificates"
      class="white--text"
      color='green') Consultar 
    v-btn(v-else class="white--text" color="blue" @click="$router.push('/vur-credentials')")
      | Ingresar credenciales
      v-icon(right dark) mdi-arrow-right-bold
    button-download-pdf(class="ml-2" :folios="avalaibleCertificates")
    
</template>

<script>
import celeryMixin from '@/mixins/celeryMixin.js';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import VurMassiveList from '@/components/vur/VurMassiveList';
import VurExcelList from '@/components/vur/VurExcelList';
import { maxQueryCertificates } from '@/config.js';
import ButtonDownloadPdf from '@/components/files/ButtonDownloadPdf';

export default {
  name: 'VurMassiveManager',

  components: {
    VurMassiveList,
    VurExcelList,
    ButtonDownloadPdf,
  },

  mixins: [celeryMixin],

  data: () => ({
    labels: [],
    time: 0,
    maxQueryCertificates
  }),

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('vur', ['certificates', 'selectedCertificate', 'loadingVur', 'celeryTaskId']),
    ...mapGetters('vur', ['validCertificates', 'invalidCertificates', 'processedCertificates']),
    ...mapState('files', ['zipPath', 'loadingDownload']),
    avalaibleCertificates() {
      return this.certificates.filter((x) => x.folioId !== null).map((x) => x.certificate);
    },
  },

  mounted() {
    // limpiar ruta ZIP
    this.CHANGE_VALUE({
      varName: 'zipPath',
      value: ''
    })
    this.getCircles();
  },

  methods: {
    ...mapActions('vur', ['getCircles', 'uploadCertificates']),
    ...mapMutations('ui', ['SHOW_ALERT']),
    ...mapMutations('vur', ['CLEAR_CERTIFICATES', 'RESTART_NO_DOWNLOADED_CERTIFICATES']),
    ...mapMutations('files', ['CHANGE_VALUE']),
    sendCertificates() {
      if ((this.validCertificates + this.invalidCertificates) === 0) {
        this.SHOW_ALERT({
          alertMessage: 'Debe haber por lo menos un certificado para enviar',
          alertType: 'error'
        });
        return;
      }
      this.uploadCertificates(true);
    },
    // metodo para cancelar la descarga de forma personalizada
    async customActionCancel() {
      // ejecutar accion de cancelación preestablecida por el mixin
      await this.actionCancel();
      // indicar que se pueden volver a descargar los folios
      // cuya descarga no ha iniciado
      this.RESTART_NO_DOWNLOADED_CERTIFICATES();
    }
  }
};
</script>
  