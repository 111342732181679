<template lang="pug">
v-card(class="mx-auto")
  v-list(dense)
    v-row
      v-col( cols="4" v-for="(f, index) in processedFiles" :key="f.file.name")
        v-list-item( two-line)
          v-list-item-icon
            v-icon(size="40") mdi-file
          v-list-item-content
            div {{f.file.name}}
          div(class="d-flex flex-column")
            progres-slider(:file="f")         
          v-btn(
            color="gray"
            icon
            @click="deleteFile(f.key)"
            title="Quitar archivo")
            v-icon mdi-delete
          v-btn(
            v-if="f.error"
            color="gray"
            icon
            @click="retryFile(f.key)"
            title="Volver a subir")
            v-icon mdi-restart
        v-divider
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ProgresSlider from '@/components/ProgresSlider.vue';

export default {
  name: 'ProcessedFiles',
  components: {
    ProgresSlider
  },  
  computed: {
    ...mapGetters('files', ['processedFiles']),    
  },
  methods: {    
    ...mapMutations('files', ['REMOVE_FILE', 'RESTART_FILE']),
    deleteFile(fileId) {
      this.REMOVE_FILE(fileId);
    },
    retryFile(fileId) {
      this.RESTART_FILE(fileId);
    },
  }
}
</script>
