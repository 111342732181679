<template lang="pug">
v-dialog(
  persistent
  style="z-index: 1000; max-height: 300px!important"
  v-model="showDialog"
  :width="['FolioSummary', 'AnnotationHistogram', 'RecordExtraInfo'].includes(dialogContent) ? '500' : '100%'")
  v-card
    v-card-title(class="text-h5 grey lighten-2") {{dialogTitle}}

    component(
      v-if="!dialogText"
      class="my-2"
      v-bind:is="dialogContent"
      v-bind="currentProperties")
    v-card-text
      div {{dialogText}}

    v-divider

    v-card-actions
      v-spacer
      v-btn(
        color="primary"
        text
        @click="showDialog = false") Cerrar
</template>

<script>
import { mapState } from 'vuex';
import FolioSummary from '@/components/FolioSummary';
import AnnotationSummary from '@/components/annotations/AnnotationSummary';
import PdfPreview from '@/components/PdfPreview';
import ImagePreview from '@/components/ui/ImagePreview';
import AnnotationDetail from '@/components/annotations/AnnotationDetail.vue';
import AnnotationHistogram from '@/components/annotations/AnnotationHistogram.vue';
import AnnotationTimeline from '@/components/annotations/AnnotationTimeline.vue';
import RecordExtraInfo from '@/components/folios/RecordExtraInfo.vue';

export default {
  name: 'CommonModal',
  data: () => ({
    currentProperties: {}
  }),
  components: {
    FolioSummary,
    AnnotationSummary,
    PdfPreview,
    AnnotationDetail,
    AnnotationHistogram,
    AnnotationTimeline,
    RecordExtraInfo,
    ImagePreview,
  },
  created() {
    this.$bus.$on('update-properties', (data) => {
      this.currentProperties = data;
    });
  },
  watch: {
    '$store.state.ui.dialogContent': {
      deep: true,
      handler(newVal) {
        if (newVal === 'AnnotationDetail') {
          this.currentProperties = {...{annotation:this.viewedAnnotation}};
        }
      }
    },
    '$store.state.folios.viewedAnnotation': {
      deep: true,
      handler() {
        this.currentProperties = {...{annotation:this.viewedAnnotation}};
      }
    }
  },
  mounted() {
    if (this.dialogContent === 'AnnotationDetail') {
      this.currentProperties.annotation = this.viewedAnnotation;
    }
  },
  computed: {
    ...mapState('ui', ['dialogContent', 'dialogTitle', 'dialogText']),
    ...mapState('folios', ['viewedAnnotation']),
    showDialog: {
      get() {
        return this.$store.state.ui.showDialog;
      },
      set(value) {
        return this.$store.dispatch('ui/toggleDialog', value);
      }
    }
  }
}
</script>