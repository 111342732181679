<template lang="pug">
div
  label(v-if="label") {{label}}
  div(class="text-center")
    v-menu(offset-y)
      template(v-slot:activator="{ on, attrs }")
        v-btn(
          :class="selectedCity ? 'white--text' : 'black--text'"
          :color="selectedCity ? 'blue darken-1' : 'white'"
          block
          v-bind="attrs"
          v-on="on")
          span(v-if="selectedCity")
            | {{selectedCity.nombre}} - {{selectedCity.dpto_municipio.nombre.toUpperCase()}} - {{selectedCity.codigo}}
          span(v-else)
            | {{placeholder}} {{resultString}}
      v-list
        v-list-item
          v-text-field(
            small v-model="queryString" :label="infoMessage" @click.stop="" outlined)
        v-list-item(
          v-for="(item, index) in filteredCities"
          color="red"
          :key="index"
          @click="selectCity(item)")
          v-list-item-title
            | {{item.nombre}} - {{item.dpto_municipio.nombre.toUpperCase()}} - {{item.codigo}}
            span(v-if="selectedCity")
              v-icon(
                v-if="selectedCity.codigo === item.codigo"
                class="ml-2"
                color="green darken-1") mdi-check-circle
  </template>
  
  <script>
  import { mapActions, mapMutations, mapState } from 'vuex';
  
  export default {
    name: 'CityPicker',
    model: {
      prop: 'selectedCity',
      event: 'change'
    },
    props: {
      minCharacters: {
        type: Number,
        default: 3
      },
      notFound: {
        type: String,
        default: 'Resultados no encontrados'
      },
      queryMessage: {
        type: String,
        default: 'Por favor ingrese xx o más caracteres'
      },
      placeholder: {
        type: String,
        default: 'Seleccione el municipio'
      },
      label: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        queryString: '',
        selectedCity: null,
        errorMessage: '',
      };
    },
    computed: {
      ...mapState('cadastral', ['filteredCities']),
      resultString() {
        let result = '';
        if (this.queryString.length > this.minCharacters) {
          result = `${this.filteredCities.length} resultado(s)`;
        }
        return result;
      },
      infoMessage() {
        let message = '';
        if (this.queryString.length < this.minCharacters) {
          const missingChars = this.minCharacters - this.queryString.length;
          message = this.queryMessage.replace('xx', missingChars);
        }
        if (this.queryString.length >= this.minCharacters && this.filteredCities.length === 0) {
          message = this.notFound;
        }
        return message;
      }
    },
    watch: {
      queryString(newVal) {
        if (newVal.length >= this.minCharacters) {
          this.fetchCities();
        } else {
          this.CLEAR_CITIES();
        }
      }
    },
    methods: {
      ...mapMutations('cadastral', ['CLEAR_CITIES']),
      ...mapActions('cadastral', ['getCities']),
      selectCity(city) {
        this.selectedCity = city;
        this.$emit('change', this.selectedCity.codigo);
        this.queryString = '';
        this.CLEAR_CITIES();
      },
      fetchCities() {
        this.getCities(this.queryString);
      },
    }
  };
  </script>
  