<template lang="pug">
div(style="background:#78909C")
  v-img(
    class="mx-auto"
    :width="width"
    :src="urlFile")
  v-btn(
    class="button-download-image"
    title="Descargar Imagen"
    color="orange"
    class="ma-2 white--text"
    @click="download")
    v-icon(left dark) mdi-file-download
    | Descargar esta imagen
</template>

<script>

export default {
  props: {
    urlFile: {
      type: String,
      default: null
    }
  },

  name: 'ImagePreview',

  computed: {
  },

  data: () => ({
    width: '50vw',
    height: '500px',
  }),

  methods: {
    download() {
      var a = document.createElement('a');
      a.href = this.urlFile;
      a.download = "soporte_vur.png";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }
}
</script>

<style scoped>
.button-download-image {
  position: absolute;
  right: 0px;
  bottom: 80px;
}
</style>
