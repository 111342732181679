<template lang="pug">
div
  v-card(
    v-show="showSideDialog"
    class="side-wrapper"
    :style="{width: sideDialogWidth}"
    outlined)
    v-toolbar(
      height="40"
      :width="sideDialogWidth"
      style="position: fixed"
      color="indigo"
      dark)
      v-toolbar-title {{ sideDialogTitle }}
      v-spacer
      v-btn(icon @click="showSideDialog = false")
        v-icon mdi-close

    component(
      class="my-2"
      v-bind:is="sideDialogContent")
</template>

<script>
import { mapState } from 'vuex';
import AnnotationTimeline from '@/components/annotations/AnnotationTimeline.vue';

export default {
  name: 'CommonSideDialog',
  computed: {
    ...mapState('ui', ['sideDialogTitle', 'sideDialogContent', 'sideDialogWidth']),
    showSideDialog: {
      get() {
        return this.$store.state.ui.showSideDialog;
      },
      set(value) {
        return this.$store.commit('ui/TOGGLE_SIDE_DIALOG', value);
      }
    }
  },
  components: {
    AnnotationTimeline,
  },
  data () {
    return {}
  },
};
</script>

<style scoped>
.visor-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
}
.pdf-file {
  width: 100%;
  height: 100vh;
}
.side-wrapper {
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 10000;
  height: 100vh;
}
</style>