<template lang="pug">
v-row(class="mx-4")
  v-col(cols="8")
    v-file-input(
      @change="onFilePicked"
      :rules="rules"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      placeholder="Seleccione un archivo Excel"
      prepend-icon="mdi-file-excel"
      ref="fileExcel"
      label="Listado predios")
  v-col(cols="4")
    v-btn(
      color="green"
      :loading="loadingExcel"
      :disabled="file === null"
      class="ma-2 white--text"
      @click="sendFile")
      | cargar archivo
      v-icon(
        right
        dark) mdi-cloud-upload
  v-col(class="pt-0" cols="12")
    v-alert(
      v-if="excelError !== ''"
      border="top"
      color="light-green darken-2"
      v-html="excelError"
      dark)
    </v-alert>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';

export default {
  name: 'ApiExcelFile',
  components: {
  },
  computed: {
    ...mapState('ant', ['loadingExcel', 'excelError'],)
  },
  methods: {
    ...mapActions('ant', ['uploadFile']),
    ...mapMutations('ant', ['CLEAR_ERROR']),
    async sendFile() {
      await this.uploadFile({
        file: this.file
      })
      this.$refs.fileExcel.value = null;
      this.file = null;
    },
    onFilePicked(e) {
      this.CLEAR_ERROR();
      this.file=e;
    }
  },
  data: () => ({
    file: null,
    rules: [
      value => !value || value.size < 2000000 || 'File size should be less than 2 MB!',
    ],
  }),  
};
</script>
    