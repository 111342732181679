<template lang="pug">
v-card(
  class="mx-auto"
  max-width="400")
  v-btn(
    class="ml-2 mt-2"
    elevation="2"
    fab
    small
    icon
    @click="backFolio"
    title="Volver al folio")
    v-icon mdi-arrow-left
  annotation-detail 
</template>

<script>
import { mapMutations } from 'vuex';
import AnnotationDetail from '@/components/annotations/AnnotationDetail.vue'

export default {
  name: 'AnnotationSummary',
  components: {
    AnnotationDetail
  },
  methods: {
    ...mapMutations('ui', ['SET_DIALOG_CONTENT', 'TOGGLE_DIALOG', 'SET_DIALOG_TITLE']),    
    backFolio() {
      this.SET_DIALOG_CONTENT('FolioSummary');
      this.SET_DIALOG_TITLE('Información Folio');
    }
  },
  data: () => ({
  })
}
</script>
