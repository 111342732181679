<template lang="pug">
v-card(
  class="mx-auto"
  max-width="70%")
  v-list-item(two-line)
    v-list-item-content
      v-list-item-title(class="text-h5") Cargue el archivo excel
      v-list-item-subtitle
        | El excel debe contener el número predial en la primera columna Y/O el fmi en segunda columna 

  api-excel-file
  v-divider
  //- v-card-actions
    v-spacer
    v-btn(
      @click="sendCertificates"
      class="white--text"
      color='green') Consultar 
</template>

<script>
import { mapMutations } from 'vuex';
import ApiExcelFile from '@/components/ant/ApiExcelFile';

export default {
  name: 'ApiManager',
  components: {
    ApiExcelFile,
  },
  data: () => ({
  }),
  methods: {
    ...mapMutations('ui', ['SHOW_ALERT']),
  }
};
</script>
    