import CoreService from '@/services/CoreService.js';

const core = {
  namespaced: true,

  actions: {
    async cancelCeleryTask({ commit, rootState }, { taskId, module }) {
      /**
       * Acción para acncelar una tarea asincrona con el id celery
       *
       * @param {Object} context datos del modulo dentro del store
       * @param {Object} payload Datos de enetrada de la función
       * @returns {} No retorna nada
       * @example
       * $store.dispatch('cancelCeleryTask', {
       *  taskId: '2f57f8ef-a50d-4834-a25e-beb0d83de042',
       *  module: 'vur' | 'restrictions'
       * })
       * @author Luis Merizalde <luis.merizalde@arcerojas.com>
       */
      try {
        const token = localStorage.getItem('token');
        const response = await CoreService.cancelCeleryTask(taskId, token);
        if (response) {
          commit('ui/SHOW_ALERT',{
            alertMessage: 'Se enviaron correctamente los datos al sistema',
            alertType: 'success'
          }, {root: true});
          console.log(response);
          // limpeiar el id de la tarea celery
          rootState[module].celeryTaskId = '';
        }
      } catch (error) {
          console.log(error);
          commit('ui/SHOW_ALERT',{
            alertMessage: `Ocurrio un error al tratar de cancelar la tarea
              (${error.response.status} - ${error.response.data.detail})`,
            alertType: 'error'
          }, {root: true});
      }
    },
  },
};

export default core;
