<template lang="pug">
div(class="icon-wrapper" @click.stop="viewFile(file.file)")
  v-icon(
    :title="file.file.name"
    class="icon-pdf-wrapper"
    size="70"    
    color="red") mdi-file-outline
  v-icon(
    title="Eliminar folio"
    @click.stop="deleteFile(file.key)"
    class="close-icon" size="20") mdi-close
  span(class="text-file-size") {{fileSize}}
  span(class="text-file-name") {{fileName}}
</template>

<script>
import { mapMutations } from 'vuex';
import { filesizeFormat } from '@/utils.js';

export default {
  name: 'PdfFile',
  computed: {
    fileSize() {
      return filesizeFormat(this.file.file.size);
    },
    fileName() {
      const filename = this.file.file.name;
      return `${filename.slice(0,6)}...${filename.slice(filename.length - 4,filename.length)}`;
    }
  },
  methods: {
    ...mapMutations('files', ['REMOVE_FILE']),
    ...mapMutations('ui', ['SET_DIALOG_CONTENT', 'TOGGLE_DIALOG', 'SET_DIALOG_TITLE']),
    deleteFile(fileId) {
      this.REMOVE_FILE(fileId);
    },
    viewFile(file) {
      // cambiar el archivo de la propiedad de la vista previa del pdf
      this.$bus.$emit('update-properties', {
        urlFile: URL.createObjectURL(file)
      });
      this.SET_DIALOG_CONTENT('PdfPreview');
      this.SET_DIALOG_TITLE(`Visualizando ${file.name}`);
      this.TOGGLE_DIALOG(true);
    },
  },
  props: {
    file: {
      required: true
    }
  },
  data: () => ({
  })
}
</script>

<style scoped>
  .icon-wrapper {
    position: relative;
    width: fit-content;
  }
  .icon-pdf-wrapper {
    background: white;
    border-radius: 10px;
    padding-bottom: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .text-file-size {
    position: absolute;
    left: 22px;
    top: 70px;
    font-size: 12px;
  }
  .text-file-name {
    position: absolute;
    left: 15px;
    top: 90px;
    font-size: 12px;
  }
  .close-icon {
    position: absolute;
    top: 0px;
    right: 3px;
  }
</style>
