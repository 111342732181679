import { json2excel } from 'js2excel';

export function makeid(length) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function filesizeFormat(fileSize) {
  let formatSize = 0;
  let sufix = '';
  if (fileSize < 1000) {
    formatSize = fileSize;
    sufix = 'bytes';
  } else {
    fileSize /= 1024;
    if (fileSize < 1000) {
      formatSize = fileSize;
      sufix = 'kB';
    } else {
      fileSize /= 1024;
      if (fileSize < 1000) {
        formatSize = fileSize;
        sufix = 'MB';
      } else {
        fileSize /= 1024;
        formatSize = fileSize;
        sufix = 'GB';
      }
    }
  }

  return `${formatSize.toFixed(2)} ${sufix}`;
}

export function timeFormat (currentTime) {
  let hours = String(Math.trunc(currentTime / 3600));
  currentTime = currentTime - (hours * 3600);
  let minutes = String(Math.trunc(currentTime / 60));
  currentTime -= minutes * 60;
  let seconds = String(currentTime)
  let strTime = ''
  if (hours == '0') {
    strTime = `${minutes.length == 1 ? '0' + minutes : minutes}:${seconds.length == 1 ? '0' + seconds : seconds}`;
  } else {
    strTime = `${hours.length == 1 ? '0' + hours : hours}:${minutes.length == 1 ? '0' + minutes : minutes}:${seconds.length == 1 ? '0' + seconds : seconds}`;
  }

  return strTime
}

export function removeURLParameter(url, parameter) {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split('?');   
  if (urlparts.length >= 2) {

      var prefix = encodeURIComponent(parameter) + '=';
      var pars = urlparts[1].split(/[&;]/g);

      //reverse iteration as may be destructive
      for (var i = pars.length; i-- > 0;) {    
          //idiom for string.startsWith
          if (pars[i].lastIndexOf(prefix, 0) !== -1) {  
              pars.splice(i, 1);
          }
      }

      return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
}

export function downloadExcel(data, reportPrefix) {
  /*
  funcion paar descargar un excel
   */
  let dateString = new Date();
  dateString = dateString.toISOString().replace('T','_')
    .replace('Z','').replaceAll('-','_').replace(':','_').replace('.','_')
  try {
    json2excel({
      data,
      name: `${reportPrefix}_${dateString}`,
      // formateDate: 'yyyy/mm/dd'
    });
  } catch (e) {
      console.error('export error', e);
  }
}

export function findAllMatches(regex, testString) {
  /*
  funcion para encontrar todas las coincidencias en una cadena
  dada una expresion regular
   */
  const matches = [];
  var m;
  do {
    m = regex.exec(testString);
    if (m) {
      matches.push(m[0].trim());
    }
  } while (m);
  return matches;
}