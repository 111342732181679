<template lang="pug">
div
  v-btn(
    class="visor-button"
    color="primary"
    dark
    @click="dialog = true") Ver pdf
  v-card(
    v-show="dialog"
    class="pdf-wrapper"
    outlined)
    v-toolbar(
      height="40"
      width="40vw"
      style="position: fixed"
      color="indigo"
      dark)
      v-toolbar-title
        | Folio con matrícula: <b>{{record}}</b>
      v-spacer
      v-btn(icon @click="dialog = false")
        v-icon mdi-close
    embed(v-if="pdfExists" class="pdf-file pt-7" :src="pdfUrl" scrolling="no")
    //-div
      v-card-text
        v-icon mdi-file-document-remove
        v-card(class="mx-auto" v-else style="width: 50%;")
    div(v-else class="mx-auto text-center mt-30" style="margin-top: 50%;")
      v-icon(color="red" size="60") mdi-file-document-remove
      v-card-text(class="pb-0")
        p(class="text text-h4 text--primary") 404
        p(class="text-overline") El archivo que intenta consultar no esta disponible en el sistema. Comuniquese con el administrador del sitio para obtener más información.
      div(class="pt-0" v-if="currentFolio.es_vur")
        v-btn(class="black--text" color="amber" @click="getRebuildPDF" :loading="loading") 
          v-icon(left size="25") mdi-hammer-wrench
          | Reconstruir Archivo
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { staticUrl } from '@/env';
import axios from 'axios';

async function checkFileExists(url) {
  let fileExists = false;
  try {
    const response = await axios.head(url);
    if (response.status === 200) {
      fileExists = true;
    }
    console.log(response.status === 200 ? 'File exists' : 'File does not exist');
  } catch (error) {
    console.log('File does not exist');
  }
  return fileExists
}

export default {
  name: 'FolioVisor',
  props: {
    folioUrl: {
      type: String,
      required: true
    },
    record: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('folios', ['currentFolio']),
    pdfUrl() {
      return `${staticUrl}${this.folioUrl}`
    }
  },
  data () {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      pdfExists: false,
      loading: false,
    }
  },
  async updated() {
    if (this.folioUrl !== '') {
      this.pdfExists = await checkFileExists(`${staticUrl}${this.folioUrl}`);
    } else {
      this.pdfExists = false;
    }
  },
  methods: {
    ...mapActions('vur', ['reBuildPdf']),
    async getRebuildPDF() {
      this.loading = true;
      await this.reBuildPdf({folioId: this.currentFolio.id});
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.visor-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
}
.pdf-file {
  width: 100%;
  height: 100vh;
}
.pdf-wrapper {
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 10000;
  width: 40vw!important;
  height: 100vh;
}
</style>