<template lang="pug">
v-container(class="text-center" fluid)
  h1 Consulta de Folios VUR (máximo {{maxQueryCertificates}})
    v-btn(
      class="ml-3"
      to="/massive-vur"
      class="info"
      text)
      | Más de 5
      v-icon mdi-download-multiple
  vur-manager
</template>

<script>
import { mapMutations } from 'vuex';
import VurManager from '@/components/vur/VurManager';
import { maxQueryCertificates } from '@/config.js';

export default {
  name: 'VurSearch',
  components: {
    VurManager
  },

  data: () => ({
    maxQueryCertificates,
  }),

  methods: {
    ...mapMutations('vur', ['CHANGE_VALUE']),
  },

  mounted() {
    // limpiar certificados
    this.CHANGE_VALUE({
      varName: 'certificates',
      value: []
    });
    // realizar limpieza de la funcionalidad celery
    this.CHANGE_VALUE({
      varName: 'celeryTaskId',
      value: ''
    });
  }
};
</script>
