import apiClient, {authHeaders} from './config.js';

export default {
  async getFolios(token, pageSize, page, searchQuery) {    
    return await apiClient.get(`folios/?skip=${page}&limit=${pageSize}&search=${searchQuery}`, authHeaders(token));
  },
  async getFolioDetail(folioId, token) {
    return await apiClient.get(`folios/${folioId}/detail`, authHeaders(token));
  },
  async folioSearch(npn, npnOld, propertyRecord, cityCode, token) {
    return await apiClient.get(`folios/search?npn=${npn}&npn_old=${npnOld}&record=${propertyRecord}&city_code=${cityCode}`, authHeaders(token));
  },
  async searchFamily(propertyRecord, token) {
    return await apiClient.get(`folios/${propertyRecord}/family`, authHeaders(token));
  },
  async masiveFolioSearch(file, field, token) {
    const formData = new FormData();
    formData.append('file', file);
    apiClient.interceptors.request.use((config) => {
      config.headers = { ...{ 
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }, ...config.headers };
      return config;
    });
    return await apiClient.post(`folios/massive_search?field=${field}`, formData);
  },
};