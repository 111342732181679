<template lang="pug">
v-list-item(:key="person.nombre" two-line)
  v-list-item-icon
    v-icon(v-text="getPeopleIcon(person.nombre)")
  v-list-item-content
    v-list-item-title(class="d-flex flex-row") {{person.nombre}}
    v-list-item-subtitle(class="d-flex flex-row")
      div(class="mr-2" v-if="person.tipo_identificacion !== 'SI'")
        v-icon(title="Identificación" class="mr-1") mdi-card-account-details-outline 
        | {{person.tipo_identificacion}} - {{person.numero_identificacion}}
      div(v-if="person.porcentaje")
        v-icon(title="Porcentaje" class="mr-1") mdi-chart-pie
        | {{person.porcentaje}}
  //- v-list-item-icon
    v-icon(color="yellow" v-text="p.titularidad !== 'Real' ? 'mdi-star' : 'mdi-star-half-full'") 
  v-list-item-icon(v-if="person.titularidad !== 'Desconocido' && 'titularidad' in person")
    v-icon(
      v-text="person.titularidad === 'Real' ? 'mdi-star' : 'mdi-star-half-full'"
      color="yellow"
      size="30"
      :title="person.titularidad === 'Real' ? 'X-Titular de derecho real de dominio' : 'I-Titular de dominio incompleto'")
</template>

<script>
export default {
  name: 'PeopleDetail',
  props: {
    person: {
      type: Object,
      required: true
    },
  },
  methods: {
    
    getPeopleIcon(name) {
      let peopleName = name.toLowerCase();
      const companyStrings = [
        's.a',
        'ltda',
        'c.i',
        'limitada',
        'empresa',
        'sociedad',
        'sas',
        's.a.s.',
        'instituto',
        'company',
        'ltd.',
        'ltd',
      ];
      companyStrings.forEach(element => {
        peopleName.includes(element)
      });
      let findMatch = false;
      for (let index = 0; index < companyStrings.length; index++) {
        const element = companyStrings[index];
        if (peopleName.includes(element)) {
            findMatch = true;
            break;
          }
      }
      let icon = findMatch ? 'mdi-domain' : 'mdi-account';
      return icon;
    },
  }
};
</script>