<template lang="pug">
div
  v-list(
    v-if="vurMode && vurFamilyLogs.length > 0"
    class="truncated-folio-detail-height scrollable"
    subheader
    two-line)
    v-list-item(v-for="message, index in messageList" :key="index")
      v-list-item-content(v-if="message.header")
        h3 {{message.title}} | {{message.subtitle}}
      v-list-item-avatar(v-if="!message.header")
        v-icon(:class="message.success === true ? 'green' : 'red'" dark v-text="message.icon")
          | {{ message.success === true ? 'mdi-check-circle' : 'mdi-alert-circle' }}
      v-list-item-content(v-if="!message.header")
        v-list-item-title(v-text="message.title")
        v-list-item-subtitle(v-if="message.subtitle" style="text-wrap:balance")
          text-cutter(
            :text="message.subtitle"
            :text-length="150"
            modal-title="Información adicional")
      //- v-list-item-action(v-if="message.icon")
        v-btn(icon)
          v-icon(color="grey lighten-1") mdi-information
  div(
    v-else
    class="text-center"
    style="margin-top: 50% !important"
    )
    v-icon(size="40") mdi-list-status
    br
    span Listado eventos familia registral VUR

</template>

<script>
import { mapState } from 'vuex';
import TextCutter from '@/components/TextCutter';

export default {
  name: 'VurFamilyLogs',

  components: {
    TextCutter,
  },

  props: {
    messageList: {
      required: true,
      type: Array
    }
  },

  data: () => ({
  }),

  updated() {
    // console.log('UPDATED LIST TRADITION');
    // const el = document.getElementById('wrapper-tradition');
    // console.log(el);
    // console.log('el.scrollHeight', el.scrollHeight);
    // el.scrollTop = el.scrollHeight;
    // console.log('el.scrollTop', el.scrollTop);
    // const el = this.$refs.wrapperTradition;
    // el.scrollTop = 0;
  },

  computed: {
    ...mapState('vur', ['loadingVurFamily', 'vurMode', 'vurFamilyLogs']),
  },

  methods: {
    detailFolio(folioId) {
      const url = `${window.location.origin}/folios/${folioId}/detail`;
      window.open(url, "_blank");
    }
  }
};
</script>