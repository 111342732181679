<template lang="pug">
v-container(fluid)
  a(ref="downloadLink" hidden :href="graphData" download="grafo.png")
  v-toolbar(class="d-flex flex-row white--text" elevation="4" color="blue-grey" rounded)
    div(class="text-h4 mr-4") Consulta Familia Registral
    div(class="text-body-1") Traerá las matriculas <b>matrices</b> y <b>segregadas</b> que se encuentren disponibles en el sistema
  v-card(class="mt-4")
    v-form
      v-container
        v-row
          v-col(cols="12" md="6")
            fmi-picker(v-model="propertyRecord" ref="fmiNumber")
          v-col(cols="12" md="4")
            v-switch(
              v-if="hasPermission('can_use_vur')"
              v-model="vurMode"
              inset
              :label="vurMode ? 'Desactivar VUR' : 'Activar VUR'")
            //- Boton para cancelar la descarga de la familia registral
            v-btn(
              class="white--text"
              :loading="loadingCancel"
              :disabled="loadingCancel"
              v-if="celeryTaskId !== '' && vurMode"
              class="ma-2"
              color="red"
              @click="actionCancel")
              v-icon(class="mr-1" dark) mdi-cancel
              | Cancelar
            //- Boton para cargar familia registral
            v-btn(
              v-if="!vurMode"
              :disabled="loading"
              :loading="loading"
              ref="buttonSearch"
              color="green"
              class="ma-2 white--text"
              @click="search")
              v-icon(left dark) mdi-magnify
              | consultar
            v-btn(
              v-if="!!graphData"
              color="orange"
              class="ma-2 white--text"
              @click="download")
              v-icon(left dark) mdi-download
              | Descargar Grafo
            button-download-pdf(v-if="avalaibleCertificates.length > 0" class="ml-2" :folios="avalaibleCertificates")
            v-btn(
              v-if="!!graphData"
              color="green"
              class="ma-2 white--text"
              title="Descargar Excel con información folios"
              @click="downloadExcel")
              v-icon(left dark) mdi-file-excel
              | Descargar Excel
            div(v-if="vurMode" style="display:flex")
              v-checkbox(
                class="mr-2"
                v-model="checkboxParent"
                label="Matrices")
              v-checkbox(
                class="mr-2"
                v-model="checkboxChildren"
                label="Segregadas")
              v-checkbox(
                v-if="graphFolioNumber !== ''"
                style="width:max-content!important"
                class="mr-16"
                v-model="checkboxMissing"
                label="Solo Faltantes")
              v-btn(
                :disabled="loadingVurFamily"
                :loading="loadingVurFamily"
                color="green"
                class="ma-2 white--text"
                @click="searchVur")
                v-icon(left dark) mdi-robot
                | consulta VUR
  
  v-row
    v-col(:cols="truncatedFolios.length > 0 ? 9 : 12")
      folio-chart(class="mt-4" :folio-family="folioFamily" :current-folio="propertyRecord")
    v-col(cols="3")
      v-card(class="mt-4")
        v-card-title Listado Matriculas Truncadas
        v-list(class="my-0 py-0" dense)
          v-expansion-panels
            v-expansion-panel(v-for="(item,i) in truncatedFolios" :key="i")
              v-expansion-panel-header <b style="font-size:18px">{{ item.name }}</b> Matrículas Truncadas: {{truncatedRecords(item.truncated)}}
              v-expansion-panel-content
                span(v-if="item.truncated.parents.length > 0")
                  b(style="color:#F44336") Matriculas Matrices:
                  text-cutter(:text="item.truncated.parents.join(', ')"
                  modal-title="Matriculas segregadas matrices")
                span(v-if="item.truncated.children.length > 0")
                  b(style="color:#F44336") Matriculas Segregadas:
                  text-cutter(:text="item.truncated.children.join(', ')"
                  modal-title="Matriculas segregadas Truncadas")
</template>
  
<script>
import celeryMixin from '@/mixins/celeryMixin.js';
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import FolioChart from '@/components/folios/FolioChart.vue';
import FmiPicker from '@/components/ui/FmiPicker.vue';
import TextCutter from '@/components/TextCutter';
import ButtonDownloadPdf from '@/components/files/ButtonDownloadPdf';
import * as XLSX from 'xlsx';

export default {
  name: 'FolioFamily',

  components: {    
    FolioChart,
    FmiPicker,
    TextCutter, 
    ButtonDownloadPdf,  
  },

  mixins: [celeryMixin],

  data: () => ({
    propertyRecord: '',
    loading: false,
    checkboxParent: true,
    checkboxChildren: false,
    checkboxMissing: false,
    viewTruncated: true,
    alertMessage: `Consulta Familia Registral<br>
    <span class="text-body-1">Traerá las matriculas <b>matrices</b> y <b>segregadas</b> que se encuentren disponibles en el sistema</span>`
  }),

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('folios', ['truncatedFolios']),
    ...mapState('folios', ['folioFamily', 'graphData', 'graphFolioNumber']),
    ...mapState('vur', ['vurMode', 'loadingVurFamily', 'celeryTaskId']),
    vurMode: {
      get() {
        return this.$store.state.vur.vurMode;
      },
      set(value) {
        return this.$store.commit('vur/CHANGE_VALUE', {
          value,
          varName: 'vurMode'
        });
      }
    },
    avalaibleCertificates() {
      return this.folioFamily.filter((x) => x.avalaible === true).map((x) => x.name);
    },
  },

  mounted() {
    if ('fmi' in this.$route.query) {
      // Inicializar el numero de matricula inmobiliaria
      const { fmi } = this.$route.query;
      const [circle, number] = fmi.split('-')
      this.$refs.fmiNumber.selectedCircle = circle;
      this.$refs.fmiNumber.recordText = number;
      this.$refs.fmiNumber.valid = true;
      this.propertyRecord = fmi;
      // ejecutar la funcion de consulta
      setTimeout(() => this.search(), 1000);
      // eliminar el parametro de la ruta
      window.history.replaceState({}, document.title, "/" + "family");
    }
    // realizar limpieza de la funcionalidad celery
    this.CHANGE_VALUE({
      varName: 'loadingVurFamily',
      value: false
    });
    this.CHANGE_VALUE({
      varName: 'celeryTaskId',
      value: ''
    });
  },

  methods: {
    ...mapActions('folios', ['searchFamily']),
    ...mapActions('vur', ['searchVurFamily', 'completeVurFamily']),
    ...mapMutations('ui', ['SHOW_ALERT']),
    ...mapMutations('folios', ['CLEAR_SEARCH']),
    ...mapMutations('vur', ['CHANGE_VALUE']),
    // metodo para descargar el grafo png
    download() {
      this.$refs.downloadLink.click();
    },
    // metodo para realizar la busqueda de la familia registral
    async search() {
      if (!this.$refs.fmiNumber.validate()) {
        alert('Debe introducir un número de matricula valido.')
        return
      }
      this.loading = true;
      try {
        await this.searchFamily({
          propertyRecord: this.propertyRecord,
        });
      } catch (error) {
        this.SHOW_ALERT({
          alertMessage:'Ocurrio un error al cargar la información',
          alertType: 'error'
        });
      }      
      this.loading = false;
    },
    // función para descargar los datos del VUR
    async searchVur() {
      // validar eque se haya introducido un numero de matricula valido
      if (!this.$refs.fmiNumber.validate()) {
        alert('Debe introducir un número de matricula valido.')
        return
      }
      // Validar que se vayan a descargar matrice y/o segregados
      if (!this.checkboxChildren && !this.checkboxParent) {
        alert('Debe marcar por lo menos una de las opciones de tradición.');
        return;
      }
      try {
        if (this.checkboxMissing === true) {
          await this.completeVurFamily({
            propertyRecord: this.propertyRecord,
            downloadParent: this.checkboxParent,
            downloadChildren: this.checkboxChildren,
          });
        } else {
          await this.searchVurFamily({
            propertyRecord: this.propertyRecord,
            downloadParent: this.checkboxParent,
            downloadChildren: this.checkboxChildren,
          });
        }
      } catch (error) {
        console.log(error);
        this.SHOW_ALERT({
          alertMessage:'Ocurrio un error al cargar la información',
          alertType: 'error'
        });
      }      
    },
    // método para calcular el número de matricular truncadas
    truncatedRecords(truncated) {
      return truncated.parents.length + truncated.children.length;
    },
    // metodo para descargar excel con la información de la familia
    downloadExcel() {
      const data = []
      for (let index = 0; index < this.folioFamily.length; index++) {
        const element = this.folioFamily[index];
        let relation = ''
        if (element.name === this.propertyRecord) {
          relation = 'Raíz'
        } else {
          relation = element.relation_type === 'PARENT' ? 'Matriz' : 'Segregado';
        }
        data.push([
          element.name,
          relation
        ]);
      }
      let dateString = new Date();
      dateString = dateString.toISOString().replace('T','_')
        .replace('Z','').replaceAll('-','_').replace(':','_').replace('.','_')
      // Crear una nueva hoja de cálculo
      const ws = XLSX.utils.aoa_to_sheet(data);

      // Crear un nuevo libro de trabajo
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Folios");

      // Generar y descargar el archivo Excel
      XLSX.writeFile(wb, `familia_${dateString}.xlsx`);
    }
  }
};
</script>