<template lang="pug">
v-row(class="mx-4")
  v-col(cols="8")
    v-file-input(
      @change="onFilePicked"
      :rules="rules"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      placeholder="Seleccione un archivo"
      prepend-icon="mdi-file-excel"
      ref="fileExcel"
      label="Listado números de matrícula")
  v-col(cols="4")
    v-btn(
      color="green"
      :loading="loading"
      :disabled="file === null"
      class="ma-2 white--text"
      @click.stop="sendFile")
      | {{ buttonMessage }}
      v-icon(
        right
        dark) {{ buttonIcon }}
  v-col(class="pt-0" cols="12")
    v-alert(
      v-if="errorMessage !== ''"
      border="top"
      color="red lighten-2"
      v-html="errorMessage"
      dark)
    </v-alert>
</template>

<script>
// import { mapActions } from 'vuex';

export default {
  name: 'ExcelFileAction',
  props: {
    buttonMessage: {
      type: String,
      default: 'cargar archivo'
    },
    buttonIcon: {
      type: String,
      default: 'mdi-cloud-upload'
    },
    loading: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    // loading: false,
    // errorMessage: '',
    file: null,
    rules: [
      value => !value || value.size < 2000000 || 'File size should be less than 2 MB!',
    ],
  }),
  components: {
  },
  computed: {
  },
  methods: {
    // ...mapActions('vur', ['uploadFile']),
    async sendFile() {
      console.log('entro sendfile excel');
      // emitir evento con el archivo
      this.$bus.$emit('file-loaded', this.file);
      // await this.uploadFile({
      //   file: this.file
      // })
      this.$refs.fileExcel.value = null;
      this.file = null;
      
    },
    onFilePicked(e) {
      this.file=e;
    }
  },
    
};
</script>
  