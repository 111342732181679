<template lang="pug">
v-container(fill-height fluid)
  v-card(class="mx-auto my-auto meteo-form pa-4 text-center" max-width="400" min-width="350" outlined)
    v-alert(
      v-if="errorMessage"
      border="top"
      :color="alertColor"
      dark) {{errorMessage}}
    v-form(ref="form" v-model="valid" lazy-validation)
      v-text-field(
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required]"
        :type="show ? 'text' : 'password'"
        name="input-10-2"
        label="Usuario"
        hint="At least 8 characters"
        v-model="username"
        class="input-group--focused"
        @click:append="show = !show")
      v-text-field(
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required]"
        :type="show1 ? 'text' : 'password'"
        name="input-10-2"
        label="Contraseña"
        v-model="password"
        class="input-group--focused"
        @click:append="show1 = !show1")
      v-btn(
        :disabled="!valid"
        color="success"
        class="mr-4"
        @click="login") Guardar
</template>

<script>

export default {
  name: 'VurCredentialsForm',
  data: () => ({
    errorMessage: '',
    valid: true,
    show: false,
    show1: false,
    username: null,
    password: null,
    alertColor: 'red lighten-2',
    rules: {
      required: (value) => !!value || 'Campo obligatorio.',
      min: (v) => v.length >= 8 || 'Minimo 8 caracteres',
    },
    nameRules: [
      (v) => !!v || 'El nombre de usuario es obligatorio',
    ],
  }),
  mounted() {
    this.$store.dispatch('vur/getVurCredentials')
      .then((resp) => {
        if ('vur_user' in resp) {
          this.username = resp.vur_user;
          this.password = resp.vur_password;
          this.errorMessage = '';
        } else {
          this.errorMessage = resp;
        }
      })
      .catch((err) => {
        console.log('entro vur credentials catch', err);
      });
  },
  methods: {
    login() {
      this.errorMessage = '';
      const isValid = this.$refs.form.validate();
      if (isValid === false) {
        return;
      }
      const { username, password } = this;
      this.$store.dispatch('vur/changeVurCredentials', { username, password })
        .then((resp) => {
          console.log('entro changeVurCredentials then', resp);
          if (resp !== 'success') {
            this.errorMessage = resp;
            this.alertColor = 'red lighten-2';
          } else {
            // si el logueo es exitoso volver a recargar las
            // aplicaciones para obtener las correspondientes
            // al usuario en cuestión
            this.errorMessage = 'Credendiales actualizadas correctamente';
            this.alertColor = 'green lighten-2';
          }
          setTimeout(() => this.errorMessage = '', 3000);
        })
        .catch((err) => {
          console.log('entro login catch', err);
        });
    }
  }
}
</script>
    