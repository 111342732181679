<template lang="pug">
v-card(
  class="mx-auto"
  max-width="80%")
  //- link escondido para descargar el reporte
  a(ref="downloadLink" hidden download="massive_report.xlsx")
  v-list-item(two-line)
    v-list-item-content
      v-list-item-title(class="text-h5") Generar Reporte (Máximo <b>{{maxZipPdfs}}</b> folios)
  span(class="text-left")
    v-icon(color="red" size="30") mdi-numeric-1-circle
    | Para buscar los archivos cargue el archivo Excel con el listado de matrículas comenzando desde la celda A1
    | <b style="color:red">SOLO SE ENTREGARÁ RESULTADO DE LOS FOLIOS QUE ESTÁN EN EL SISTEMA.</b>
  v-file-input(
    class="mx-4"
    v-model="file"
    :rules="rules"
    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    placeholder="Seleccione un archivo"
    prepend-icon="mdi-file-excel"
    ref="fileExcel"
    label="Listado números de matrícula")
  v-divider
  span(class="text-left")
    v-icon(color="red" size="30") mdi-numeric-2-circle
    | Seleccione alguno de los formatos para el Reporte
  v-row(class="mt-2 mx-3")
    v-col
      v-select(
        v-model="selectedReport"
        dense
        :items="reportTemplates"
        item-text="name"
        item-value="name"
        filled
        label="Seleccione una plantilla")
    v-col
      span(class="text-caption")
        | {{getSelectedReport !== null ? getSelectedReport.desc : 'Seleccione una plantilla'}}
  v-divider
  span(class="text-left")
    v-icon(color="red" size="30") mdi-numeric-3-circle
    | Tambien puede seleccionar los campo de forma manual
  //- Secciones con los elementos que pueden ser seleccionados del reporte
  v-row(v-if="reportData === null" class="mt-1 px-3")
    v-col
      report-folio-section(
        section-color="green"
        section-title="Campos Principales"
        :fields="getVariablesSection('Main')")
    v-col
      report-folio-section(
        section-color="red"
        section-title="Campos Vur"
        section-icon="mdi-robot"
        :fields="getVariablesSection('Vur')")
      report-folio-section(
        class="mt-2"
        section-title="Campos Tradicion"
        section-icon="mdi-family-tree"
        :fields="getVariablesSection('Tradition')")
      report-folio-section(
        class="mt-2"
        section-title="Restitución de Tierras"
        section-icon="mdi-land-plots-marker"
        section-color="cyan"
        :fields="getVariablesSection('Restitution')")
    v-col
      report-folio-section(
        section-color="purple"
        section-title="Sección Jurídica"
        section-icon="mdi-gavel"
        :fields="getVariablesSection('Legal')")
    v-col
      report-folio-section(
        section-color="blue"
        section-title="Información Secundaria"
        :fields="getVariablesSection('Secondary')")
    v-col
      report-folio-section(
        section-color="lime"
        section-icon="mdi-earth"
        section-title="Información de Catastro"
        :fields="getVariablesSection('Register')")
    v-col
      report-folio-section(
          section-color="teal"
          class="mt-2"
          section-title="Campos Analisis"
          section-icon="mdi-head-cog"
          :fields="getVariablesSection('Analysis')")
  report-preview(v-else)
  v-divider(class="mt-3")

  v-card-actions
    v-spacer
    v-btn(
      small
      v-if="!!reportData"
      color="blue"
      class="ma-2 white--text"
      @click="clearReport")
      v-icon(left dark) mdi-sync
      | Otra consulta
    v-btn(
      small
      v-if="!!reportData"
      color="orange"
      class="ma-2 white--text"
      @click="download")
      v-icon(left dark) mdi-download
      | Descargar Reporte
    v-btn(
      @click="generateReport"
      :loading="loadingReport"
      :disabled="false"
      class="success"
      small
      color="white"
    ) Generar Reporte
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import { json2excel } from 'js2excel';
import ExcelFileAction from '@/components/ui/ExcelFileAction';
import ReportFolioSection from '@/components/reports_cmp/ReportFolioSection';
import ReportPreview from '@/components/reports_cmp/ReportPreview';
import { maxQueryCertificates } from '@/config.js';
import { maxZipPdfs } from '@/config'

export default {
  name: 'MassiveFolioReportManager',

  components: {
    ExcelFileAction,
    ReportFolioSection,
    ReportPreview,
  },

  data: () => ({
    // el arcghivo excel con los folios
    file: null,
    // indica si se encontro error en el archivo excel
    rules: [
      value => !value || value.size < 2000000 || 'File size should be less than 2 MB!',
    ],
    // elementos d elos reportes 
    maxQueryCertificates,
    // el numero maximo de pdfs que se pueden generar
    maxZipPdfs,
  }),

  computed: {
    ...mapGetters('reports', [
      'getVariablesSection',
      'getCheckedVariables',
      'getSelectedReport'
    ]),
    ...mapState('reports', [
      'reportTemplates',
      'selectedReport',
      'loadingReport',
      'excelFile',
      'reportData',
      'managerVariables',
    ]),
    selectedReport: {
      get() {
        return this.$store.state.reports.selectedReport;
      },
      set(value) {
        return this.$store.dispatch('reports/updateManagerVariables', value);
      }
    }
  },

  mounted() {
  },

  methods: {
    ...mapActions('reports', ['sendReport']),
    ...mapActions('vur', ['populateFolios', 'getCircles']),
    ...mapMutations('reports', ['CHANGE_VALUE']),
    // metodo para validar y enviar la generación del reporte
    async generateReport() {
      // validar que por lo menos exista una variable seleccionada
      if (this.getCheckedVariables === 0) {
         alert('Debe seleccionar por lo menos una variable para el reporte');
         return;
      }
      // validar que se haya cargado un archivo
      if (this.file === null) {
        alert('Debe subir el archivo con los folios');
        return;
      }
      // lanzar confirmación cuando hay variables de restitución de tierras
      const restitutionChecked = this.managerVariables.filter((x) => x.checked === true && x.section === 'Restitution').length;
      if (restitutionChecked > 0) {
        let text = `
          La consulta de variables de la sección de Restitución de Tierras
          hará que la generación del reporte demore más.
          ¿Desea continuar con esta acción?
        `;
        if (confirm(text) == false) {
          return;
        }
      }
      // enviar datos al servidor
      this.sendReport(this.file);
    },
    // metodo para descargar el reporte generado
    download() {
      // this will be export a excel and the file's name is user-info-data.xlsx
      // the default file's name is excel.xlsx
      let data = [];
      // agragar las cabeceras del reporte
      const headers = {};
      for (let index = 0; index < this.reportData.headers.length; index++) {
        const element = this.reportData.headers[index];
        // const dataElement = {};
        headers[element.variable] = element.name;
        // headers.push(dataElement);
      }
      data.push(headers);
      // agregar los datos del reporte
      data = data.concat(this.reportData.report_data);
      let dateString = new Date();
      dateString = dateString.toISOString().replace('T','_')
        .replace('Z','').replaceAll('-','_').replace(':','_').replace('.','_')
      try {
          json2excel({
              data,
              name: `folios_${dateString}`,
              // formateDate: 'yyyy/mm/dd'
          });
      } catch (e) {
          console.error('export error', e);
      }
    },
    // metodo para limpiar el reporte y generar otra consulta
    clearReport() {
      this.file = null;
      this.CHANGE_VALUE({
        varName: 'reportData',
        value: null
      })
    }
  }
};
</script>
    