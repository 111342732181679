import { staticUrl } from '@/env';
import { firstLimitDaysFolio, secondLimitDaysFolio } from '@/config'

export default {
  data() {
    return {
    };
  },
  methods: {
    detailFolio(folioId) {
      this.$router.push({
        name: 'FolioDetail',
        params: {folioId}
      });
      // cerrar el modal
      this.$store.dispatch('ui/toggleDialog', false);
    },
    // abrir folio en una ventana nueva
    targetFolio(folioId) {
      this.$store.dispatch('ui/toggleDialog', false);
      const routeData = this.$router.resolve({
        name: 'FolioDetail',
        params: {folioId}
      });
      window.open(routeData.href, '_blank');
    },
    analysisFolio(folioId) {
      this.$router.push({
        name: 'FolioAnalysis',
        params: {folioId}
      });
      // cerrar el modal
      this.$store.dispatch('ui/toggleDialog', false);
    },
    // mostrar PDF del folio en un modal
    showPdf(url) {
      this.$bus.$emit('update-properties', {
        urlFile: `${staticUrl}${url}`
      });
      this.$store.commit('ui/SET_DIALOG_CONTENT', 'PdfPreview');
      this.$store.commit('ui/SET_DIALOG_TITLE', 'Archivo Folio');
      this.$store.commit('ui/TOGGLE_DIALOG', true);
    },
    // mostrar PDF de una url completa
    showEntirePdf(url, title) {
      this.$bus.$emit('update-properties', {
        urlFile: url
      });
      this.$store.commit('ui/SET_DIALOG_CONTENT', 'PdfPreview');
      this.$store.commit('ui/SET_DIALOG_TITLE', title);
      this.$store.commit('ui/TOGGLE_DIALOG', true);
    },
    // mostrar imagen en modal
    showModalImage(url, title) {
      this.$bus.$emit('update-properties', {
        urlFile: url
      });
      this.$store.commit('ui/SET_DIALOG_CONTENT', 'ImagePreview');
      this.$store.commit('ui/SET_DIALOG_TITLE', title);
      this.$store.commit('ui/TOGGLE_DIALOG', true);
    },
    getStatus: function (date) {
      /*
      Funcion para establecer el color de fondo de una fila según 
      la fecha de impresión del folio
      */
      const printedDay = new Date(date);
      const today = new Date();
      let difference = today.getTime() - printedDay.getTime();
      let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
      let info = {
        color: 'red',
        title: 'Folio no apto para estudio titulos',
        message: `Fecha Impresión > ${secondLimitDaysFolio} días`
      };
      if (totalDays < firstLimitDaysFolio) {
        info.color = 'green';
        info.title = 'Folio Apto para estudio titulos';
        info.message = `Fecha Impresión < ${firstLimitDaysFolio} días`;
      }
      if (totalDays > firstLimitDaysFolio && totalDays <= secondLimitDaysFolio) {
        info.color = 'yellow';
        info.title = 'Revisar aptitud';
        info.message = `Fecha Impresión entre ${firstLimitDaysFolio} y ${secondLimitDaysFolio} días días`;
      }
      return info;
    },
  }
};
