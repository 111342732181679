<template lang="pug">
div {{viewedText}}
  v-btn(
    v-if="text.length > textLength"
    small
    class="ma-2"
    :color="buttonColor"
    @click="viewtext") {{message}}
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'TextCutter',
  props: {
    text: {
      type: String,
      required: true
    },
    textLength: {
      type: Number,
      default: 50
    },
    modalTitle: {
      type: String,
      required: true
    },
    message: {
      type: String,
      default: 'ver más'
    },
    buttonColor: {
      type: String,
      default: 'info'
    }
  },
  computed: {
    viewedText() {
      return this.text.length > this.textLength ? `${this.text.slice(0, this.textLength)} ...` : this.text;
    }
  },
  methods: {
    ...mapMutations('ui', ['TOGGLE_DIALOG', 'SET_DIALOG_TITLE', 'SET_DIALOG_TEXT']),
    viewtext() {
      this.SET_DIALOG_TEXT(this.text);
      this.SET_DIALOG_TITLE(this.modalTitle);
      this.TOGGLE_DIALOG(true);
    }
  },
  data: () => ({
  }),
}
</script>