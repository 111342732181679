<template lang="pug">
div(style="height: 350px; width: 100%; overflow: hidden")
  l-map(
    v-if="showMap && this.coordinates.length > 0"
    :zoom="zoom"
    :center="trueCoordinates[0]"
    :options="mapOptions"
    ref="myMap"
    @update:center="centerUpdate"
    @update:zoom="zoomUpdate"
    @ready="lefletMapReady()")
    l-control-layers
    l-control(
      class="example-custom-control clickable"
      title="Encuadrar el predio")
      v-icon(
        class="pt-1 pl-1"
        size="35"
        @click="focusProperty"
        color="grey darken-1"
        ) mdi-target
    l-control-scale(position="bottomleft" :imperial="false" :metric="true")
    l-wms-tile-layer(
      v-for="l in layers"
      :key="l.name"
      :base-url="l.url"
      :layer-type="l.type"
      :layers="l.layers"
      :transparent="l.transparent"
      format="image/png"
      :name="l.name")
    l-polygon(
      ref="polygon"
      :weight="4"
      :fill-color="polygonColor"
      :lat-lngs="trueCoordinates"
      @ready="polygonReady"
      :color="polygonColor")
  div(
    v-else
    style="background:#80CBC4; height:100%"
    class="text-body-2 d-flex flex-column text-center pt-16")
    v-icon(size="50") mdi-map-marker-off-outline
    | {{ message }}
</template>

<script>
import { latLng } from "leaflet";
import {
  LMap,
  LWMSTileLayer,
  LPolygon,
  LControlLayers,
  LControl,
  LControlScale,
} from "vue2-leaflet";
import mapMixin from '@/mixins/mapMixin.js';

export default {
  name: 'PropertyVisor',

  components: {
    LMap,
    LPolygon,
    LControlLayers,
    'l-wms-tile-layer': LWMSTileLayer,
    LControl,
    LControlScale
  },

  mixins: [mapMixin],

  props: {
    coordinates: {
      type: Array,
      required: true
    },
    message: {
      type: String,
      default: 'No fue posible encontrar el poligono del predio'
    },
    reverseCoords: {
      type: Boolean,
      default: true
    },
  },

  data: () => ({
    withPopup: latLng(47.41322, -1.219482),
    withTooltip: latLng(47.41422, -1.250482),
    showParagraph: false,
    showMap: false,
    map: null,
  }),

  computed: {
    trueCoordinates() {
      if (this.coordinates.length < 1) {
        return;
      }
      let coords;
      if (this.reverseCoords === true) {
        try {
          coords = this.coordinates[0].map((x) => x.reverse());
        } catch (error) {
          coords = this.coordinates.map((x) => x.reverse());
        }
      } else {
        coords = this.coordinates[0].map((x) => {
          return {lat:x[1], lng:x[0]}});
      }
      
      return coords;
    }
  },

  async mounted() {
    this.showMap = await true;
  },

  methods: {
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    },
    
  }
};
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 300px;
  overflow: hidden;
}
.example-custom-control {
background: #fff;
width: 48px;
height: 48px;
/* padding: 0 0.5em; */
border: 1px solid #aaa;
border-radius: 4px;
}
</style>