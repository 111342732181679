import Vue from 'vue';
import VueRouter from 'vue-router';

// Application Views
import FoliosList from '../views/FoliosList.vue';
import FoliosUpload from '../views/FoliosUpload.vue';
import FolioDetail from '../views/FolioDetail.vue';
import NotFound from '../views/NotFound.vue';
import Test from '../views/Test.vue';
import RegistrationCircles from '../views/RegistrationCircles.vue';
import LoginView from '../views/LoginView.vue';
import FolioAnalysis from '../views/FolioAnalysis.vue';
import EspecificationDict from '../views/EspecificationDict.vue';
import FolioSearch from '../views/FolioSearch.vue';
import ChangePassword from '../views/ChangePassword.vue';
import VurSearch from '../views/VurSearch.vue';
import VurCredentials from '../views/VurCredentials.vue';
import RestrictiveLists from '../views/RestrictiveLists.vue';
import VurMassiveSearch from '../views/VurMassiveSearch.vue';
import APIReport from '../views/APIReport.vue';
import FolioFamily from '../views/FolioFamily.vue';
import MassiveFolioSearch from '../views/MassiveFolioSearch.vue';
import VurOwner from '../views/VurOwner.vue';
import DownloadPdf from '../views/DownloadPdf.vue';
import DynamicReportView from '../views/DynamicReportView.vue';
import CoordinateConversorView from '../views/CoordinateConversorView.vue';
// Vuex
import store from '../store';

// Usar el router en la aplicacion Vue
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: () =>
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      ({ path: '/folios' })
    ,
  },
  {
    path: '/folios',
    name: 'FoliosList',
    component: FoliosList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/upload',
    name: 'FoliosUpload',
    component: FoliosUpload,
    meta: {
      requiresAuth: true,
      permission: 'can_add_folio'
    }
  },
  {
    path: '/folios/:folioId/detail',
    name: 'FolioDetail',
    component: FolioDetail,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/folios/:folioId/analysis',
    name: 'FolioAnalysis',
    component: FolioAnalysis,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  {
    path: '/especifications',
    name: 'EspecificationDict',
    component: EspecificationDict,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/map',
    name: 'RegistrationCircles',
    component: RegistrationCircles,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/search',
    name: 'Search',
    component: FolioSearch,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/massive-search',
    name: 'MassiveSearch',
    component: MassiveFolioSearch,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/family',
    name: 'Family',
    component: FolioFamily,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/vur',
    name: 'Vur',
    component: VurSearch,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/vur-credentials',
    name: 'VurCredentials',
    component: VurCredentials,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/restrictive-lists',
    name: 'RestrictiveLists',
    component: RestrictiveLists,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/massive-vur',
    name: 'VurMassiveSearch',
    component: VurMassiveSearch,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/owner-vur',
    name: 'VurOwner',
    component: VurOwner,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/api-report',
    name: 'APIReport',
    component: APIReport,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dynamic-report',
    name: 'DynamicReportView',
    component: DynamicReportView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/conversor',
    name: 'DynamicReportView',
    component: CoordinateConversorView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/not-found',
    component: NotFound,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/download-pdfs',
    component: DownloadPdf,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // ocultar los dialogos con el cambio de url
  store.commit('ui/TOGGLE_SIDE_DIALOG', false);
  store.commit('ui/TOGGLE_DIALOG', false);

  // eliminar la consulta de busqueda por npn
  if (from.name == 'Search') {
    store.commit('folios/CLEAR_SEARCH');
  }
  // eliminar listado vur al salir carga massiva
  if (from.name == 'VurMassiveSearch') {
    store.commit('vur/CLEAR_SEARCH');
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // // verificar si la vista tiene permisos
    if ('permission' in to.matched[0].meta) {
      const permission = to.matched[0].meta.permission;
      const hasPermission = store.getters['auth/hasPermission'](permission);
      if (!hasPermission) {
        next('/folios');
        return;
      }
    }
    if (store.getters['auth/isLoggedIn']) {
      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
});

export default router;