<template lang="pug">
bar(
  :chart-options="chartOptions"
  :chart-data="chartData"
  :chart-id="chartId"
  :dataset-id-key="datasetIdKey"
  :plugins="plugins"
  :css-classes="cssClasses"
  :styles="styles"
  :width="width"
  :height="graphHeight")
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'OwnerGraph',
  components: {
    Bar
  },
  props: {
    labels: {
      type: Array,
      required: true,
    },
    graphData: {
      type: Array,
      required: true,
    },
    graphColors: {
      type: Array,
      required: true,
    },
    chartId: {
      type: String,
      default: 'owner-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 1000
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    let graphHeight = 400;
    let ownerCount = this.graphData.length;
    if (ownerCount > 10) {
      graphHeight = ownerCount * 40;
    }
    let bgColors = this.graphColors.map((x) => `${x}bf`);
    return {
      chartData: {
        labels: this.labels,
        datasets: [
          {
            label: 'Propietarios',
            backgroundColor: bgColors,
            borderColor: this.graphColors,
            borderWidth: 3,
            borderSkipped: false,
            borderRadius: 20,
            data: this.graphData
          }
        ]
      },
      graphHeight,
      chartOptions: {
        indexAxis: 'y', // grafico vertical
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            yAlign: 'bottom',
            callbacks: {
              label: (context) => {
                const d1 = context.raw[0];
                const d2 = context.raw[1];
                let formattedLabel = `${d1.getFullYear()}/${d1.getMonth() + 1}/${d1.getDate()} al ${d2.getFullYear()}/${d2.getMonth() + 1}/${d2.getDate()}`;
                return formattedLabel;
              }
            }
          }
        },
        scales: {
          x: {
            ticks: {
              // paso cada año
              stepSize: 31536000000,
              callback: (val) => {
                const date = new Date(val);
                return date.getFullYear();
              }
            }
          }
        }
      }
    }
  }
}
</script>
