var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('a',{ref:"exportLink",attrs:{"href":"","id":"export","hidden":""}}),_c('v-card-title',[_c('v-text-field',{attrs:{"dense":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.reportData.report_data,"search":_vm.search},scopedSlots:_vm._u([(_vm.headers.filter(function (x) { return x.value === 'complementacion'; }).length > 0)?{key:"item.complementacion",fn:function(ref){
var item = ref.item;
return [(item.complementacion)?_c('text-cutter',{attrs:{"text":item.complementacion,"text-length":50,"modal-title":"Complementación"}}):_vm._e()]}}:null,(_vm.headers.filter(function (x) { return x.value === 'linderos_geos'; }).length > 0)?{key:"item.linderos_geos",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.linderos_geos))]),(item.linderos_geos.includes('urn:ogc:def:crs:OGC:1.3:CRS84'))?_c('v-btn',{staticClass:"ml-2 white--text",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.downloadJson(item)}}},[_vm._v("Descargar GeoJSON")]):_vm._e()]}}:null,(_vm.headers.filter(function (x) { return x.value === 'cabida'; }).length > 0)?{key:"item.cabida",fn:function(ref){
var item = ref.item;
return [(item.cabida)?_c('text-cutter',{attrs:{"text":item.cabida,"text-length":50,"modal-title":"Cabida"}}):_vm._e()]}}:null,(_vm.headers.filter(function (x) { return x.value === 'linderos_tecnicos'; }).length > 0)?{key:"item.linderos_tecnicos",fn:function(ref){
var item = ref.item;
return [(item.linderos_tecnicos)?_c('text-cutter',{attrs:{"text":item.linderos_tecnicos,"text-length":50,"modal-title":"Linderos Tecnicos"}}):_vm._e()]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }