import apiClient from './config.js';

export default {
  async cancelCeleryTask(taskId, token) {
    apiClient.interceptors.request.use((config) => {
      config.headers = { ...{ 
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }, ...config.headers };
      return config;
    });
    return apiClient.post(`core/cancel_task/${taskId}`);
  },
};