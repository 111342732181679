<template lang="pug">
div(class="d-flex flex-column")
  div(v-if="!file.ready")
    span(v-if="file.message" class="text-body-1") {{file.message.slice(0,100)}}
    div(:style="{width: massive ? '80px' : '200px'}" 
        :class="['my-0', 'animated-progress', file.error ? 'error-color' : 'progress-color']")
      span(:data-progress="file.progress" :style="{ width: calcProgress }")
    span(v-if="massive && file.progress == 100") {{ file.certificate }}
  v-btn(
    v-else 
    small
    color="info"
    elevation="2"
    @click="targetFolio(file.folioId)"
  ) Ver folio
  span(
    v-if="!file.error"
    class="text-caption") {{ellapsedTime}}
</template>

<script>
import { timeFormat } from '@/utils';
import folioMixin from '@/mixins/folioMixin.js';

export default {
  name: 'ProgresSlider',
  mixins: [folioMixin],
  props: {
    file: {
      type: Object,
      required: true
    },
    massive: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    timeP: null,
    timer: null
  }),
  watch: {
    file: {
      handler(val){
        if (val.endProcess) {
          clearInterval(this.timer);
        }
        if (val.error === true) {
          clearInterval(this.timer);
        }
      },
      deep: true
    }
  },
  created() {
    const currentDate = new Date();
    this.timeP = 0;
    if (!this.file.endProcess) {
      this.timeP = this.secondDifference(currentDate, this.file.initProcess);
    } else {
      this.timeP = this.secondDifference(this.file.endProcess, this.file.initProcess);
    }
    if (!this.file.endProcess) {
      let inst = this;
      this.timer = setInterval(function () {
        inst.timeP = inst.timeP + 1;
      }, 1000);
    }    
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  computed: {
    calcProgress() {
      return `${this.file.progress}%`
    },
    ellapsedTime() {
      return timeFormat(this.timeP);
    }
  },
  methods: {
    secondDifference(endDate, initDate) {
      return parseInt((endDate.getTime() - initDate.getTime()) / 1000);
    },
  }
}
</script>

<style scoped>
.animated-progress {
  height: 18px;
  border-radius: 10px;
  margin: 20px 10px;
  border: 2px dashed gray;
  overflow: hidden;
  position: relative;
  background-color: #DCEDC8;
}
.animated-progress span {
  height: 100%;
  display: block;
  width: 0;
  color: rgb(255, 251, 251);
  line-height: 18px;
  position: absolute;
  text-align: end;
  padding-right: 5px;
}
.progress-color span {
  background-color: #4CAF50;
}
.error-color span {
  background-color: #E53935;
}
</style>