<template lang="pug">
v-container(fluid)
  v-alert(
    class="text-h4"
    border="bottom"
    color="blue-grey"
    dark
    v-html="alertMessage")
  v-card
    v-form
      v-container
        v-row
          v-col(cols="12" md="4")
            v-text-field(
              v-model="npn"
              label="Número predial o Número predial anterior"
              required)
          //- v-col(cols="12" md="4")
            v-text-field(
              v-model="npnOld"
              label="Número Predial Anterior"
              required)
          v-col(cols="12" md="4")
            fmi-picker(v-model="propertyRecord" ref="fmiNumber")
            //- v-text-field(
              v-model="propertyRecord"
              label="Número Matrícula Inmobiliaria"
              required)
          v-col(class="pt-6" cols="12" md="4")
            city-picker(v-model="selectedCity")
          v-col(cols="12" md="4")
            v-btn(
              :disabled="loading"
              :loading="loading"
              color="green"
              class="ma-2 white--text"
              @click="search")
              v-icon(left dark) mdi-magnify
              | consultar
  v-row(class="mt-2")
    v-col(cols="6")
      search-location-info

    v-col(cols="6")
      record-one-info

    v-col(cols="12")
      record-historical
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import SearchLocationInfo from '@/components/folios/SearchLocationInfo.vue';
import RecordOneInfo from '@/components/folios/RecordOneInfo.vue';
import CityPicker from '@/components/ui/CityPicker.vue';
import FmiPicker from '@/components/ui/FmiPicker.vue';
import RecordHistorical from '@/components/folios/RecordHistorical.vue';

export default {
  name: 'FolioSearch',
  data: () => ({
    npn: '',
    cities: [],
    npnOld: '',
    propertyRecord: '',
    selectedCity: null,
    loading: false,
    queryString: '',
    alertMessage: `Consulta por números prediales<br>
    <span class="text-body-1">Recuerde que se <b>almacenará</b> un registro en las consultas realizadas por cada usuario</span>`
  }),
  components: {    
    SearchLocationInfo,
    RecordOneInfo,
    CityPicker,
    RecordHistorical,
    FmiPicker,
  },
  methods: {
    ...mapActions('folios', ['folioSearch']),
    ...mapMutations('ui', ['SHOW_ALERT']),
    ...mapMutations('folios', ['CLEAR_SEARCH']),
    getCity(e) {
      return `${e.nombre} - ${e.codigo}`
    },
    async search() {
      this.CLEAR_SEARCH();
      if (this.npn === '' && this.npnOld === '' && this.propertyRecord === '') {
        this.SHOW_ALERT({
          alertMessage:'Debe ingresar por lo menos un valor para la búsqueda',
          alertType: 'error'
        });
        return;
      }
      this.loading = true;

      try {
        await this.folioSearch({
          npn: this.npn,
          npnOld: this.npnOld,
          propertyRecord: this.propertyRecord,
          cityCode: this.selectedCity ? this.selectedCity : ''
        });
      } catch (error) {
        this.SHOW_ALERT({
          alertMessage:'Ocurrio un error al cargar la información',
          alertType: 'error'
        });
      }      
      this.loading = false;
    }
  }
};
</script>