<template lang="pug">
v-data-table(
  dense
  :headers="headers"
  :items="folios"
  :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50]}"
  :items-per-page="10"
  class="elevation-1")
  template(v-slot:item.numero_matricula="{ item }")
    v-icon(
      :set="info=getStatus(item.fecha_impresion)"
      :color="info.color"
      :title="info.title") mdi-traffic-light
    span {{ item.numero_matricula }}
  template(v-slot:item.disponible="{ item }")
    b(:style="{color: item.id === '' ? 'red': 'green'}") {{ item.id === '' ? 'No disponible': 'Disponible' }} </b>
  template(v-slot:item.numero_anotaciones="{ item }")
    b(:style="{color: item.numero_anotaciones === '0' ? 'red': 'green'}") {{ item.numero_anotaciones }} </b>
  template(v-slot:item.opciones="{ item }")
    v-btn(
      v-if="item.id !== ''"
      color="gray"
      icon
      @click="targetFolio(item.id)"
      title="Ver detalle folio")
      v-icon mdi-information
    v-btn(
      v-if="item.url_archivo !== ''"
      color="gray"
      icon
      @click="showPdf(item.url_archivo)"
      title="Ver PDF del folio")
      v-icon mdi-file-pdf-box
</template>

<script>
import folioMixin from '@/mixins/folioMixin.js';

export default {
  name:'PdfListPreview',
  props: {
    folios: {
      type: Array,
      required: true
    }
  },
  mixins: [folioMixin],
  data: () => ({
      search: '',
      pagination: [10, 20, 30, 40, 50]
  }),
  computed: {    
    headers () {
      return [
        {
          text: 'Número de Matricula',
          align: 'start',
          sortable: false,
          value: 'numero_matricula',
        },
        {
          text: 'Disponible',
          value: 'disponible',
        },
        {
          text: 'Fecha Impresión',
          value: 'fecha_impresion',
        },
        { text: 'Fecha Apertura', value: 'fecha_apertura' },
        { text: 'Estado', value: 'estado' },
        { text: 'Fuente', value: 'es_vur' },
        { text: '# Anotaciones', value: 'numero_anotaciones', align: 'center'},
        { text: 'Opciones', value: 'opciones' },
      ]
    },
  },
  methods: {
    filterOnlyCapsText (value, search) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
    },
  },
}
</script>