<template lang="pug">
v-container(class="text-center" fluid)
  h1
    v-icon(color="red" size="45") mdi-file-pdf-box
    | Descarga Masiva de PDF
  massive-pdf-manager
</template>

<script>
import MassivePdfManager from '@/components/files/MassivePdfManager';

export default {
  name: 'DownloadPdf',
  components: {
    MassivePdfManager
  },
  data: () => ({
  }),  
};
</script>
    