<template lang="pug">
div(class="certificates")
  transition(name="switch" mode="out-in")
    div(v-if="certificates.length")
      transition-group(tag="ul" name="list" appear)
        certificate-item(
          v-for="certificate in certificates"
          :key="certificate.id"
          :certificate="certificate")
    div(class="d-flex flex-column" v-else)
      v-icon(color="grey" size="40") {{vurListIcon}}
      span(class="grey--text") {{vurListMessage}}
</template>

<script>
import { mapState } from 'vuex';
import CertificateItem from '@/components/vur/CertificateItem.vue'

export default {
  name: 'CertificatesList',
  data: () => ({
  }),
  components: {
    CertificateItem,
  },
  computed: {
    ...mapState('vur', ['certificates', 'vurListIcon', 'vurListMessage']),
  },
}
</script>

<style>
  .certificates {
    max-width: 600px;
    margin: 20px auto;
    position: relative;
  }
  .certificates ul {
    position: relative;
    padding: 0;
  }
  .certificates .v-list-item {
    list-style-type: none;
    display: flex;
    margin-bottom: 10px;
    padding: 10px;
    background: white;
    box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
  }
  .certificates li:hover {
    cursor: pointer;
  }

  /* list transition */
  /* enter classes */
  .list-enter-from {
    opacity: 0;
    transform: scale(0.6);
  }
  .list-enter-to {
    opacity: 1;
    transform: scale(1);
  }
  .list-enter-active {
    transition: all 0.4s ease;
  }
  /* leave classes */
  .list-leave-from {
    opacity: 1;
    transform: scale(1);
  }
  .list-leave-to {
    opacity: 0;
    transform: scale(0.6);
  }
  .list-leave-active {
    transition: all 0.4s ease;
    position: absolute;
  }
  /* clase de mover elementos existentes cuando sale o ingresa un elemento */
  .list-move {
    transition: all 0.3s ease;
  }

  /* Switch transition */
  .switch-enter-from,
  .switch-leave-to {
    opacity: 0;
    transform: translateY(20px);
  }
  /* .switch-enter-to,
  .switch-leave-from {
    opacity: 1;
    transform: translateY(0); 
  } */
  .switch-enter-active,
  .switch-leave-active {
    transition: all 0.5s ease;
  }
</style>