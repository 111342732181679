<template lang="pug">
v-btn(color="blue" class="ma-2 support-button")
  a(:href="`mailto:${supportUrl}`" class="white--text")
    v-icon(left size="25") mdi-face-agent
    | ¿Necesitas Ayuda?
</template>
  
<script>

export default {
  name: 'SupportButton',
  data() {
    return {
      supportUrl: process.env.VUE_APP_ARKANDHA_SUPPORT_URL,
    };
  },

};
</script>

<style scoped>
.support-button {
  position: absolute;
  bottom: 5px;
  right: 5px;
}
</style>
  