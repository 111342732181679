<template lang="pug">
v-card(class="truncated-folio-detail-height scrollable")
  v-toolbar(
    height="40"
    :color="folioColor"
    dark)
    v-toolbar-title Listado de salvedades
  v-card-title(class="text-h5")
    | Se encontraron un total de {{currentFolio.salvedades.length}} Salvedades
  v-list(dense)
    v-list-item-group(
      v-model="selectedWarning"
      color="primary")
      v-list-item(
        v-for="(item, name, i) in processedWarnings"
        :key="`warning-${i}`")
        v-list-item-icon
          annotation-badge(
            :show-annotation="true"
            :annotation-number="parseInt(name)")
        v-list-item-content
          v-list(dense)
            v-list-item(
              class="d-flex flex-column text-left"
              v-for="(subitem, subindex) in item"
              :key="`w-${i}-${subindex}`")
              v-list-item-title
                | {{subindex + 1}})
                | <b>Radicación:</b> {{subitem.radicacion}}
                | <b>Fecha:</b> {{subitem.fecha ? subitem.fecha : 'Sin fecha'}}
              v-list-item-content 
                span(class="text-caption font-weight-light") {{subitem.contenido}}
            v-divider
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import AnnotationBadge from '../annotations/AnnotationBadge.vue';

export default {
  name: 'FolioWarningsInfo',
  data: () => ({
    selectedWarning: null
  }),
  components: {
    AnnotationBadge,
  },
  methods: {
    ...mapMutations('folios', ['VIEW_ANNOTATION']),
  },  
  computed: {
    ...mapGetters('folios', ['folioColor']),
    ...mapState('folios', ['currentFolio']),
    processedWarnings() {
      const warnings = {};
      for (let index = 0; index < this.currentFolio.salvedades.length; index++) {
        const element = this.currentFolio.salvedades[index];
        if (!(element.numero_anotacion in warnings)) {
          warnings[element.numero_anotacion] = [element];
        } else {
          warnings[element.numero_anotacion].push(element);
        }
      }
      return warnings;
    }
  }
}
</script>
