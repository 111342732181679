import apiClient from './config.js';

export default {
  async uploadFolios(files, token) {
    const keyFiles = files.map((x) => {
      return {key: x.key, fileName: x.file.name};
    })
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      const { file } = files[i];
    //   filesArray.push(file);
      formData.append('files', file);
    }
    formData.append('keys', JSON.stringify(keyFiles));
    // formData.append('files', filesArray);
    apiClient.interceptors.request.use((config) => {
      config.headers = { ...{ 
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }, ...config.headers };
      return config;
    });
    return await apiClient.post('files/uploadfiles/', formData);
  },
  /**
   * Servicio para consultar el estado de los archivos PDF en el sistema
   *
   * @param {file} File el archivo excel con el listado
   * @param {token} string token de usuario generado por la aplicacion
   * @returns {HttpResponse} No retorna nada
   * @example
   * uploadPdfList(File(), 'dhlfgdnfudygufymguyfgf')
   * @author Luis Merizalde <luis.merizalde@arcerojas.com>
   */
  async uploadPdfList(file, token) {
    const formData = new FormData();
    formData.append('file', file);
    apiClient.interceptors.request.use((config) => {
      config.headers = { ...{ 
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }, ...config.headers };
      return config;
    });
    return await apiClient.post('files/search_pdf_list', formData);
  },
  /**
   * Servicio para consultar el estado de los archivos PDF en el sistema
   *
   * @param {pdfList} Array El listado de matriculas inmobiliarias
   * @param {token} string token de usuario generado por la aplicacion
   * @returns {HttpResponse} No retorna nada
   * @example
   * downloadPdfs(File(), 'dhlfgdnfudygufymguyfgf')
   * @author Luis Merizalde <luis.merizalde@arcerojas.com>
   */
  async downloadPdfs(pdfList, token) {
    console.log(pdfList);
    // const data = {
    //   pdfs: JSON.stringify(pdfList),
    // };
    apiClient.interceptors.request.use((config) => {
      config.headers = { ...{ 
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }, ...config.headers };
      return config;
    });
    return await apiClient.post('files/download_pdfs', JSON.stringify(pdfList));
  },
};