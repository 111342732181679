import AuthService from '@/services/AuthService.js';
import router from '@/router'

const auth = {
  namespaced: true,
  state: {
    status: '',
    email: localStorage.getItem('email') || '',
    token: localStorage.getItem('token') || '',
    permissions: localStorage.getItem('permissions') || [],
    errorMessage: '',
  },
  mutations: {
    TOGGLE_LOADING(state) {
      state.isLoading = !state.isLoading;
    },
    AUTH_SUCCESS(state, { email, token, permissions, userName }) {
      state.status = 'success';
      state.email = email;
      state.token = token;
      state.permissions = permissions;
      localStorage.setItem('email', email);
      localStorage.setItem('token', token);
      localStorage.setItem('permissions', permissions);
      localStorage.setItem('userName', userName);
    },
    LOGOUT(state) {
      state.email = '';
      state.token = '';
      localStorage.removeItem('email');
      localStorage.removeItem('token');
      localStorage.removeItem('permissions');
      localStorage.removeItem('userName');
      router.push({name: 'Login'});
    },
    ADD_PERMISSION(state, permission) {
      if (!state.permissions.includes(permission)) {
        state.permissions.push(permission);
        localStorage.setItem('permissions', state.permissions);
      }
    },
  },
  actions: {
    toggleLoading({ commit }) {
      commit('TOGGLE_LOADING');
    },
    // Acción para autenticar el usuario en el portal
    login({ commit }, { email, password }) {
      return AuthService.login(email, password)
        .then((resp) => {
          console.log('entro SUCCESS LOGIN', resp);
          if (resp.data) {
            const token = resp.data.access_token;
            const permissions = resp.data.permissions;
            const userName = resp.data.user_name;
            // axios.defaults.headers.common.Authorization = token;
            commit('AUTH_SUCCESS', { email, token, permissions, userName });
            // return 'success';
          }
          return 'success';
        })
        .catch((err) => {
          console.log(err);
          return `Ocurrio un error: ${err.response.status} - ${err.response.data.detail}`;
        });
    },
    // Acción para el cambio de contraseña del usuario
    // eslint-disable-next-line
    changePassword({}, { password, newPassword }) {
      console.log('entro changePassword', password, newPassword);
      return AuthService.changePassword(password, newPassword)
        .then((resp) => {
          console.log('entro SUCCESS CHANGE PASSWORD', resp);
          // if (resp.data) {
          //   const token = resp.data.access_token;
          // }
          return 'success';
        })
        .catch((err) => {
          console.log(err);
          return `Ocurrio un error: ${err.response.status} - ${err.response.data.detail}`;
        });
    },
    // Acción para obtner la cookie csrftoken
    userLogout({ commit, rootState }) {
        commit('LOGOUT');
        // limpiar certificados al salir
        rootState.vur.selectedCertificate = null;
        rootState.vur.certificates = [];
        // limpiar los pdf al salir
        rootState.files.fileList = [];
    },    
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    hasPermission: (state) => (permissionName) => {
      return state.permissions.includes(permissionName)},
  }
};

export default auth;
