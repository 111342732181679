<template lang="pug">
v-row(class="px-2" v-if="certificates.length")
  v-col(
    cols="3"
    :key="certificate.id"
    v-for="certificate in certificates")
    certificate-item(
      class="certificate-item"
      :massive="true"      
      :key="certificate.id"
      :certificate="certificate")
div(class="d-flex flex-column" v-else)
  v-icon(color="grey" size="40") mdi-file-document-remove
  span(class="grey--text") Aún no hay certificados en la lista.
</template>

<script>
import { mapState } from 'vuex';
import CertificateItem from '@/components/vur/CertificateItem.vue'

export default {
  name: 'VurMassiveList',
  data: () => ({
  }),
  components: {
    CertificateItem,
  },
  computed: {
    ...mapState('vur', ['certificates']),
  },
}
</script>
