import { render, staticRenderFns } from "./FolioMainInfo.vue?vue&type=template&id=3792dbd1&scoped=true&lang=pug"
import script from "./FolioMainInfo.vue?vue&type=script&lang=js"
export * from "./FolioMainInfo.vue?vue&type=script&lang=js"
import style0 from "./FolioMainInfo.vue?vue&type=style&index=0&id=3792dbd1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3792dbd1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VCardSubtitle,VCardText,VCardTitle,VChip,VDivider,VRow,VSpacer,VToolbar,VToolbarTitle})
