<template lang="pug">
v-container(class="text-center" fluid)
  h1 Generacion masiva de API (Análisis Predial Integral)
  api-manager
</template>

<script>
import ApiManager from '@/components/ant/ApiManager';

export default {
  name: 'APIReport',
  components: {
    ApiManager
  },
  data: () => ({
  }),  
};
</script>
  