<template lang="pug">
div
  //- Enlace para descargar el ZIP
  a(ref="downloadLink" hidden :href="downloadUrl" target="_blank")
  v-btn(
    v-if="zipPath === ''"
    :loading="loadingDownload"
    @click="sendPdfs"
    :disabled="folios.length === 0"
    class="blue white--text"
  ) Descargar PDF - (Solo existentes <b class="ml-2" style="font-size:22px">{{ folios.length }}</b>)
  v-btn(
      v-else
      color="orange"
      class="ma-2 white--text"
      @click="download")
      v-icon(left dark) mdi-zip-box
      | Descargar Archivo
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { staticUrl } from '@/env';

export default {
  name: 'ButtonDownloadPdf',
  props: {
    // El listado de numeros de matricula cuyos pdfs van a ser descargados
    folios: {
      type: Array,
      required: true,
    }
  },
  data: () => ({
  }),
  computed: {
    ...mapState('files', ['zipPath', 'loadingDownload']),
    // avalaibleFolios() {
    //   return this.folios.filter((x) => x.id !== '').length;
    // },
    downloadUrl() {
      return `${staticUrl}${this.zipPath}`
    }
  },
  methods: {
    ...mapActions('files', ['downloadPdfs']),
    async sendPdfs() {
      await this.downloadPdfs(this.folios);
    },
    download() {
      this.$refs.downloadLink.click();
    }
  }
};
</script>
    