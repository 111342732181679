<template lang="pug">
div(
  v-if="isMounted"
  class="truncated-folio-detail-height scrollable"
  ref="wrapperAnnotationDetail")
  v-list-item(two-line)
    v-list-item-content
      v-list-item-title(class="text-h5")
        | Anotación
        annotation-badge(
          class="ml-2"
          :annotation-number="annotation.numero"
          :annotation="annotation"
          :number-cancellation="annotation.cancelacion ? annotation.cancelacion.numero  : 0")
      v-list-item-subtitle {{annotation.fecha}}  

  div(v-if="annotation.errors")
    v-subheader(v-if="annotation.errors.length > 0" class="text-h6 red--text font-weight-bold") ¡Errores identificados!
    v-row(class="mx-2")
      v-col(v-for="(error, index) in annotation.errors" :key="error")
        div(class="text-body font-weight-light")
          v-chip(class="ma-2" small color="yellow") <b>{{ index + 1 }}</b>
          | {{ error }}
         
    v-divider(v-if="annotation.errors.length > 0")

  v-subheader Información Documento
  v-row(class="mx-2")
    v-col 
      div(class="text-caption font-weight-light") Tipo documento 
      div(class="text-body-1") {{annotation.documento}}  
      div(class="text-caption font-weight-light") Lugar resolución
      div(class="text-body-1") {{annotation.datos_documento}}  
    v-col 
      div(class="text-caption font-weight-light") Fecha documento 
      div(class="text-body-1") {{annotation.fecha_doc_anotacion}} 
      div(class="text-caption font-weight-light") Valor
      div(class="text-body-1") $ {{currencyFormat}} 
  v-divider
  v-subheader Información Especificación
  v-row(class="mx-2")
    v-col 
      div(class="text-caption font-weight-light") Especificación 
      div(class="text-body-1") {{annotation.especificacion}}
    //- v-col
      v-chip(
        class="ma-1"
        :color="annotationColor('danger')"
        small
        text-color="white")
        v-icon(small left) mdi-label
        | Anotacion riesgosa

  v-divider
  v-subheader Personas que intervienen
  v-row(class="mx-2 my-0")
    people-list(:people="fromPeople" title="Aceptante")
    people-list(:people="toPeople" title="Destinatario")
</template>

<script>
import { mapState } from 'vuex';
import PeopleList from '@/components/people/PeopleList.vue';
import AnnotationBadge from '../annotations/AnnotationBadge.vue';

export default {
  name: 'AnnotationDetail',
  components: {
    PeopleList,
    AnnotationBadge,
  },
  props: {
    annotation: {
      type: Object,
      required: true
    }
  },
  methods: {
    annotationColor(type) {
      let color = 'gray';
      switch (type) {
        case 'danger':
          color = 'red';
          break;      
        default:
          break;
      }
      return color;
    },
  },
  computed: {
    ...mapState('folios', ['viewedAnnotation']),
    currencyFormat() {
      if (this.annotation.valor_acto === -1) {
        return 'Sin valor';
      }
      let pesosCOLocale = Intl.NumberFormat('es-CO');
      return pesosCOLocale.format(this.annotation.valor_acto);
    },
    toPeople() {
      return this.annotation.interesados.filter((x) => x.tipo === 'Para')
    },
    fromPeople() {
      return this.annotation.interesados.filter((x) => x.tipo === 'Desde')
    },
    annotationNumber() {
      if (!this.annotation.numero) return;
      return String(this.annotation.numero).padStart(3, '0');
    }
  },
  mounted() {    
    this.isMounted = true;
  },
  updated() {
    const el = this.$refs.wrapperAnnotationDetail;
    el.scrollTop = 0;
  },
  data: () => ({
    isMounted: false,
  })
}
</script>