var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.hasPermission('can_add_folio'))?_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"info"},on:{"click":function($event){return _vm.$router.push({name: 'FoliosUpload'})}}},[_vm._v("Cargar Folios"),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-cloud-upload")])],1):_vm._e(),_c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v("CONVENCIONES")]),_c('list-convention-icon',{staticClass:"ml-4",attrs:{"conventions":_vm.conventions}})],1),_c('v-card',{staticClass:"mt-2"},[_c('v-card-title',[_c('v-text-field',{ref:"inputSearch",attrs:{"append-icon":_vm.loadingFolios ? 'mdi-timer-sand-complete' : 'mdi-magnify',"label":"Buscar folios","single-line":"","disabled":_vm.loadingFolios,"hide-details":""},on:{"input":_vm.searchEvent},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loadingFolios,"loading-text":"Cargando folios... Por favor espere","hide-default-footer":"","page":_vm.page,"disable-pagination":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(_vm.items.map(function(x) {return x.id; }).indexOf(item.id) + (_vm.pageSize * (_vm.page - 1) + 1)))])]}},{key:"item.fecha_impresion",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.fecha_impresion.slice(0, 10)))]}},{key:"item.numero_matricula",fn:function(ref){
var item = ref.item;
return [(item.errors)?_c('span',[_c('v-icon',{staticClass:"mr-2 hithere",attrs:{"title":"Folio con errores registrales","color":"red accent-4"}},[_vm._v("mdi-alert")])],1):_vm._e(),(item.transfer_info)?_c('span',[(item.transfer_info.length > 0)?_c('v-icon',{staticClass:"mr-2",attrs:{"title":_vm.getTranferInfo(item.transfer_info),"color":"indigo"}},[_vm._v("mdi-file-swap")]):_vm._e()],1):_vm._e(),(item.es_vur === true)?_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Datos provenientes VUR","color":"red darken-2"}},[_vm._v("mdi-database-search")]):_vm._e(),(item.geom === true)?_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Predio georreferenciado","color":"orange"}},[_vm._v("mdi-map-marker-radius")]):_vm._e(),_c('v-icon',{attrs:{"set":_vm.info=_vm.getStatus(item.fecha_impresion),"color":_vm.info.color,"title":_vm.info.title}},[_vm._v("mdi-traffic-light")]),_c('a',{on:{"click":function($event){return _vm.detailFolio(item.id)}}},[_vm._v(_vm._s(item.numero_matricula))])]}},{key:"item.fecha_carga",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.fecha_carga.slice(0, 10)))]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"gray","icon":"","title":"Ver resumen diagnóstico"},on:{"click":function($event){return _vm.showFolio(item.id)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-btn',{attrs:{"color":"gray","icon":"","title":"Ver análisis jurídico"},on:{"click":function($event){return _vm.analysisFolio(item.id)}}},[_c('v-icon',[_vm._v("mdi-scale-balance")])],1),_c('v-btn',{attrs:{"color":"gray","icon":"","title":"Ver detalle folio"},on:{"click":function($event){return _vm.detailFolio(item.id)}}},[_c('v-icon',[_vm._v("mdi-information")])],1),_c('v-btn',{attrs:{"color":"gray","icon":"","title":"Ver PDf del folio"},on:{"click":function($event){return _vm.showPdf(item.url_archivo)}}},[_c('v-icon',[_vm._v("mdi-file-pdf-box")])],1)]}}])}),_c('v-divider'),_c('v-row',{staticClass:"mx-5 mt-2"},[_c('v-col',{attrs:{"cols":"4","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.pageSizes,"label":"Items per Page"},on:{"change":_vm.handlePageSizeChange},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":"7","next-icon":"mdi-menu-right","prev-icon":"mdi-menu-left"},on:{"input":_vm.handlePageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }