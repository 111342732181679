<template lang="pug">
v-card
  v-toolbar(
    height="40"
    :color="folioColor"
    dark)
    v-toolbar-title Información general de los <b>Propietarios</b>
  //- div(class="truncated-folio-detail-height scrollable")
  v-card-title(class="text-h5")
    v-row
      v-col
        span Propietario(s) Actual(es)
      v-spacer
      v-col(cols="4")
        v-alert(
          dense
          border="left"
          colored-border
          type="warning"
          elevation="2")
          | La información presentada está sujeta a revisión de un profesional jurídico por cuanto puede contener imprecisiones propias de la calificación de la Oficina de Registro.
      v-spacer
      v-col
        v-btn(
          color="info"
          class="ma-2 white--text"
          @click="showTimeline")
          | Ver línea de tiempo
          v-icon(
            right
            dark) mdi-sort-clock-descending-outline
  v-divider
  div(class="d-flex mb-2 text-center")
    v-row
      v-col
        v-card-title(class="text-h5") Último propietario
    v-row(v-if="lastOwner")
      v-col
        people-list(title="Listado" :people="lastOwner.interesados.filter((x) => x.titularidad === 'Real')")
      v-col
        | Ver anotación relacionada: 
        annotation-badge(
          :show-annotation="true"
          :annotation-number="parseInt(lastOwner.numero)")
    div(v-else class="text-center")
      v-card-subtitle(class="my-auto")
        v-icon(size="35") mdi-account-multiple-remove
      v-card-text(class="text--primary my-auto")
        div No existe información de los propietarios actuales

  v-divider(class="mb-2")

  v-card-title(class="text-h5")
    | Histórico de propietarios
  v-card-title(class="text-h6") Naturaleza Jurídica
    div(class="ml-2 legend tradicion") Tradición
    div(class="ml-2 legend limitaciones") Limitaciones y afectaciones
    div(class="ml-2 legend falsa-tradicion") Falsa tradición
    div(class="ml-2 legend otros") Otros
  div(class="d-flex pb-2")
    owner-graph(:labels="graphLabels" :graph-data="graphData" :graph-colors="graphColors")
  
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import PeopleList from '@/components/people/PeopleList';
import OwnerGraph from '@/components/people/OwnerGraph';
import AnnotationBadge from '@/components/annotations/AnnotationBadge.vue';

export default {
  name: 'FolioOwnerAnalysis',
  data() {
    return {}
  },
  computed: {
    ...mapGetters('folios', ['folioColor']),
    ...mapState('folios', ['currentFolio']),
    ...mapGetters('annotations', ['getPropertyOwners', 'getTimeline', 'getAnnotationByDocument']),
    lastOwner() {
      let lastAnnotation = null;
      if (this.getPropertyOwners.length > 0) {
        lastAnnotation =  this.getPropertyOwners[this.getPropertyOwners.length - 1];
      }
      return lastAnnotation;
    },
    graphLabels() {
      return this.getTimeline.map((x) => x.nombre);
    },
    graphData() {
      return this.getTimeline.map((x) => [x.initDate, x.finishDate]);
    },
    graphColors() {
      return this.getTimeline.map((x) => x.color);
    }
  },
  components: {
    PeopleList,
    AnnotationBadge,
    OwnerGraph
  },
  mounted() {
    console.log(this.getPropertyOwners);
    console.log(this.getTimeline);
  },
  methods: {
    ...mapMutations('ui', ['TOGGLE_SIDE_DIALOG', 'SET_SIDE_DIALOG_CONTENT', 'SET_SIDE_DIALOG_TITLE', 'SET_SIDE_DIALOG_WIDTH']),
    showTimeline() {
      this.SET_SIDE_DIALOG_CONTENT('AnnotationTimeline');
      this.SET_SIDE_DIALOG_WIDTH('30vw');
      this.SET_SIDE_DIALOG_TITLE('Anotaciones (ordenadas por fecha documento)');
      this.TOGGLE_SIDE_DIALOG(true);
    }
  }
}
</script>

<style scoped>
.legend {
  color: white;
  padding: 0px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 12px;
  font-size: 20px;
}
.tradicion {
  background: #8BC34ABF;
  border: #8BC34A 2px solid;
}
.limitaciones {
  background: #FF9800BF;
  border: #FF9800 2px solid;
}
.falsa-tradicion {
  background: #C2185BBF;
  border: #C2185B 2px solid;
}
.otros {
  background: #78909CBF;
  border: #78909C 2px solid;
}

</style>
