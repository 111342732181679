var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.folios,"footer-props":{'items-per-page-options': [10, 20, 30, 40, 50]},"items-per-page":10},scopedSlots:_vm._u([{key:"item.numero_matricula",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"set":_vm.info=_vm.getStatus(item.fecha_impresion),"color":_vm.info.color,"title":_vm.info.title}},[_vm._v("mdi-traffic-light")]),_c('span',[_vm._v(_vm._s(item.numero_matricula))])]}},{key:"item.disponible",fn:function(ref){
var item = ref.item;
return [_c('b',{style:({color: item.id === '' ? 'red': 'green'})},[_vm._v(_vm._s(item.id === '' ? 'No disponible': 'Disponible')+" ")])]}},{key:"item.numero_anotaciones",fn:function(ref){
var item = ref.item;
return [_c('b',{style:({color: item.numero_anotaciones === '0' ? 'red': 'green'})},[_vm._v(_vm._s(item.numero_anotaciones)+" ")])]}},{key:"item.opciones",fn:function(ref){
var item = ref.item;
return [(item.id !== '')?_c('v-btn',{attrs:{"color":"gray","icon":"","title":"Ver detalle folio"},on:{"click":function($event){return _vm.targetFolio(item.id)}}},[_c('v-icon',[_vm._v("mdi-information")])],1):_vm._e(),(item.url_archivo !== '')?_c('v-btn',{attrs:{"color":"gray","icon":"","title":"Ver PDF del folio"},on:{"click":function($event){return _vm.showPdf(item.url_archivo)}}},[_c('v-icon',[_vm._v("mdi-file-pdf-box")])],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }