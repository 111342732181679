const MAP_EPHEMERAL = {
  nuevo: {
    color: '#F44336',
    animation: 'pulse'
  },
  pronto: {
    color: '#2196F3',
    animation: 'gelatine'
  },
  renovando: {
    color: '#FFC107',
    animation: 'gelatine'
  }
};
function createBadge(el, text, date) {
  const today = new Date();
  const ephemeralDate = new Date(date);
  // si la fecha de expiración es menor no mostrar el icono
  if (ephemeralDate < today) {
    return;
  }
  let directiveData = {
    color: '#9E9E9E',
    animation: 'pulse'
  };
  if (text in MAP_EPHEMERAL) {
    directiveData = MAP_EPHEMERAL[text];
  }
  const span = document.createElement('span');
  span.innerHTML = text;
  span.classList.add('ephemeral-directive');
  span.classList.add(directiveData.animation);
  span.style.background = directiveData.color;
  el.appendChild(span);
}

const ephemeralDir = {
  bind: (el, binding) => {
    createBadge(el, binding.arg, binding.value);
  },
  update(el, binding, vnode) {
    console.log('entro update direc tive');
    console.log(el, binding, vnode);
    createBadge(el, binding.arg, binding.value);
  },
};
export default ephemeralDir;
