<template lang="pug">
div(id="imap1" class="map")
</template>

<script>

import leaflet from 'leaflet'

export default {
  name: 'TestView',
  components: {
    leaflet
  },
  data() {
    return {
      professionnel: {},
      map1: null,
      tileLayer: null,
    }
  },
  async mounted() {
    await this.initMap();
  },
  methods: {
    initMap() {
      // eslint-disable-next-line
      this.map1 = L.map('imap1').setView([47.413220, -1.219482], 12);
      // eslint-disable-next-line
      this.tileLayer = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      {
          maxZoom: 18,
          zoomOffset: 1,
          center: [47.413220, -1.219482],
          attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
      }).addTo(this.map1);

      this.map1.invalidateSize();
    }
  },
}
</script>