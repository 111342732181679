<template lang="pug">
v-list-item
  v-icon(v-if="!certificate.processed"
    :style="{background: !certificate.valid ? 'black' : '', borderRadius: !certificate.valid ? '25px' : ''}"
    size=40
    :color="certificate.valid ? 'green' : 'yellow'"
    v-text="certificate.valid ? 'mdi-check-circle' : 'mdi-alert-circle'")
  div(v-else)
    progres-slider(:file="certificate" :massive="massive")

  v-list-item-content(v-if="!massive")
    v-list-item-title {{ certificate.circle }} | {{ certificate.certificate }}
    v-list-item-subtitle {{ certificate.identification }}
    v-list-item-subtitle(v-if="certificate.sources.length > 0")
      div(class="font-weight-medium" :style="{color:getColor(certificate.sources)}") Encontrado por: {{ certificate.sources.join(', ') }}
  v-list-item-content(v-else)
    v-list-item-title {{ certificate.certificate }}

  v-list-item-action(class="d-flex flex-row")
    v-btn(
      v-if="certificate.folioId && !certificate.processed"
      @click="targetFolio(certificate.folioId)"
      icon)
      v-icon(color="green" title="Ver registro en el sistema") mdi-information-outline
    v-btn(
      v-if="certificate.polygon"
      @click="SELECT_CERTIFICATE(certificate)"
      icon)
      v-icon(color="blue" title="Ver en el mapa") mdi-earth
    v-btn(icon)
      v-icon(
        color="red"
        title="Quitar este certificado"
        @click="DELETE_CERTIFICATE(certificate.id)") mdi-delete-circle
</template>

<script>
import { mapMutations } from 'vuex';
import folioMixin from '@/mixins/folioMixin.js';
import ProgresSlider from '@/components/ProgresSlider.vue';

export default {
  name: 'CertificatesItem',
  mixins: [folioMixin],
  props: {
    certificate: {
      type: Object,
      required: true
    },
    massive: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
  }),
  components: {
    ProgresSlider
  },
  methods: {
    ...mapMutations('vur', ['DELETE_CERTIFICATE', 'SELECT_CERTIFICATE']),
    getColor(items) {
      let color = '#757575';
      if (items.length === 2) {
        if (items[0] === items[1]) {
          color = '#F44336';
        } else {
          color = '#4CAF50';
        }
      }
      return color;
    }
  }
}
</script>