<template lang="pug">
v-list(dense class="truncated-folio-detail-height scrollable")
  v-text-field(
        v-model="query"
        :hint="hintMessage"
        small
        label="Ingrese tres o más caracteres")
  v-list-item-group(
    v-model="selectedAnnotation"
    color="primary")
    v-list-item(
      @click="selectAnnotation(item)"
      v-for="(item, i) in filteredAnnotations"
      :key="i")
      div(v-if="item.errors")
        v-icon(v-if="item.errors.length > 0" color="red" size="25" title="Esta anotación presenta errores") mdi-shield-alert
      annotation-badge(
        :annotation-number="item.numero"
        :number-cancellation="item.cancelacion ? item.cancelacion.numero  : 0")
      v-list-item-content
        v-list-item-title {{item.especificacion ? item.especificacion : 'ANOTACION SIN VALIDEZ'}}
      annotation-badge(
        v-if="item.cancelacion"
        :show-annotation="true"
        :annotation-number="item.cancelacion.numero")
</template>

<script>
import AnnotationBadge from '../annotations/AnnotationBadge.vue';

export default {
  name: 'AnnotationList',
  props: {
    annotations: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    query: '',
    filteredAnnotations: [],
    hintMessage: '',
  }),
  components: {
    AnnotationBadge,    
  },
  watch: {
    query(newValue) {
      if (newValue != '') {
        this.filteredAnnotations = this.annotations.filter((x) => 
          x.datos_documento.toLowerCase().includes(newValue.toLowerCase()) ||
          x.documento.toLowerCase().includes(newValue.toLowerCase()) ||
          x.especificacion.toLowerCase().includes(newValue.toLowerCase()) ||
          x.interesados.some(i => i.nombre.toLowerCase().includes(newValue.toLowerCase()) || i.numero_identificacion.toLowerCase().includes(newValue.toLowerCase())) ||
          x.codigo_especificacion.toLowerCase().includes(newValue.toLowerCase()));
        if (this.filteredAnnotations.length > 0) {
          this.hintMessage = `Se encontraron ${this.filteredAnnotations.length} resultado(s)`;
        } else {
          this.hintMessage = 'Sin resultados';
        }
        
      } else {
        this.filteredAnnotations = this.annotations;
        this.hintMessage = '';
      }
    },
    '$store.state.annotations.currentAnnotations': {
      deep: true,
      handler(newVal) {
        console.log(newVal);
        this.filteredAnnotations = this.annotations;
      }
    }
  },  
  computed: {
    selectedAnnotation: {
      get() {
        return this.$store.state.folios.selectedAnnotation;
      },
      set(value) {
        return this.$store.commit('folios/SELECT_ANNOTATION', value);
      }
    },
  },
  methods: {
    selectAnnotation(annotation) {
      this.$bus.$emit('select-annotation', {
        annotation
      });
    },
  },
  mounted() {
    this.filteredAnnotations = this.annotations;
  }
}
</script>
