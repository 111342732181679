import axios from 'axios';
import router from '@/router';
import { apiUrl } from '@/env.js';
// Vuex
import store from '../store';

export function authHeaders(token) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

const apiClient = axios.create({
  baseURL: `${apiUrl}/`,
  withCredentials: true,
  headers: {
    // Accept: 'application/json',
  },
});

apiClient.interceptors.response.use(
  
  response => {
    return Promise.resolve(response);
  }, error => {
    let path = '/not-found';
    switch (error.response.status) {
      case 401:
        store.commit('auth/LOGOUT');
        path = '/login';
        break;
      case 403:
        store.commit('auth/LOGOUT');
        path = '/login';
        break;
      // case 404:
      //   path = '/not-found';
      //   break;
      default:
        path = '';
        // return Promise.resolve(error.response);
    }
    if (path !== '') {
      router.push(path);
    }
    return Promise.reject(error);
  }
);

export default apiClient;