<template lang="pug">
v-card
  v-toolbar(
    height="40"
    :color="folioColor"
    dark)
    v-toolbar-title Localización del predio según autoridad catastral
  property-visor(:coordinates="currentFolio.geom ? currentFolio.geom.coordinates[0] : []")
  v-divider
  div(class="d-flex")
    v-card-subtitle(class="my-auto")
      div Departamento
    v-card-text(class="text--primary text-body-1 font-weight-bold my-auto")
      div(class="pt-4") {{currentFolio.departamento}}

    v-card-subtitle(class="my-auto")
      div Municipio
    v-card-text(class="text--primary text-body-1 font-weight-bold my-auto")
      div(class="pt-4") {{currentFolio.municipio_txt}}

    v-card-subtitle(class="my-auto")
      div Vereda
    v-card-text(class="text--primary text-body-1 font-weight-bold my-auto")
      div(class="pt-4") {{currentFolio.vereda}}
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import PropertyVisor from '@/components/PropertyVisor';

export default {
  name: 'FolioLocationInfo',
  components: {
    PropertyVisor
  },
  computed: {
    ...mapState('folios', ['currentFolio']),
    ...mapGetters('folios', ['folioColor']),
  }
}
</script>
