<template lang="pug">
v-expansion-panels(class="truncated-folio-detail-height scrollable" popout)
  v-row 
    v-col(class="mt-4 pl-8" cols="8") Ver el comportamiento de las anotaciones
    v-col(cols="4")
      v-switch(
        v-model="active"
        color="red"
        inset
        :label="active ? 'Vigentes' : 'Historicas'")
  v-expansion-panel(
    v-show="active === true"
    v-for="(item,i) in analysableCategories"
    :set="currentAnnotations = getAnnotations(item)"
    :key="`l1_${item.name}`")
    v-expansion-panel-header(class="white--text" :color="item.color")
      |{{item.name}} {{active ? 'Vigentes' : 'Historicas'}} <b>({{currentAnnotations.length}})</b>
    v-expansion-panel-content
      annotation-list(:annotations="currentAnnotations")
  v-expansion-panel(
    v-show="active === false"
    v-for="(item,i) in analysableCategories"
    :set="currentAnnotations = getAnnotations(item)"
    :key="`l2_${item.name}`")
    v-expansion-panel-header(class="white--text" :color="item.color")
      |{{item.name}} {{active ? 'Vigentes' : 'Historicas'}} <b>({{currentAnnotations.length}})</b>
    v-expansion-panel-content
      annotation-list(:annotations="currentAnnotations")
      
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import AnnotationBadge from '../annotations/AnnotationBadge.vue';
import AnnotationList from '@/components/annotations/AnnotationList';

export default {
  name: 'AnnotationAnalysis',
  props: {
    annotations: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    active: true,
  }),
  
  computed: {
    ...mapState('annotations', ['annotationCategories']),
    ...mapGetters('annotations', ['analysableCategories'])
  },
  components: {
    AnnotationBadge,
    AnnotationList,
  },
  methods: {
    getAnnotations(category) {
      let annotations = this.annotations.filter((x) => x.especificacion);
      annotations = annotations.filter((x) => 
        x.codigo_especificacion.replaceAll('0', '')[0] === category.startChar);
      if (this.active === true) {
        annotations = annotations.filter((x) => !x.cancelacion);
      } else {
        annotations = annotations.filter((x) => x.cancelacion);
      }
      return annotations
    }
  }
};
</script>
