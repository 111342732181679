<template lang="pug">
v-container(class="text-center" fluid)
  div(v-if="hasPermission('can_change_vur')")
    h1 Introduzca las credenciales que utiliza en el VUR
    vur-credentials-form
    v-btn(class="white--text" color="blue" @click="$router.push('/vur')")
      | Consultar certificados
      v-icon(right dark) mdi-arrow-right-bold
  v-card(class="mx-auto" v-else style="width: 50%;")
    v-icon(color="red" size="60") mdi-cancel
    v-card-text(class="pb-0")
      p(class="text-h4 text--primary") Prohibido
      p(class="text-overline") Lo sentimos no puede ver o configurar las credenciales VUR, comuniquese con el administrador del sitio para obtener este permiso.
    v-card-actions(class="pt-0")
      support-button
      v-btn(class="white--text" color="blue" @click="$router.push('/folios')") 
        v-icon(left size="25") mdi-home
        | Regresar al inicio
</template>

<script>
import {mapGetters} from 'vuex';
import VurCredentialsForm from '@/components/forms/VurCredentialsForm.vue';
import SupportButton from '@/components/ui/SupportButton';

export default {
  name: 'VurCredentials',
  components: {
    SupportButton,
    VurCredentialsForm
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters('auth', ['hasPermission']),
  }
};
</script>
  