// servicio que consume api reportes
import ReportService from '@/services/ReportService.js';

const reports = {
  namespaced: true,

  state: {
    // etado que indica el archivo excel que se genero
    excelFile: null,
    // estado para almacenar los variables disponibles para el analisis del folio
    folioVariables: [],
    // estado para indicar que el reporte esta cargando
    loadingReport: false,
    // estado para almacenar las variables del reporte en el componente
    managerVariables: [],
    // estado para almacenar los datos generados por el reporte
    reportData: null,
    // estado para almacenar las plantilas de repoprtes masivo folios
    reportTemplates: [],
    // estado para almacenar el reporte seleccionado
    selectedReport: null,
  },

  mutations: {
    SET_INITIAL_VARIABLES(state, variables) {
      /**
       * Mutación para incializar las variables del manegador del reporte
       *
       * @param {Object} state estados del modulo del store
       * @param {Array} variables listado de variable traidas del servidor
       * @returns {} No retorna nada
       * @example
       * $store.commit('SET_INITIAL_VARIABLES', [
       *  {
       *    desc: null,
       *    id: "3961ac16-bb16-452b-9377-ae4da9dcb3f2",
       *    key: "codigo_catastral",
       *    name: "Referencia Catastral",
       *    options: null,
       *    section: "Main"
       *  }
       * ])
       * @author Luis Merizalde <luis.merizalde@arcerojas.com>
       */
      const managerVariables = [];
      for (let index = 0; index < variables.length; index++) {
        const variable = variables[index];
        managerVariables.push({
          name: variable.name,
          desc: variable.desc,
          options: variable.options,
          checked: false,
          section: variable.section,
          key: variable.key,
          selectedOption: null
        });
      }
      state.managerVariables = managerVariables;
    },
    UPDATE_MANAGER_VARIABLE(state, { variableKey, varAttr, varValue }) {
      /**
       * Mutación para cambiar el estado de una variable
       *
       * @param {Object} state estados del modulo del store
       * @param {String} variableKey llave de la variable
       * @returns {} No retorna nada
       * @example
       * $store.commit('UPDATE_MANAGER_VARIABLE', 'variable_key')
       * @author Luis Merizalde <luis.merizalde@arcerojas.com>
       */
      const variable = state.managerVariables.filter((x) => x.key === variableKey)[0];
      // variable.checked = !variable.checked;
      variable[varAttr] = varValue;
    },
    // mutacion para establecer los valores de cualquier variable
    CHANGE_VALUE(state, {value, varName}) {
      state[varName] = value;
    },
  },

  actions: {
    async getFolioVariables({ commit, state}) {
      /**
       * Acción obtener las variables disponibles para generar un reporte masivo
       * de folios
       *
       * @param {Object} context contexto del estado del store
       * @returns {} No retorna nada
       * @example
       * $store.dispatch('getFolioVariables', null)
       * @author Luis Merizalde <luis.merizalde@arcerojas.com>
       */
      try {
        const token = localStorage.getItem('token');
        const response = await ReportService.getFolioVariables(token);
        if (response) {
          state.folioVariables = response.data;
          commit('SET_INITIAL_VARIABLES', state.folioVariables);
        }
      } catch (error) {
          console.log(error);
          commit('ui/SHOW_ALERT',{
            alertMessage: `Ocurrio un error al traer las variables de los folios
              (${error.response.status} - ${error.response.data.detail})`,
            alertType: 'error'
          }, {root: true});
      }
    },
    async getFolioReports({ commit, state}) {
      /**
       * Acción obtener las variables disponibles para generar un reporte masivo
       * de folios
       *
       * @param {Object} context contexto del estado del store
       * @returns {} No retorna nada
       * @example
       * $store.dispatch('getFolioReports', null)
       * @author Luis Merizalde <luis.merizalde@arcerojas.com>
       */
      try {
        const token = localStorage.getItem('token');
        const response = await ReportService.getFolioReports(token);
        if (response) {
          state.reportTemplates = response.data;
        }
      } catch (error) {
          console.log(error);
          commit('ui/SHOW_ALERT',{
            alertMessage: `Ocurrio un error al traer las plantilla de los folios
              (${error.response.status} - ${error.response.data.detail})`,
            alertType: 'error'
          }, {root: true});
      }
    },
    updateManagerVariables({state, commit}, reportName) {
      /**
       * Accion para actualizar el esatdo de todas las variable segun el reporte
       *
       * @param {Object} context contexto del estado del store
       * @param {String} reportName Nombre del reporte seleccionado
       * @returns {} No retorna nada
       * @example
       * $store.dispatch('updateManagerVariables', 'report_name')
       * @author Luis Merizalde <luis.merizalde@arcerojas.com>
       */
      if (reportName === null || reportName === undefined) {
        commit('SET_INITIAL_VARIABLES', state.managerVariables);
        return;
      }
      state.selectedReport = reportName;
      const report = state.reportTemplates.filter((x) => x.name === reportName)[0];
      const reportVariables = report.fields.map((x) => x.variable);
      for (let index = 0; index < state.managerVariables.length; index++) {
        const variable = state.managerVariables[index];
        if (reportVariables.includes(variable.key)) {
          const reportVar = report.fields.filter((x) => x.variable === variable.key)[0]
          variable.selectedOption = 'selected_option' in reportVar ? reportVar['selected_option'] : null;
          variable.checked = true;
        } else {
          variable.checked = false;
          variable.selectedOption = null;
        }
      }
    },
    async sendReport({ commit, state}, file) {
      /**
       * Acción obtener las variables disponibles para generar un reporte masivo
       * de folios
       *
       * @param {Object} context contexto del estado del store
       * @returns {} No retorna nada
       * @example
       * $store.dispatch('getFolioReports', null)
       * @author Luis Merizalde <luis.merizalde@arcerojas.com>
       */
      // realizar limpieza para una nueva consulta
      state.loadingReport = true;
      state.excelFile = null;
      state.reportData = null;
      // obtener las variables seleccionadas
      const selectedVariables = state.managerVariables.filter((x) => x.checked === true).map(
        (x) => {return {variable: x.key, selected_option: x.selectedOption, section: x.section}});
      // obtener el reporte seleccionado
      let reportId = 'empty';
      if (state.selectedReport !== null) {
        reportId = state.reportTemplates.filter((x) => x.name === state.selectedReport)[0].id;
      }
      try {
        const token = localStorage.getItem('token');
        const response = await ReportService.sendReport(file, selectedVariables, reportId, token);
        if (response) {
          // state.excelFile = response.data;
          state.reportData = response.data;
        }
        state.loadingReport = false;
      } catch (error) {
          console.log(error);
          commit('ui/SHOW_ALERT',{
            alertMessage: `Ocurrio un error al traer las plantilla de los folios
              (${error.response.status} - ${error.response.data.detail})`,
            alertType: 'error'
          }, {root: true});
          state.loadingReport = false;
      }
    },
  },

  getters: {
    // getter para obtener las varable por seccion
    getVariablesSection: (state) => (sectionName) => {
      return state.managerVariables.filter((x) => x.section === sectionName);
    },
    // getter para obtener las variable que están seleccionadas
    getCheckedVariables: (state) => {
      return state.managerVariables.filter((x) => x.checked === true).length;
    },
    // getter el elemento del reporte seleccionado
    getSelectedReport: (state) => {
      let selectedReport = null;
      if (state.selectedReport !== null) {
        selectedReport = state.reportTemplates.filter((x) => x.name === state.selectedReport)[0];
      }
      return selectedReport;
    }
  },
};

export default reports;