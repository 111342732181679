<template lang="pug">
v-card
  v-toolbar(
    height="40"
    :color="folioColor"
    dark)
    v-toolbar-title Información adicional del predio
  v-card-subtitle(class="pt-2 pb-0") Tipo Predio
  v-card-text(class="text--primary text-h4")
    div {{currentFolio.tipo_predio}}

  v-card-subtitle(class="pt-2 pb-0") Dirección
  v-card-text(class="text--primary text-h5")
    div {{currentFolio.direccion}}

  v-card-subtitle(class="pt-2 pb-0") Linderos
  v-card-text(class="text--primary text-caption")
    text-cutter(
      :text="currentFolio.cabida"
      :text-length="100"
      modal-title="Linderos del predio")
    v-btn(
      v-if="checkCoordinates(currentFolio.cabida)"
      small
      class="ma-2"
      color="amber"
      @click="extractCoordinates(currentFolio.cabida)") Ver coordenadas

  div(v-if="currentFolio.linderos_tecnicos")
    v-card-subtitle(class="pt-2 pb-0") Linderos Técnicos
    v-card-text(class="text--primary text-caption")
      text-cutter(
        :text="currentFolio.linderos_tecnicos"
        :text-length="100"
        modal-title="Linderos del predio")
      v-btn(
        v-if="checkCoordinates(currentFolio.linderos_tecnicos)"
        small
        class="ma-2"
        color="amber"
        @click="extractCoordinates(currentFolio.linderos_tecnicos)") Ver coordenadas

  v-card-subtitle(class="pt-2 pb-0") Complementaciones
  v-card-text(class="text--primary text-caption")
    text-cutter(
      :text="currentFolio.complementacion"
      :text-length="100"
      modal-title="Complementación del predio")
  v-divider
  div(class="d-flex flex-row py-2")
    v-card-subtitle(class="pt-2 pb-0") Área Terreno
    v-card-text(class="text--primary text-h6")
      div(v-if="currentFolio.area_terreno > -1") {{currentFolio.area_terreno.toFixed(2).replace('.', ',')}} m<sup>2</sup>
      div(v-else) Sin datos
    v-card-subtitle(class="pt-2 pb-0") Área Construcción
    v-card-text(class="text--primary text-h6")
      div(v-if="currentFolio.area_construida > -1") {{currentFolio.area_construida.toFixed(2).replace('.', ',')}} m<sup>2</sup>
      div(v-else) Sin datos
    v-card-subtitle(class="pt-2 pb-0") Área Privada
    v-card-text(class="text--primary text-h6")
      div(v-if="currentFolio.area_privada > -1") {{currentFolio.area_privada.toFixed(2).replace('.', ',')}} m<sup>2</sup>
      div(v-else) Sin datos
    v-card-subtitle(class="pt-2 pb-0") Destino Económico
    v-card-text(class="text--primary text-h6")
      div {{currentFolio.actividad_economica}}
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import TextCutter from '@/components/TextCutter';
import { findAllMatches } from '@/utils';

export default {
  name: 'FolioSecondaryInfo',

  components: {
    TextCutter,
  },

  data: () =>({
    // lista de expresiones regulares para detectar coordenadas
    regexCoordinates: [
      /[0-9]{6,7}[,.]?[0-9]{0,3}\s*M?[NE]/,
      /(?:E|N|NORTE|ESTE)\s*:?\s*[0-9.,]{6,9}[,.]?[0-9]{0,3}/,
    ]
  }),

  computed: {
    ...mapState('folios', ['currentFolio']),
    ...mapGetters('folios', ['folioColor']),
  },

  methods: {
    ...mapMutations('ui', ['SHOW_BOTTOM_MODAL', 'CLEAR_BOTTOM_MODAL', 'SHOW_ALERT']),
    // metodo para ver si los linderos tienen coordenadas
    checkCoordinates(boundaryText) {
      // eslint-disable-next-line
      const regex = /(?:E|N|NORTE|ESTE)?\s*:?\s*[0-9\.,]{6,9}[,.]?[0-9]{0,3}\s*M?\.?[NE]?/;
      return regex.test(boundaryText);
    },
    // metodo para extraer las coordenadas de los linderos
    extractCoordinates(boundaryText) {
      // eslint-disable-next-line
      const regex = /(?:E|N|NORTE|ESTE)?\s*:?\s*[0-9]{0,3}[\.\,\s]?[0-9]{1,3}[\.\,\s]?[0-9]{1,3}[\.\,\s]?[0-9]{1,3}\s*M?\.?[NE]?/g;
      // const regex2 = /[0-9]{6,7}[,.]?[0-9]{0,4}/
      const regex2 = /[0-9]{6,7}(?= [0-9]{1,3})|[0-9]{6,7}\.?[0-9]{1,3}/;
      // expresion regular para sacar la componente de la coordenada
      const regexAxisGlobal = /(?:N|NORTE|ESTE|E)/g;
      const regexAxis = /(?:N|NORTE|ESTE|E)/;

      // funcion para eliminar todos los puntos menos el ultimo
      function removeChar(string, char) {
        const lastIndex = string.lastIndexOf(char);
        const newString = `${string.slice(0, lastIndex).replaceAll(char, '')}${string.slice(lastIndex)}`;
        return newString;
      }

      // funcion para realizar el ultimo procesamiento de la coordenada
      function processCoord(coord) {
        let formattedComp = ''
        const matches = regex2.exec(coord);
        if (matches === null) {
          coord = coord.replaceAll(' ', '');
          formattedComp = regex2.exec(coord)[0];
        } else {
          formattedComp = matches[0]
        }
        return parseFloat(formattedComp);
      }

      // limpiar el modal inferiro
      this.CLEAR_BOTTOM_MODAL();
      
      // valores de la componente X
      const xAxis = ['E', 'ESTE'];
      // valores de la componente Y
      const yAxis = ['N', 'NORTE'];
      // extraer coordenasa con expresiones regulares
      const rawCoordinates = findAllMatches(regex, boundaryText);
      // limpiar las coordenadas crudas
      const cleanedCoordinates = []

      try {
        for (let index = 0; index < rawCoordinates.length; index++) {
          let element = rawCoordinates[index];
          const matches = findAllMatches(regexAxisGlobal, element);
          // ver si los elemntos encontrados pertencen al este
          const matchesAxis = matches.map((x) => xAxis.includes(x));
          // validar caso cuando se encuentran metros
          let removeCoord = false;
          if (matchesAxis.length >= 2) {
            if (matchesAxis[0] && matchesAxis[1]) {
              removeCoord = true;
            }
          }

          // remover cuando los dogitos del elemeto son menores a seis
          const numberMatches = findAllMatches(/[0-9]/g, element);
          if (numberMatches.length < 6) {
            removeCoord = true;
          }
          
          // agragr la coordenada solo si pasa las validaciones
          if (!removeCoord && element.length > 0) {
            // eliminar el ultimo punto o coma si tiene
            if ([',', '.'].includes(element[element.length - 1])) {
              element = element.slice(0, element.length - 1)
            }
            cleanedCoordinates.push(element);
          }
        }
        // ordenar las coordenadas en pares
        const pairedCoordinates = []
        for (let index = 0; index < cleanedCoordinates.length/2; index++) {
          pairedCoordinates.push([cleanedCoordinates[index*2], cleanedCoordinates[(index * 2) + 1]]);
        }
        
        // ordenar los pares de coordenadas como [NORTE, ESTE]
        const coordinates = [];
        for (let index = 0; index < pairedCoordinates.length; index++) {
          let pairedCoordinate = pairedCoordinates[index];
          const axisA = regexAxis.exec(pairedCoordinate[0])[0];
          // console.log(pairedCoordinate[1], regexAxis.exec(pairedCoordinate[1]));
          let axisB = regexAxis.exec(pairedCoordinate[1])
          axisB = axisB !== null ? axisB[0] : null;
          let formattedCoordinate = [];
          if (yAxis.includes(axisA) && xAxis.includes(axisB)) {
            formattedCoordinate = pairedCoordinate;
          } else if (yAxis.includes(axisB) && xAxis.includes(axisA)) {
            formattedCoordinate = [pairedCoordinate[1], pairedCoordinate[0]];
          } else if (yAxis.includes(axisA) && axisB === null) {
            formattedCoordinate = pairedCoordinate;
          } else if (xAxis.includes(axisA) && axisB === null) {
            formattedCoordinate = [pairedCoordinate[1], pairedCoordinate[0]];
          } else if (xAxis.includes(axisA) && axisB === xAxis.includes(axisB)) {
            formattedCoordinate = [pairedCoordinate[1], pairedCoordinate[0]];
          }
          // convertir comas a puntos
          formattedCoordinate = formattedCoordinate.map((x) =>
            x.replaceAll(',', '.').replaceAll('M.E', '').replaceAll('M.N', '')); //.replaceAll(' ', '')
          // eliminar los puntos intermedios
          formattedCoordinate = formattedCoordinate.map((x) => removeChar(x, '.'));
          // extraer valores numericos y convertir a flotante
          formattedCoordinate = formattedCoordinate.map((x) => processCoord(x));
          
          // eliminar coordenadas repetidad
          let removeCoord = false;
          if (coordinates.length >= 1) {
            if (coordinates[coordinates.length-1][0] === formattedCoordinate[0] && coordinates[coordinates.length-1][1] === formattedCoordinate[1]) {
              removeCoord = true;
            }
          }
          if (!removeCoord) {
            coordinates.push(formattedCoordinate);
          }
        }
        // desplegar el modal inferior
        this.SHOW_BOTTOM_MODAL({
          dialogTitle: `Coordenadas cabida folio <b>${this.currentFolio.numero_matricula}</b>`,
          componentName: 'CoordinateManager',
          properties: {
            initialCoordinates: coordinates,
            initialOriginSrs: 'EPSG:3116',
            initialTargetSrs: 'EPSG:4326',
            addInitialPolygon: true,
          }
        });
      } catch (error) {
        this.SHOW_ALERT({
          alertMessage: '¡Ocurrió un error al tratar de convertir las coordenadas!',
          alertType: 'error'
        })
      }
      
    }
  }
}
</script>
