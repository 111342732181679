import apiClient from './config.js';

export default {
  // iniciar sesión de usuario
  async login(email, password) {
    const params = new URLSearchParams();
    params.append('username', email);
    params.append('password', password);
    return apiClient.post('auth/login', params);
  },
  // Borrar las credenciales del usuario
  logout() {
    return apiClient.post('logout');
  },
  // iniciar sesión de usuario
  async changePassword(password, newPassword) {
    const data = {
      password,
      new_password: newPassword,
      email: localStorage.getItem('email')
    }
    return apiClient.post('auth/change_password', data);
  },
};
