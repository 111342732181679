<template lang="pug">
v-card
  v-toolbar(
    height="40"
    color="cyan"
    dark)
    v-toolbar-title
      span Localización del predio según autoridad catastral
    v-spacer
    a(href="" id="export" ref="exportLink" hidden)
    v-btn(
      v-if="currentSearch.poligono"
      color="green"
      class="ma-2 white--text"
      @click="getKLM")
      | Descargar kml
      v-icon(
        right
        dark) mdi-cloud-download-outline
  property-visor(ref="propertyVisor" :coordinates="currentSearch.poligono ? currentSearch.poligono.coordinates[0] : []")
  v-divider
  div(class="d-flex" v-if="currentSearch.ubicacion")
    v-card-subtitle(class="my-auto")
      div Ubicación
    v-card-text(class="text--primary text-body-1 font-weight-bold my-auto")
      div(class="pt-4") {{currentSearch.ubicacion}}
    v-card-subtitle(class="my-auto")
      div Bases consultadas
    v-card-text(class="text--primary text-body-1 font-weight-bold my-auto")
      v-list(dense)
        v-list-item-group(
          color="primary")
          v-list-item(
            v-for="(item, i) in currentSearch.used_databases"
            :key="i")
            v-list-item-icon
              v-icon(
                :color="item.id == currentSearch.match_database ? 'green' : ''"
                v-text="item.id == currentSearch.match_database ? 'mdi-database-check' : 'mdi-database'")
            v-list-item-content
              v-list-item-title(v-text="item.name")
              v-list-item-subtitle(v-text="item.date")
  div(class="d-flex" v-if="currentSearch.ubicacion")
    v-card-subtitle(class="my-auto")
      div Tipo Predio
    v-card-text(class="text--primary text-body-1 font-weight-bold my-auto")
      div(class="pt-4") {{currentSearch.tipo === 'URBAN' ? 'Urbano' : 'Rural'}}
    v-card-subtitle(class="my-auto")
      div Área polígono
    v-card-text(class="text--primary text-body-1 font-weight-bold my-auto")
      div(class="pt-4") {{currentSearch.area.toFixed(2)}} m²
  div(class="d-flex" v-if="currentSearch.matricula")
    v-card-subtitle(class="my-auto")
      div Matrícula Inmobiliaria
    v-card-text(class="text--primary text-h5 font-weight-bold my-auto")
      div(class="pt-4") {{currentSearch.matricula}}
    div(
      title="Ver folio"
      class="mr-2 clickable"
      v-if="currentSearch.folio_id"
      @click="detailFolio(currentSearch.folio_id)")
      v-icon(class="mr-1" color="green") mdi-checkbox-outline 
      | En el sistema


</template>

<script>
import { mapState } from 'vuex';
import folioMixin from '@/mixins/folioMixin.js';
import PropertyVisor from '@/components/PropertyVisor';
import tokml from 'tokml';

export default {
  name: 'SearchLocationInfo',
  mixins: [folioMixin],
  components: {
    PropertyVisor
  },
  computed: {
    ...mapState('folios', ['currentSearch']),
  },
  methods: {
    getKLM() {
      const data = this.$refs.propertyVisor.polygon.toGeoJSON();
      // agregar los atributos al geojson
      let propertyData = {};
      propertyData.tipo = this.currentSearch.tipo === 'URBAN' ? 'Urbano' : 'Rural';
      propertyData.ubicacion = this.currentSearch.ubicacion || '';
      propertyData.base = this.currentSearch.match_database;
      propertyData.area = this.currentSearch.area; 
      propertyData.matricula = this.currentSearch.matricula;

      if (this.currentSearch.registros) {
        propertyData = {...propertyData, ...this.currentSearch.registros};
      }

      data.properties = propertyData;
      var kml = tokml(data);

      var convertedData = 'application/xml;charset=utf-8,' + encodeURIComponent(kml);

      // if you want to use the official MIME type for KML
      // var convertedData = 'application/vnd.google-earth.kml+xml;charset=utf-8,' + 
      // encodeURIComponent(kml);
      const today = new Date();
      let formatDate = today.toISOString().replace('T', '_').replace('Z', '');
      formatDate = formatDate.slice(0, formatDate.length - 4);
      document.getElementById('export').setAttribute('href', 'data:' + convertedData); 
      document.getElementById('export').setAttribute('download',
        `descarga_predio_${formatDate}.kml`);
      this.$refs.exportLink.click();
    }
  }
}
</script>
  