<template lang="pug">
div(style="height:70vh")
  login-form
  support-button
</template>

<script>
import LoginForm from '@/components/forms/LoginForm.vue';
import SupportButton from '@/components/ui/SupportButton.vue';

export default {
  name: 'LoginView',
  components: {
    LoginForm,
    SupportButton,
  }
}
</script>
