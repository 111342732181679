<template lang="pug">
v-card(class="mx-auto mt-10")
  v-card(flat)
    v-btn(
      absolute
      bottom
      color="light-blue "
      right
      fab) <b>{{ getAnnotationByDocument.length }}</b>
    annotation-detail(
      class="pa-3"
      style="height: 38vh"       
      :annotation="selectedAnnotation"
      v-if="selectedAnnotation")
    div(v-else class="text-center" style="height: 38vh")
      div(class="pt-10") Seleccione una anotación de la línea de tiempo
  v-divider
  v-card-text(
    class="py-0 scrollable" 
    :style="{height: isMounted ? '60vh' : '100%', overflowY: 'scroll', overflowX: 'hidden'}")
    v-timeline(
      align-rigth
      dense
      )
      v-timeline-item(
        v-for="(annotation, index) in getAnnotationByDocument"
        :key="annotation.numero"
        :color="annotation.color"
        
        large)
        template(v-slot:icon)
          span(
            @click="selectAnnotation(annotation)"
            :class="['clickable', annotation.cancelacion ? 'is-cancel' : '', 'text-h6', ['7', '8'].includes(annotation.codigo) ? 'black--text' : 'white--text']") {{ annotation.numero }}
        v-row(class="pt-1")
          v-col(cols="3")
            div(
              v-if="annotation.numero - 1 === index"
              class="mt-4 green--text"
              title="Concuerda con el orden cronológico de las anotaciones")
              v-icon(color="green") mdi-check-circle
              | Concuerda
            div(
              v-else
              class="mt-4 red--text"
              title="Hay una discrepancia con el orden de las anotaciones")
              v-icon(color="red") mdi-close-box
              | Difiere
          v-col
            strong {{annotation.documento.replace('del', '')}}
            div(class="text-caption")
              v-icon(class="mr-1" small) mdi-bank-outline
              | {{annotation.datos_documento}}
            div(class="text-caption")
              v-icon(class="mr-1" small) mdi-calendar-month
              | {{annotation.fecha_doc_anotacion}}
</template>

<script>
import { mapGetters } from 'vuex';
import AnnotationDetail from '@/components/annotations/AnnotationDetail';

export default {
  name: 'AnnotationTimeline',
  props: {
    annotations: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selectedAnnotation: null,
      isMounted: false
    }
  },
  computed: {
    ...mapGetters('annotations', ['getAnnotationByDocument']),
  },
  components: {
    AnnotationDetail,
  },
  methods: {
    selectAnnotation(annotation) {
      console.log('entro click select annotation timeline');
      this.selectedAnnotation = annotation;
    },
    // getAnnotationByDocument() {
    //   const orderAnnotations = this.ann.sort((a,b) => new Date(b.fecha_documento) - new Date(b.fecha_documento));
    //   for (let index = 0; index < orderAnnotations.length; index++) {
    //     const annotation = orderAnnotations[index];
    //     let code = annotation.codigo_especificacion.replaceAll('0', '')[0];
    //     let color =  state.annotationCategories.filter((x) => x.startChar == code)[0].color;
    //     annotation.color = color;
    //     annotation.codigo = code;
    //   }
    //   return orderAnnotations;
    // },
  },
  mounted() {
    this.isMounted = true;
  }
}
</script>

<style scoped>
.is-cancel:after {
  position: absolute;
  content: "\2715"; /* \274c 2717 */
  font-size: 20px; 
  font-weight: 100;
  color: black;
  text-align: center;
  transform: scale(2, 1.3);
  left: 40px;
}
</style>
