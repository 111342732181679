<template lang="pug">
v-container(class="text-center" fluid)
  h1 Carga de Folios (máximo {{maxUploadFiles}})
  drag-zone
  v-btn(
    :loading="loadingFiles"
    :disabled="rawFiles.length === 0"
    color="green"
    class="ma-2 white--text"
    @click="sendFiles")
    v-icon(left) mdi-cloud-upload
    | Cargar
  v-btn(
    :loading="loadingFiles"
    :disabled="!(successFiles !== 0)"
    color="blue"
    class="ma-2 white--text"
    @click="REMOVE_SUCCESS_FILES")
    v-icon(left) mdi-delete
    | Eliminar Procesados ({{ successFiles }})
  v-btn(
    :loading="loadingFiles"
    :disabled="!(errorFiles !== 0)"
    color="red"
    class="ma-2 white--text"
    @click="REMOVE_ERROR_FILES")
    v-icon(left) mdi-delete
    | Eliminar Errores ({{ errorFiles }})
  processed-files(v-if="processedFiles.length > 0")
</template>

<script>
import DragZone from '@/components/DragZone';
import { maxUploadFiles } from '@/config.js';
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import ProcessedFiles from '@/components/files/ProcessedFiles.vue';

export default {
  name: 'FoliosUpload',
  components: {
    DragZone,
    ProcessedFiles
  },
  computed: {
    ...mapGetters('files', ['rawFiles', 'processedFiles', 'successFiles', 'errorFiles']),
    ...mapState('files', ['loadingFiles']),
  },
  methods: {
    ...mapActions('files', ['uploadFolios']),
    ...mapMutations('files', ['REMOVE_SUCCESS_FILES', 'REMOVE_ERROR_FILES']),
    sendFiles() {
      this.uploadFolios();
    }
  },
  data: () => ({
    maxUploadFiles,
  }),  
};
</script>
