<template lang="pug">
v-container 
  v-alert(
    class="text-h4"
    border="bottom"
    color="blue-grey"
    dark)
      | Diccionario de anotaciones
  v-card(:color="currentColor")
    v-card-title(class="text-center justify-center py-6")
      h1(class="font-weight-bold text-h4 white--text") Resolución 6007 de 2019

    v-tabs(
      v-model="tab"
      background-color="transparent"
      color="basil"
      grow)
      v-tab(
        :class="[tab === index ? 'white--text': 'gray--text']"
        v-for="item, index in annotationNames"
        :key="index") {{ item }}

    v-tabs-items(v-model="tab")
      v-tab-item(
        v-for="item, index in annotationNames"
        :key="index")
        v-card(
          color="basil"
          flat)
          v-list(dense)
            v-list-item-group(
              color="primary")
              v-list-item(
                v-for="(especification, i) in getEspecifications(index)"
                :key="i")
                v-list-item-icon
                  v-chip(
                    :color="currentColor"
                    small
                    text-color="white") <b>{{especification.number}}</b>
                v-list-item-content
                  v-list-item-title(v-text="especification.content")
</template>

<script>
import { mapGetters } from 'vuex';

const jsonData= require('@/data/especifications_2019.json'); 

export default {
  name: 'EspecificationDict',
  computed: {
    ...mapGetters('annotations', ['annotationNames', 'annotationVuetifyColors']),
    currentColor() {
      return this.annotationVuetifyColors[this.tab]
    }
  },
  methods: {
    getEspecifications(index) {
      return jsonData[String(index+1)]
    }
  },
  data: () => ({
    tab: null,
    items: [
      'Appetizers', 'Entrees', 'Deserts', 'Cocktails',
    ],
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  })
};
</script>

<style scoped>
/* Helper classes */
.basil {
  background-color: #FFFBE6 !important;
}
.basil--text {
  color: #356859 !important;
}
</style>
