import { latLng, icon } from "leaflet";
import { mapState } from 'vuex';

export default {
  data: () => ({
    center: latLng(5.047269, -73.083070),
    // variable que almacena el zoom actual
    currentZoom: 11.5,
    // variable que almacena la coordenada del centro del mapa
    currentCenter: latLng(5.047269, -73.083070),
    // objeto que almacena el mapa
    map: null,
    // configuracion del mapa
    mapOptions: {
      zoomSnap: 0.5
    },
    // valor del nivel de acercamiento del mapa
    zoom: 13,
    // icono del marcador leaflet
    icon: icon({
      iconUrl: require('@/assets/marker-icon.png'),
      shadowUrl: require('@/assets/marker-shadow.png'),
      iconSize: [25, 37],
      iconAnchor: [16, 37]
    }),
    // eatdo con los bound del mapa
    bounds: null,
    // eatdo para un polygono
    polygon: null,
    // color del polygono
    polygonColor: '#FF9800',
  }),

  computed: {
    ...mapState('geos', ['layers']),
  },

  methods: {
    // metodo que se lanza cuando el zoom se actualiza
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    // metodo que se lanza cuando el centro se actualiza
    centerUpdate(center) {
      this.currentCenter = center;
    },
    // metodo que se lanza cuando el
    lefletMapReady() {
      this.map = this.$refs.myMap.mapObject;
      setTimeout(() => {
        this.map.invalidateSize();
      }, 0);
    },
    // cuando un polygono esta listo
    polygonReady() {
      this.polygon = this.$refs.polygon.mapObject;
      this.bounds = this.polygon._bounds;
      setTimeout(() => {
        this.map.fitBounds(this.bounds);
      }, 100);
    },
    // metodo para enficar el poligono opera con el control
    focusProperty() {
      this.map.fitBounds(this.bounds);
    }
  }
}