<template lang="pug">
v-file-input(
  @change="onFilePicked"
  :rules="rules"
  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
  :placeholder="placeholder"
  prepend-icon="mdi-file-excel"
  ref="fileExcel"
  :label="label")
</template>
  
<script>
  
  export default {
    name: 'ExcelFileInput',
    model: {
      prop: 'selectedFile',
      event: 'change'
    },
    props: {
      placeholder: {
        type: String,
        default: 'Seleccione un archivo Excel'
      },
      label: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        selectedFile: null,
        errorMessage: '',
        rules: [
          value => !value || value.size < 2000000 || 'File size should be less than 2 MB!',
        ],
      };
    },
    computed: {
    },
    methods: {
      onFilePicked(e) {
        console.log('onFilePicked', e);
        this.selectedFile=e;
        this.$emit('change', this.selectedFile);
      }
    }
  };
</script>
