<template lang="pug">
v-card(
  class="mx-auto"
  max-width="70%")
  v-list-item(two-line)
    v-list-item-content
      v-list-item-title(class="text-h5") Consultar Archivos de Soporte (Máximo <b>{{maxZipPdfs}}</b> archivos)
      v-list-item-subtitle
        | Para buscar los archivos cargue el archivo Excel con el listado de matrículas comenzando desde la celda <b>A1</b>
  excel-file-action(:loading="loadingExcel" :errorMessage="excelError")
  v-divider
  list-convention-icon(class="mt-4" :conventions="conventions")
  pdf-list-preview(:folios="folios")
  v-divider

  v-card-actions
    v-spacer
    v-btn(
      @click="searchIncompleteFolios"
      :disabled="incompleteFolios === 0"
      class="orange"
      color="black"
      small
      title="Descargar folios con Información Básica o Jurídica INCOMPLETA"
    ) Descargar Incompletos <b class="ml-2" style="font-size:22px">{{ incompleteFolios }}</b>
    v-btn(
      @click="searchMissingFolios"
      :disabled="missingFolios === 0"
      class="yellow"
      small
      color="black"
    ) Descargar No encontrados <b class="ml-2" style="font-size:22px">{{ missingFolios }}</b>
    button-download-pdf(class="ml-2" :folios="avalaibleFolios")
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import ExcelFileAction from '@/components/ui/ExcelFileAction';
import PdfListPreview from '@/components/files/PdfListPreview';
import { maxQueryCertificates } from '@/config.js';
import { maxZipPdfs, firstLimitDaysFolio, secondLimitDaysFolio } from '@/config'
import ListConventionIcon from '@/components/ui/ListConventionIcon';
import ButtonDownloadPdf from '@/components/files/ButtonDownloadPdf';

export default {
  name: 'VurMassiveManager',

  components: {
    ExcelFileAction,
    PdfListPreview,
    ListConventionIcon,
    ButtonDownloadPdf,
  },

  data: () => ({
    // Mensaje de error la procesar archivo excel
    excelError: '',
    // indica el procesamiento del archico excel
    loadingExcel: false,
    // los registros de folios mostrados en la tabla
    folios: [],
    maxQueryCertificates,
    // el numero maximo de pdfs que se pueden generar
    maxZipPdfs,
    conventions: [
      {
        icon: 'mdi-traffic-light',
        color: 'green',
        text: `Impresión < ${firstLimitDaysFolio} días`
      },
      {
        icon: 'mdi-traffic-light',
        color: 'yellow',
        text: `Impresión entre ${firstLimitDaysFolio} y ${secondLimitDaysFolio} días`
      },
      {
        icon: 'mdi-traffic-light',
        color: 'red',
        text: `Impresión > ${secondLimitDaysFolio} días`
      },
    ],
  }),

  computed: {
    // propiedad computada para encontrar folios que no estan en el sistema
    missingFolios() {
      return this.folios.filter((x) => x.id === '').length;
    },
    // propiedad computada que indica los folios que estan en el sistema
    avalaibleFolios() {
      return this.folios.filter((x) => x.id !== '').map((x) => x.numero_matricula);
    },
    // propiedad computada para encontrar folios que no estan en el sistema
    incompleteFolios() {
      return this.folios.filter((x) => x.id !== '' && (x.numero_anotaciones === '0' || x.estado === '')).length;
    },
  },

  created() {
    this.$bus.$on('file-loaded', async (file) => {
      this.CHANGE_VALUE({
        varName: 'zipPath',
        value: ''
      })
      this.loadingExcel = true;
      this.excelError = '';
      const res = await this.uploadPdfList(file);
      this.loadingExcel = false;
      if ('error' in res) {
        this.excelError = res.error;
      } else {
        this.folios = res.data;
      }
    });
  },

  mounted() {
    this.CHANGE_VALUE({
      varName: 'zipPath',
      value: ''
    })
    this.getCircles();
  },

  beforeDestroy(){
    this.$bus.$off('file-loaded', null);
  },

  methods: {
    ...mapActions('files', ['uploadPdfList']),
    ...mapActions('vur', ['populateFolios', 'getCircles']),
    ...mapMutations('files', ['CHANGE_VALUE']),
    //metodo para descargar los folios que no se encuentran en ARkandha
    searchMissingFolios() {
      const searchFolios = [];
      const missingFolios = this.folios.filter((x) => x.id === '');
      for (let index = 0; index < missingFolios.length; index++) {
        const element = missingFolios[index];
        searchFolios.push({
          record: element.numero_matricula
        });
      }
      this.populateFolios(searchFolios);
      this.$router.push({
        name: 'VurMassiveSearch',
        params: {
          preserveList: true
        }
      });
    },
    // metodo para descargar los folios incompletos en arkandha
    searchIncompleteFolios() {
      const searchFolios = [];
      const incompleteFolios = this.folios.filter((x) => x.id !== '' && (x.numero_anotaciones === '0' || x.estado === ''));
      for (let index = 0; index < incompleteFolios.length; index++) {
        const element = incompleteFolios[index];
        searchFolios.push({
          record: element.numero_matricula
        });
      }
      this.populateFolios(searchFolios);
      this.$router.push({
        name: 'VurMassiveSearch',
        params: {
          preserveList: true
        }
      });
    }
  }
};
</script>
  