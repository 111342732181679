const env = process.env.VUE_APP_ENV;

let envApiUrl = '';
let envWebsocketUrl = '';
let envStaticUrl = '';
let envStaticScrapperUrl = '';

if (env === 'production') {
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_PROD}/api/v1`;
  envStaticUrl = `https://${process.env.VUE_APP_DOMAIN_PROD}`;
  envWebsocketUrl = `wss://${process.env.VUE_APP_DOMAIN_PROD}`;
  envStaticScrapperUrl = `https://${process.env.VUE_APP_DOMAIN_PROD}`;
} else if (env === 'staging') {
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_STAG}/api/v1`;
  envStaticUrl = `https://${process.env.VUE_APP_DOMAIN_STAG}`;
  envWebsocketUrl = `wss://${process.env.VUE_APP_DOMAIN_STAG}`;
  envStaticScrapperUrl = `https://${process.env.VUE_APP_DOMAIN_STAG}`;
} else {
  envApiUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}/api/v1`;
  envStaticUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}`;
  envWebsocketUrl = `ws://${process.env.VUE_APP_DOMAIN_DEV}`;
  envStaticScrapperUrl = `http://${process.env.VUE_APP_SCRAPPER_URL}/static`;
  // envStaticScrapperUrl = `http://${process.env.VUE_APP_SCRAPPER_URL}/static_scrapper`;
}

export const apiUrl = envApiUrl;
export const staticUrl = envStaticUrl;
export const socketUrl = envWebsocketUrl;
export const appName = process.env.VUE_APP_NAME;
export const staticScrapperUrl = envStaticScrapperUrl;
