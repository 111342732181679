<template lang="pug">
div(
    class="folios-drag-zone mx-auto scrollable"
    @click="clickDragZone"
    ref="dragZone"
    @drop="onDrop"
    @dragover.prevent="onDragLeave"
    @dragenter.prevent="onDragEnter"
  )
  div(
    v-if="!showFilesPreview && rawFiles.length === 0"
    class="d-flex flex-column vertical-center")
    v-icon(large) mdi-file
    span(class="text-body-1 mt-2")
      | Utilice el drag & drop (Arrastrar y soltar) o presione sobre
      | el recuadro para cargar los archivos desde su equipo

    span(class="text-caption mt-2 font-weight-light")
      | Los elementos que debe cargar son los emitidos por la superintendencia de
      | notariado y registro  en formato <b>PDF</b>

  v-row(class="ma-2" v-else)
    v-col(v-for="file in rawFiles" cols="4" sm="12" md="3" lg="2" :key="file.key")
      pdf-file(:file="file")
  input(
    type="file" 
    multiple
    hidden
    @change="previewFiles"
    ref="foliosFiles"
    accept="application/pdf"
    id="folios-files"
  )
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import PdfFile from '@/components/PdfFile.vue';
import { maxUploadFiles } from '@/config.js';

export default {
  name: 'DragZone',
  components: {
    PdfFile,
  },
  data: () => ({
  }),
  computed: {
    ...mapState('files', ['showFilesPreview']),
    ...mapGetters('files', ['rawFiles']),
  },
  methods: {
    ...mapMutations('files', ['APPEND_FILE', 'SHOW_DRAG_PREV']),
    ...mapMutations('ui', ['SHOW_ALERT']),
    clickDragZone() {
      this.$refs.foliosFiles.click();
    },
    storeFiles(inputFiles) {
      const folioSizeLimit = 1024 * 1024 * 20; // limite de 20 MB
      // validar el tamaño de cada uno de los archivos
      for (let i = 0; i < inputFiles.length; i++) {
        // validar que no se suban más archivos de los permitidos
        if (this.rawFiles.length >= maxUploadFiles) {
          this.SHOW_ALERT({
            alertMessage: `No se pueden subir más de ${maxUploadFiles} archivos`,
            alertType: 'error'
          });
          if (inputFiles.length > 0) {
            this.SHOW_DRAG_PREV(true);
          }
          return;
        }
        const file = inputFiles[i];
        const fileSize = file.size;
        if (fileSize < folioSizeLimit) {
          this.APPEND_FILE(file);
        }
      }
      if (inputFiles.length > 0) {
        this.SHOW_DRAG_PREV(true);
      }
    },
    previewFiles(event) {
      this.storeFiles(event.target.files);
    },
    onDragEnter(e) {
      e.preventDefault();
      e.stopPropagation();
      this.SHOW_DRAG_PREV(true);
    },
    onDragLeave(e) {
      e.preventDefault();
      e.stopPropagation();
      this.SHOW_DRAG_PREV(false);
    },
    onDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      const { files } = e.dataTransfer;
      this.storeFiles(files);
    },
  }
};
</script>

<style scoped>
  .folios-drag-zone {
    height: 50vh;
    width: 50%;
    background: rgba(100,100,100,0.5);
    border-radius: 1rem;
    border: 3px dashed black;
    position: relative;
    cursor: pointer;
    max-height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .vertical-center {
    margin: 0;
    position: absolute;
    top:20%;
    left: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
</style>
