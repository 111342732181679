<template lang="pug">
div(
  v-if="isMounted"
  class="truncated-folio-detail-height scrollable"
  ref="wrapperAnnotationDetail")
  v-list-item(two-line)
    v-list-item-content
      v-list-item-title(class="text-h5") FMI {{viewedExtraInfo.record}}

  v-row(class="mx-2")
    v-col 
      div(class="text-caption font-weight-light") Vigencia
      div(class="text-body-1") {{viewedExtraInfo.date}} 
      div(class="text-caption font-weight-light") Destino Económico 
      div(class="text-body-1") {{viewedExtraInfo.destination}} 
      div(class="text-caption font-weight-light") Avaluo
      div(class="text-body-1") $ {{ currencyFormat }}
  v-divider
  v-subheader Titulares
  v-list(class="my-0 py-0" dense)
    people-item(v-for="p in viewedExtraInfo.owners" :person="p" :key="`owner-${p.nombre}`")
</template>

<script>
import { mapState } from 'vuex';
import PeopleList from '@/components/people/PeopleList.vue';
import AnnotationBadge from '../annotations/AnnotationBadge.vue';
import PeopleItem from '@/components/people/PeopleItem';

export default {
  name: 'RecordExtraInfo',
  components: {
    PeopleList,
    AnnotationBadge,
    PeopleItem,
  },
  computed: {
    ...mapState('folios', ['viewedExtraInfo']),
    currencyFormat() {
      if (this.viewedExtraInfo.appraisal === -1) {
        return 'Sin valor';
      }
      let pesosCOLocale = Intl.NumberFormat('es-CO');
      return pesosCOLocale.format(this.viewedExtraInfo.appraisal);
    },
  },
  mounted() {    
    this.isMounted = true;
  },
  data: () => ({
    isMounted: false,
  })
}
</script>