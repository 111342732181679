<template lang="pug">
v-navigation-drawer(
  v-model="showSidebar"
  temporary
  absolute
  color="light-blue lighten-4"
  style="z-index:10000; position:fixed")
  v-list-item
    v-list-item-avatar
      v-img(src="@/assets/male.png")
    v-list-item-content
      v-list-item-title {{ email }}

  v-divider(class="mt-2")

  v-list(dense color="primary")
    v-list-item(
      v-if="!item.subLinks"
      v-for="item in items"
      :to="item.url"
      :key="item.title"
      link)
      v-list-item-icon
        v-icon {{ item.icon }}
      v-list-item-content
        v-list-item-title {{ item.title }}

  support-button
</template>

<script>
import { mapState } from 'vuex';
import SupportButton from '@/components/ui/SupportButton.vue';

export default {
  name: 'SideBar',

  components: {
    SupportButton,
  },

  data: () => ({
    items: [
      {
        title: 'Mis Folios',
        url: '/folios',
        icon: 'mdi-file-multiple',
      },
      {
        title: 'Buscar Folio',
        url: '/search',
        icon: 'mdi-book-search-outline',
      },
      {
        title: 'Familia Registral',
        url: '/family',
        icon: 'mdi-family-tree',
      },
      {
        title: 'Cargar Folios',
        url: '/upload',
        icon: 'mdi-upload',
      },
      {
        title: 'Diccionario especificaciones',
        url: '/especifications',
        icon: 'mdi-clipboard-list',
      },
      {
        title: 'Círculos registrales',
        url: '/map',
        icon: 'mdi-map-outline',
      },
      {
        title: 'Cambiar contraseña',
        url: '/change-password',
        icon: 'mdi-account-key'
      },
      {
        title: 'Credenciales VUR',
        url: '/vur-credentials',
        icon: 'mdi-lock-plus'
      },
      {
        title: 'Listas Restrictivas',
        url: '/restrictive-lists',
        icon: 'mdi-account-alert'
      },
      {
        title: 'Reporte API',
        url: '/api-report',
        icon: 'mdi-file-excel-outline'
      },
      {
        title: 'Reporte Dinámico Folios',
        url: '/dynamic-report',
        icon: 'mdi-file-chart'
      },
      {
        title: 'VUR masivo',
        url: '/massive-vur',
        icon: 'mdi-download-multiple'
      },
      {
        title: 'VUR Propietarios',
        url: '/owner-vur',
        icon: 'mdi-home-group-plus'
      },
      {
        title: 'Búsqueda Masiva',
        url: '/massive-search',
        icon: 'mdi-text-search'
      },
      {
        title: 'Descargar PDFs',
        url: '/download-pdfs',
        icon: 'mdi-file-pdf-box'
      },
      {
        title: 'Conversor Coordenadas',
        url: '/conversor',
        icon: 'mdi-map-marker-distance'
      },
    ],
  }),

  computed: {
    ...mapState('auth', ['email']),
    showSidebar: {
      get() {
        return this.$store.state.ui.showSidebar;
      },
      set(value) {
        return this.$store.commit('ui/TOGGLE_SIDEBAR', value);
      }
    }
  }
};
</script>
