<template lang="pug">
div
  embed(class="pdf-visor" :src="urlFile || filePath")
</template>

<script>

export default {
  props: {
    urlFile: {
      type: String,
      default: null
    }
  },
  name: 'PdfPreview',
  computed: {
  },
  data: () => ({
  }),
}
</script>

<style scoped>
  .pdf-visor {
    width: -webkit-fill-available;
    height: 50vh;
  }
</style>