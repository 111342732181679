import AntService from '@/services/AntService.js';

const ant = {
  namespaced: true,
  state: {
    loadingExcel: false,
    excelError: ''
  },
  mutations: {
    CLEAR_ERROR(state) {
      state.excelError = '';
    },
  },
  actions: {
    async uploadFile({ commit, state }, file) {
      try {
        state.loadingExcel = true;
        const token = localStorage.getItem('token');
        const response = await AntService.uploadFile(file, token);
        if (response) {
          console.log(response);
          const message = response.data;
          state.excelError = message;
          commit('ui/SHOW_ALERT',{
            alertMessage: 'Se cargaron correctamente el listado en el sistema',
            alertType: 'success'
          }, {root: true});
        }
      } catch (error) {
          console.log(error);
          commit('ui/SHOW_ALERT',{
            alertMessage: `Ocurrio un error al cargar el listado en el sistema
              (${error.response.status} - ${error.response.data.detail})`,
            alertType: 'error'
          }, {root: true});
      }
      state.loadingExcel = false;
    },
  }
}

export default ant;
