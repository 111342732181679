const annotations = {
  namespaced: true,
  mutations: {
    SET_ANNOTATIONS(state, { annotations }) {
      state.currentAnnotations =  annotations;
    }
  },
  getters: {
    analysableCategories: (state) => state.annotationCategories.filter((x) => x.analysable === true),
    annotationNames: (state) => state.annotationCategories.map((x) => x.name),
    annotationColors: (state) => state.annotationCategories.map((x) => x.hexColor),
    annotationVuetifyColors: (state) => state.annotationCategories.map((x) => x.color),
    annotationsByCategory: (state) => state.annotationCategories.map((cat) => 
      state.currentAnnotations.filter((x) => x.especificacion).filter((x) => x.codigo_especificacion.replaceAll('0', '')[0] === cat.startChar).length),
    getPropertyOwners: (state) => state.currentAnnotations.filter(
      (x) => x.interesados.some(((y) => y.titularidad === 'Real'))
      && state.filterLegalNature.includes(x.codigo_especificacion.replaceAll('0', '')[0])),
    getAnnotationByDocument: (state) => {
      const orderAnnotations = state.currentAnnotations.sort((a,b) => new Date(b.fecha_documento) - new Date(b.fecha_documento));
      for (let index = 0; index < orderAnnotations.length; index++) {
        const annotation = orderAnnotations[index];
        let code = annotation.codigo_especificacion.replaceAll('0', '')[0];
        let color =  state.annotationCategories.filter((x) => x.startChar == code)[0].color;
        annotation.color = color;
        annotation.codigo = code;
      }
      return orderAnnotations;
    },
    getTimeline: (state) => {
      // traer las anotaciones que tienen propietarios con titulo Real y dentro de la naturaleza juridica especfificadas
      const ownerAnnotations = state.currentAnnotations.filter(
        (x) => x.interesados.some(((y) => y.titularidad === 'Real'))
        && state.filterLegalNature.includes(x.codigo_especificacion.replaceAll('0', '')[0]));
      const ownerTimeline = [];
      function saveOwner(owner, annotation, initDate, finishDate) {
        let code = annotation.codigo_especificacion.replaceAll('0', '')[0];
        let color =  state.annotationCategories.filter((x) => x.startChar == code)[0].hexColor;
        let copiedOwner = {...owner};
        copiedOwner.initAnnotation = annotation.numero;
        copiedOwner.lastAnnotation = annotation.numero;
        copiedOwner.initDate = initDate;
        copiedOwner.finishDate = finishDate;
        copiedOwner.color = color;
        copiedOwner.legalCondition = code;
        ownerTimeline.push(copiedOwner);
      }
      for (let annotationIndex = 0; annotationIndex < ownerAnnotations.length; annotationIndex++) {
        const annotation = ownerAnnotations[annotationIndex];
        // const annotationCode = annotation.codigo_especificacion.replaceAll('0', '')[0];
        const ownerPeople = annotation.interesados.filter((x) => x.titularidad === 'Real');
        let withoutPeople = annotation.interesados.filter((x) => x.titularidad === 'Desconocido');
        withoutPeople = withoutPeople.map((x) => x.nombre);
        for (let personIndex = 0; personIndex < ownerPeople.length; personIndex++) {
          const owner = ownerPeople[personIndex];
          // ver si el propietario existe
          const ownerExist = ownerTimeline.filter((x) => x.nombre === owner.nombre);
          // Almacenar los propietarios iniciales
          if (annotationIndex === 0 || ownerExist.length === 0) {
            saveOwner(owner, annotation, annotation.fecha, null)
          }
          // Cuando el propietario ya esta en la lista y la
          // última anotación es la inmeditamente anterior a esta 
          if (ownerExist.length > 0) {
            // se actualiza la ultima aparición de un propietario
            const storedOwner = ownerExist[0];
            storedOwner.lastAnnotation = annotation.numero;
          }

        }

        // revisar todas persona que no tienen fecha fin 
        const withoutFinish = ownerTimeline.filter((x) => x.finishDate === null);
        for (let index = 0; index < withoutFinish.length; index++) {
          const withoutOwner = withoutFinish[index];
          // TODO primer propietario
          // if (annotationCode === '1' && withoutOwner.legalCondition === '1' && annotationIndex !== 0) {
          //   console.log('entro codigos 111', withoutOwner);
          //   withoutOwner.finishDate = annotation.fecha;
          // }

          // se termina el dominio cuando hay un raspaso
          if (withoutPeople.filter((x) => x.includes(withoutOwner.nombre)).length > 0) {
            withoutOwner.finishDate = annotation.fecha;
          } 
          
        }
      }

      // Dejar la fecha inicial como la fecha final para los que no se encontraron
      for (let index = 0; index < ownerTimeline.length; index++) {
        const item = ownerTimeline[index];
        if (item.finishDate === null) {
          item.finishDate = item.initDate;
        }
      }

      // procesar la fecha de la tenencia
      for (let index = 0; index < ownerTimeline.length; index++) {
        const item = ownerTimeline[index];
        const initDate = item.initDate;
        item.initDate = new Date(parseInt(initDate.slice(0, 4)), parseInt(initDate.slice(5, 7)) - 1, parseInt(initDate.slice(8, 10)))
        const finishDate = item.finishDate;
        item.finishDate = new Date(parseInt(finishDate.slice(0, 4)), parseInt(finishDate.slice(5, 7)) - 1, parseInt(finishDate.slice(8, 10)))
        // dejar la fecha actual para el último titular
        if (item.lastAnnotation === ownerAnnotations[ownerAnnotations.length - 1].numero) {
          item.finishDate = new Date();
        }
      }

      return ownerTimeline;
    }
  },
  state: {
    currentAnnotations: [],
    // 1 - tradicion
    // 3 - limitaciones y afectaciones
    // 6 - falsa tradicion
    filterLegalNature: ['1', '3', '6', '9'],
    annotationCategories: [
      {
        name: 'TRADICION',
        color: 'light-green',
        hexColor: '#8BC34A', 
        startChar: '1',
        analysable: false,
      },
      {
        name: 'GRAVAMENES',
        color: 'amber',
        hexColor: '#FFC107',
        startChar: '2',          
        analysable: true,
      },
      {
        name: 'LIMITACIONES Y AFECTACIONES',
        color: 'orange',
        hexColor: '#FF9800',
        startChar: '3',          
        analysable: true,
      },
      {
        name: 'MEDIDAS CAUTELARES',
        color: 'red',
        hexColor: '#F44336',
        startChar: '4',          
        analysable: true,
      },     
      {
        name: 'TENENCIA',
        color: 'purple darken-1',
        hexColor: '#8E24AA',
        startChar: '5',          
        analysable: true,
      },
      {
        name: 'FALSA TRADICION',
        color: 'pink darken-2',
        hexColor: '#C2185B',
        startChar: '6',          
        analysable: true,
      },
      {
        name: 'CANCELACIONES',
        color: 'yellow lighten-2',
        hexColor: '#FFF176',
        startChar: '7',          
        analysable: false,
      },
      {
        name: 'CANCELACIONES',
        color: 'yellow lighten-2',
        hexColor: '#FFF176',
        startChar: '8',          
        analysable: false,
      },
      {
        name: 'OTROS',
        color: 'blue-grey lighten-1',
        hexColor: '#78909C',
        startChar: '9',          
        analysable: true,
      },
    ]
  }
};

export default annotations;
