<template lang="pug">
div(style="height:70vh")
  change-password-form
</template>

<script>
import ChangePasswordForm from '@/components/forms/ChangePasswordForm.vue';

export default {
  name: 'ChangePasswordView',
  components: {
    ChangePasswordForm,
  }
}
</script>
    