<template lang="pug">
div(class="container")
  div(id="cloud")
    span(class="shadow")
    div(class="rain")
      div(class="drop d1")
      div(class="drop d2")
      div(class="drop d3")
      div(class="drop d4")
      div(class="drop d5")
      div(class="drop d6")
      div(class="drop d7")
      div(class="drop d8")
      div(class="drop d9")
      div(class="drop d10")
      div(class="drop d11")
      div(class="drop d12")
      div(class="drop d13")
      div(class="drop d14")
      div(class="drop d15")
  div(
    class="text-center text-h5 font-weight-light"
    style="margin-top:160px") {{message}}
</template>

<script>

export default {
  name: 'CloudRain',
  props: {
    message: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped>
body {
  background: #00416a;
}

.container{
  width: 500px;
  height: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -250px;
  margin-left: -250px;
}

#cloud {
  width: 175px;
  height: 60px;
  background: #f2f9fe;
  background: -moz-linear-gradient(top,  #f2f9fe 5%, #d6f0fd 100%);
  background: -webkit-linear-gradient(top,  #f2f9fe 5%,#d6f0fd 100%);
  background: -o-linear-gradient(top,  #f2f9fe 5%,#d6f0fd 100%);
  background: -ms-linear-gradient(top,  #f2f9fe 5%,#d6f0fd 100%);
  background: linear-gradient(to top,  #f2f9fe 5%,#d6f0fd 100%);
  position: relative;
  margin: 120px auto 20px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  -webkit-animation: mover 3.5s infinite  alternate;
  animation: mover 3.5s infinite  alternate;
}

#cloud:after, div#cloud:before {
  content: '';
  position: absolute;
  z-indeX: -1;
  background: #f2f9fe;
}

#cloud:after {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  top: -25px;
  left: 25px;
}

#cloud:before {
  width: 90px;
  height: 90px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  top: -45px;
  right: 25px;
}

.shadow {
  width: 175px;
  position: absolute;
  bottom: -80px;
  background: black;
  z-index: -1;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 25px 8px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 0px 25px 8px rgba(0,0,0,0.4);
  box-shadow: 0px 0px 25px 8px rgba(0,0,0,0.4);
}

.rain {
  position : absolute;
  height : 80%;
  width : 100%;
  color: #002266;
}

.drop {
  position : absolute;
  background : linear-gradient(#000000, #ffffff);
  opacity : 2;
  top : 25px;
}

.d1 {
  left : 97%;
  width : 1%;
  height : 5%;
  animation : fall .94s linear 0.69s infinite;
}

.d2 {
  left : 18%;
  width : 1%;
  height : 5%;
  animation : fall .98s linear 0.57s infinite;
}
.d3 {
  left : 38%;
  width : 1%;
  height : 5%;
  animation : fall .66s linear .60s infinite;
}

.d4 {
  left : 64%;
  width : 1%;
  height : 5%;
  animation : fall .69s linear .76s infinite;
}

.d5 {
  left : 95%;
  width : 1%;
  height : 5%;
  animation : fall .75s linear .56s infinite;
}

.d6 {
  left : 65%;
  width : 1%;
  height : 5%;
  animation : fall .75s linear .92s infinite;
}

.d7 {
  left : 19%;
  width : 1%;
  height : 5%;
  animation : fall .92s linear .72s infinite;
}

.d8 {
  left : 55%;
  width : 1%;
  height : 5%;
  animation : fall .84s linear .99s infinite;
}

.d9 {
  left : 35%;
  width : 1%;
  height : 5%;
  animation : fall .79s linear .53s infinite;
}

.d10 {
  left : 83%;
  width : 1%;
  height : 5%;
  animation : fall .82s linear .97s infinite;
}

.d11 {
  left : 8%;
  width : 1%;
  height : 5%;
  animation : fall .75s linear .56s infinite;
}

.d12 {
  left : 1%;
  width : 1%;
  height : 5%;
  animation : fall .60s linear .59s infinite;
}

.d13 {
  left : 13%;
  width : 1%;
  height : 5%;
  animation : fall .5s linear .5s infinite;
}

.d14 {
  left : 13%;
  width : 1%;
  height : 5%;
  animation : fall .80s linear .63s infinite;
}

.d15 {
  left : 13%;
  width : 1%;
  height : 5%;
  animation : fall .68s linear .75s infinite;
}

@-webkit-keyframes fall {
  to {margin-top:150px; opacity:0;}
}
@keyframes fall {
  to {margin-top:150px; opacity:0;}
}

@-webkit-keyframes mover {
    0% { transform: translateY(1rem); }
    100% { transform: translateY(2.6rem); }
}
</style>
