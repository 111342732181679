<template lang="pug">
v-container
  h3 1- Datos de la persona o entidad que va a buscar
  v-row(class="text-center")
    v-col(cols="12" offset="")
      identification-preview
  h3 2- Ingrese los datos para el reporte
  v-row(class="text-center")
    v-col(cols="8" offset="2")
      report-preview
  h3 3- Seleccione las listas restrictivas
  v-row
    v-col(cols="8" offset="2")
      restrictive-list(v-if="isMounted" ref="listContainer")
  h3 4- Realizar la consulta
  v-row
    v-col(cols="6" offset="3")
      v-btn(
        class="white--text"
        :loading="loadingCancel"
        :disabled="loadingCancel"
        v-if="celeryTaskId !== ''"
        class="ma-2"
        color="red"
        @click="customActionCancel")
        v-icon(class="mr-1" dark) mdi-cancel
        | Cancelar      
      v-btn(
        v-if="reportGenerated"
        class="ma-2"
        color="warning"
        @click="REFRESH_SEARCH")
        v-icon(class="mr-1" dark) mdi-refresh
        | Otra consulta        
        template(v-slot:loader)
          span Loading
      v-btn(
        v-if="!reportGenerated"
        class="ma-2"
        color="info"
        :loading="reportLoading"
        :disabled="reportLoading"
        @click="makeSearch()")
        v-icon(class="mr-1" dark) mdi-send
        | Realizar consulta
        template(v-slot:loader)
          span Loading
      a(:href="docUrl" target="_blank")
        v-btn(
          v-if="reportGenerated"
          class="ma-2"
          color="success"
          @click="")
          v-icon(class="mr-1" dark) mdi-cloud-download
          | Descargar reporte
</template>
  
<script>
import celeryMixin from '@/mixins/celeryMixin.js';
import RestrictiveList from '@/components/restrictive_lists/RestrictiveList.vue';
import IdentificationPreview from '@/components/restrictive_lists/IdentificationPreview.vue';
import ReportPreview from '@/components/restrictive_lists/ReportPreview.vue';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import { staticUrl } from '@/env';

export default {
  name: 'RestrictiveLists',

  components: {
    RestrictiveList,
    IdentificationPreview,
    ReportPreview
  },

  mixins: [celeryMixin],

  data() {
    return {
      isMounted: false,
    };
  },

  computed: {
    ...mapState('restrictions', [
      'reportGenerated',
      'reportLoading',
      'reportLink',
      'selectedSources',
      'targetData',
      'naturalPerson',
      'sources',
      'celeryTaskId',
    ]),
    ...mapGetters('restrictions', ['activeSources', 'activeSourcesList']),
    docUrl() {
      return `${staticUrl}/media/${this.reportLink}`;
    }
  },

  async mounted() {
    await this.getReports();
    await this.getLists();
    this.isMounted = true;
    // realizar limpieza de la funcionalidad celery
    this.CHANGE_VALUE({
      varName: 'reportLoading',
      value: false
    });
    this.CHANGE_VALUE({
      varName: 'celeryTaskId',
      value: ''
    });
  },

  methods: {
    ...mapMutations('restrictions', ['REFRESH_SEARCH', 'CHANGE_VALUE', 'RESTART_SOURCES_ITEMS']),
    ...mapActions('restrictions', ['uploadSearch', 'getReports', 'getLists']),
    makeSearch() {
      // validar criterios cuando de consulta por persona juridica
      if (this.targetData.firstName === '' && this.targetData.identification === '' && !this.naturalPerson) {
        alert('Debe introducir la razón social de la empresa o la identificación');
        return;
      }
      // validar criterios cuando de consulta por persona natural
      if ((this.targetData.firstName === '' || this.targetData.firstSurname === '')
        && this.naturalPerson && this.targetData.identification === '') {
        alert('Debe introducir el primer nombre, el primer apellido o la identificación');
        return;
      }
      // validar que por lo menos haya una fuente elegida
      const validSources = this.selectedSources.map((x) => 
        this.activeSources.includes(x)).filter((x) => x === true);

      if (validSources.length === 0) {
        alert('Debe escoger por lo menos una lista restrictiva ACTIVA');
        return;
      }
      // validar que se haya introducido la fecha del documento en la lista
      // srvcnpc si el tipo de identificación es CC
      const validSourcesKeys = this.selectedSources.map((x) => this.activeSourcesList[x].key);
      if (validSourcesKeys.includes('SRVCNPC') && this.targetData.identificationType === 'CC' && this.targetData.identificationDate === '' ) {
        alert('Debe ingresar la fecha de expedición de la cédula en la lista srvcnpc');
        return;
      }
      // validar el tipo de identidicacion en las fuentes que lo requieren
      // además que esten dentro de las permitidas
      for (let index = 0; index < this.selectedSources.length; index++) {
        const source = this.selectedSources[index];
        try {
          if(this.selectedSources.includes(index) && source.identification_types.length > 0) {
            if (!source.identification_types.includes(this.targetData.identificationType) || this.targetData.identification === '') {
              alert(`Debe ingresar los datos de identificación o los tipos de identificacion permitidos para consultar la lista ${source.name}`);
              return;
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
      const realSources = this.$refs.listContainer.getSelectedKeys();
      this.uploadSearch(realSources);
    },
    // metodo de cancelar accion en componente
    async customActionCancel() {
      // ejecutar accion de cancelación preestablecida por el mixin
      await this.actionCancel();
      // limpiar estado en lista restrictivas
      this.RESTART_SOURCES_ITEMS();
    }
  },
};
</script>
  