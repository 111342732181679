const geos = {
  namespaced: true,

  state: {
    // estado que almacena los sistermas disponibles para colombia
    coordinateSystems: [
      {
        name: 'Bogota 1975',
        code: 'EPSG:4218',
        type: 'geo',
        proj4: '+proj=longlat +ellps=intl +towgs84=307,304,-318,0,0,0,0 +no_defs +type=crs',
      },
      {
        name: 'MAGNA-SIRGAS',
        code: 'EPSG:4686',
        type: 'geo',
        proj4: '+proj=longlat +ellps=GRS80 +no_defs +type=crs',
      },
      {
        name: 'WGS 84',
        code: 'EPSG:4326',
        type: 'geo',
        proj4: '+proj=longlat +datum=WGS84 +no_defs +type=crs',
      },
      {
        name: 'MAGNA-SIRGAS 2018 / Origen-Nacional',
        code: 'EPSG:9377',
        type: 'proj',
        proj4: '+proj=tmerc +lat_0=4 +lon_0=-73 +k=0.9992 +x_0=5000000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
      },
      {
        name: 'Bogota 1975 / Colombia Bogota zone',
        code: 'EPSG:21897',
        type: 'proj',
        proj4: '+proj=tmerc +lat_0=4.59904722222222 +lon_0=-74.0809166666667 +k=1 +x_0=1000000 +y_0=1000000 +ellps=intl +towgs84=307,304,-318,0,0,0,0 +units=m +no_defs +type=crs',
      },
      {
        name: 'Bogota 1975 / Colombia East',
        code: 'EPSG:21899',
        type: 'proj',
        proj: '+proj=tmerc +lat_0=4.59904722222222 +lon_0=-68.0809166666667 +k=1 +x_0=1000000 +y_0=1000000 +ellps=intl +towgs84=221.899,274.136,-397.554,1.361573e-05,-2.174431e-06,-1.36241e-05,-2.199943 +units=m +no_defs +type=crs',
      },
      {
        name: 'Bogota 1975 /  Colombia East Central zone',
        code: 'EPSG:21898',
        type: 'proj',
        proj4: '+proj=tmerc +lat_0=4.59904722222222 +lon_0=-71.0809166666667 +k=1 +x_0=1000000 +y_0=1000000 +ellps=intl +towgs84=307,304,-318,0,0,0,0 +units=m +no_defs +type=crs',
      },
      {
        name: 'Bogota 1975 / Colombia West zone',
        code: 'EPSG:21896',
        type: 'proj',
        proj4: '+proj=tmerc +lat_0=4.59904722222222 +lon_0=-77.0809166666667 +k=1 +x_0=1000000 +y_0=1000000 +ellps=intl +towgs84=307,304,-318,0,0,0,0 +units=m +no_defs +type=crs',
      },
      {
        name: 'Bogota 1975 / UTM zone 18N',
        code: 'EPSG:21818',
        type: 'proj',
        proj4: '+proj=utm +zone=18 +ellps=intl +towgs84=307,304,-318,0,0,0,0 +units=m +no_defs +type=crs'
      },
      {
        name: 'MAGNA-SIRGAS / Colombia Bogota zone',
        code: 'EPSG:3116',
        type: 'proj',
        proj4: '+proj=tmerc +lat_0=4.596200416666666 +lon_0=-74.07750791666666 +k=1 +x_0=1000000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs")'
      },
      {
        name: 'MAGNA-SIRGAS / Colombia East Central zone',
        code: 'EPSG:3117',
        type: 'proj',
        proj4: '+proj=tmerc +lat_0=4.596200416666666 +lon_0=-71,077507916666 +k=1 +x_0=1000000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
      },
      {
        name: 'MAGNA-SIRGAS / Colombia East zone',
        code: 'EPSG:3118',
        type: 'proj',
        proj4: '+proj=tmerc +lat_0=4.59620041666667 +lon_0=-68.0775079166667 +k=1 +x_0=1000000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
      },
      {
        name: 'MAGNA-SIRGAS / Colombia Far West zone',
        code: 'EPSG:3114',
        type: 'proj',
        proj4: '+proj=tmerc +lat_0=4.59620041666667 +lon_0=-80.0775079166667 +k=1 +x_0=1000000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
      },
      {
        name: 'MAGNA-SIRGAS / Colombia West zone',
        code: 'EPSG:3115',
        type: 'proj',
        proj4: '+proj=tmerc +lat_0=4.596200416666666 +lon_0=-77.07750791666666 +k=1 +x_0=1000000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
      },
      {
        name: '	WGS 84 / UTM zone 17N',
        code: 'EPSG:32617',
        type: 'proj',
        proj4: '+proj=utm +zone=17 +datum=WGS84 +units=m +no_defs +type=crs',
      },
      {
        name: '	WGS 84 / UTM zone 18N',
        code: 'EPSG:32618',
        type: 'proj',
        proj4: '+proj=utm +zone=18 +datum=WGS84 +units=m +no_defs +type=crs',
      },
      {
        name: '	WGS 84 / UTM zone 18S',
        code: 'EPSG:32718',
        type: 'proj',
        proj4: '+proj=utm +zone=18 +south +datum=WGS84 +units=m +no_defs +type=crs',
      },
      {
        name: '	WGS 84 / UTM zone 19N',
        code: 'EPSG:32619',
        type: 'proj',
        proj4: '+proj=utm +zone=19 +datum=WGS84 +units=m +no_defs +type=crs',
      },
      {
        name: '	WGS 84 / UTM zone 19S',
        code: 'EPSG:32719',
        type: 'proj',
        proj4: '+proj=utm +zone=19 +south +datum=WGS84 +units=m +no_defs +type=crs'
      },
    ],
    // capas base para los mapas leaflet
    layers: [
      {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        name: 'Open Street',
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        type: 'base',
        transparent: false,
      },
      {
        url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        name: 'Esri WorldImagery',
        attribution:
          'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
        type: 'base',
        transparent: false,
      },
      {
        url: 'https://geovisor.arkandha.net/geoserver/geonode/wms',
        name: 'Predios catastro',
        layers: 'ptp_rural_catastro_2017',
        attribution: 'ARkandha © 2022',
        type: 'overlay',
        transparent: true,
        format: 'image/png',
      },
    ],
  },

  getters: {
    getProj4: (state) => (code) => {
      return state.coordinateSystems.filter((x) => x.code === code)[0].proj4;
    }
  }
};

export default geos;
