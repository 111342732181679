const websocket = {
  namespaced: true,
  state: {
    connection: null,
  },
  mutations: {
    SOCKET_ON_OPEN(state, webSocket) {
      state.connection = webSocket;
      state.isConnected = true;
    },
    SOCKET_ON_CLOSE(state) {
      state.isConnected = false;
    },
    SET_MESSAGE(state, message) {
      state.message = message;
    },
  },
  actions: {
    sendMessage({ state }, event) {
      const parsedMessage = JSON.stringify(event);
      state.connection.send(parsedMessage);
    },
    socketMessage({ commit, dispatch, rootState }, message) {
      const appMessage = JSON.parse(message);
      const { eventType } = appMessage;
      switch (eventType) {
        /*
        ####################################
        ###  EVENTOS GENERALES SCRAPPER  ###
        ####################################
        */
        case 'TASK_TIMEOUT_FINISHED': {
          let module = 'vur';
          if (appMessage.taskType === 'CONSULT_RESTRICTIVE_LISTS'){
            module = 'restrictions';
          }
          const data = {
            taskId: appMessage.taskId,
            message: appMessage.error,
          }
          if (module === 'vur') {
            data.taskType = appMessage.taskType
          }
          dispatch(`${module}/taskTimeoutFinished`, data, { root: true });
          break;}
        case 'CELERY_TASK_FINISHED': {
            let module = 'vur';
            if (appMessage.taskType === 'CONSULT_RESTRICTIVE_LISTS'){
              module = 'restrictions';
            }
            if (rootState[module].celeryTaskId === appMessage.taskId) {
              commit(`${module}/CHANGE_VALUE`, {
                value: '',
                varName: 'celeryTaskId'
              }, { root: true });
            }
            break;}
        /*
        ####################################
        ####  EVENTOS FOLIO MATRICULA   ####
        ####################################
        */
        case 'UPDATE_FOLIO_STATUS':
          commit('files/UPDATE_PROGRESS', {
            progress: appMessage.progress,
            message: appMessage.message,
            fileId: appMessage.file_id
          }, { root: true });
          break;
        case 'FINISH_FOLIO':
          commit('files/FINISH_FOLIO', {
            folioId: appMessage.folio_id,
            message: appMessage.message,
            fileId: appMessage.file_id
          }, { root: true });
          break;
        case 'ERROR_FOLIO':
          commit('files/ERROR_FOLIO', {
            fileId: appMessage.file_id,
            message: appMessage.message,
          }, { root: true });
          break;
        /*
        ####################################
        ####  EVENTOS CERTIFICADO VUR   ####
        ####################################
        */
        case 'UPDATE_CERTIFICATE_STATUS':          
          commit('vur/UPDATE_PROGRESS', {
            progress: appMessage.progress,
            message: appMessage.message,
            certId: appMessage.file_id
          }, { root: true });
          break;
        case 'FINISH_CERTIFICATE':
          commit('vur/FINISH_CERTIFICATE', {
            folioId: appMessage.folio_id,
            message: appMessage.message,
            certId: appMessage.file_id
          }, { root: true });
          break;
        case 'ERROR_CERTIFICATE':
          commit('vur/ERROR_CERTIFICATE', {
            certId: appMessage.file_id,
            message: appMessage.message,
          }, { root: true });
          break;
        case 'FINISH_VUR_DOWNLOAD':
          commit('vur/FINISH_VUR_DOWNLOAD', null, { root: true });
          break;
        /*
        ##########################################
        ####  EVENTOS CONSULTA PROPIETARIOS   ####
        ##########################################
        */
       // evento para cargar predios propietario VUR
        case 'LOAD_OWNER_STATES':
          commit('vur/STOP_LOADING_VUR_OWNERS', null,
            { root: true });
          dispatch('vur/loadOwnerStates', {
            estates: appMessage.estates,
            message: appMessage.message,
          }, { root: true });
          break;
        // evento para mostrar el progreso en la lista del VUR
        case 'UPDATE_LIST_VUR_MESSAGE':
          commit('vur/UPDATE_LIST_VUR_MESSAGE', {
            icon: appMessage.icon,
            message: appMessage.message,
          }, { root: true });
          if (appMessage.stopLoading === true) {
            commit('vur/STOP_LOADING_VUR_OWNERS', null,
              { root: true });
          }
          break;
        // evento para indicar la ruta de soporte VUR
        case 'SUPPORT_VUR_FILE':
          if (appMessage.vurModule == 'OWNERS') {
            commit('vur/SUPPORT_VUR_FILE', {
              supportType: appMessage.supportType,
              path: appMessage.path,
            }, { root: true });
          }
          break;
        // evento para indicar que ha ocurrido un error al descargar los propietarios
        case 'ERROR_VUR_OWNER':
          commit('ui/SHOW_ALERT', {
            alertMessage: appMessage.error,
            alertType: 'error'
          }, { root: true });
          commit('vur/ERROR_VUR_OWNER', {error: appMessage.error},
            { root: true });
          break;
        /*
        ####################################
        ### EVENTOS LISTAS RESTRICTIVAS  ###
        ####################################
        */
        case 'REPORT_GENERATED':          
          commit('restrictions/REPORT_GENERATED', {
            link: appMessage.file_name,
          }, { root: true });
          break;
        case 'REPORT_ERROR':          
          commit('restrictions/REFRESH_SEARCH', null, { root: true });
          commit('ui/SHOW_ALERT', {
            alertMessage: 'Ocurrio un error al conseguir las fuentes',
            alertType: 'error'
          }, { root: true });
          break;
        case 'UPDATE_SOURCE':
          commit('restrictions/UPDATE_SOURCE', {
            source: appMessage.source,
            error: appMessage.error,
            locatedName: appMessage.located_name,
            locatedId: appMessage.located_id,
          }, { root: true });          
          break;
          /*
          ####################################
          ####    EVENTOS FAMILIA VUR     ####
          ####################################
          */
          // Evento que indica que la familia vur se termino de descargar
          case 'FINISH_VUR_FAMILY':
            // verificar que la pantalla en la que se ejecuta el evemto tenga la raiz correspondiente
            if (appMessage.rootFolio === rootState.folios.graphFolioNumber) {
              commit('ui/SHOW_ALERT', {
                alertMessage: appMessage.message,
                alertType: 'success',              
              }, { root: true });        
              commit('vur/FINISH_VUR_FAMILY', null, { root: true });
              //actualizar grafo desde cuado la familia se haya completado
              dispatch('folios/searchFamily', {
                propertyRecord: appMessage.rootFolio,
              }, { root: true });
            }
            break;
          // Evento que indica que ocurrio un error en la familia VUR
          case 'ERROR_VUR_FAMILY':
            // verificar que la pantalla en la que se ejecuta el evemto tenga la raiz correspondiente
            if (appMessage.rootFolio === rootState.folios.graphFolioNumber) {
              commit('ui/SHOW_ALERT', {
                alertMessage: appMessage.error,
                alertType: 'error'
              }, { root: true });        
              commit('vur/ERROR_VUR_FAMILY', null, { root: true });
            }
            break;
          // Evento para agragar un nodo a la familia registral
          case 'LOAD_FAMILY_NODE':
            // verificar que la pantalla en la que se ejecuta el evemto tenga la raiz correspondiente
            if (appMessage.rootFolio === rootState.folios.graphFolioNumber) {
              commit('folios/LOAD_FAMILY_NODE', {
                fmiData: appMessage.fmiData,
                // relationType: appMessage.relationType,
              }, { root: true });
            }        
            break;
          // Evento para agregar un mensaje de log en familia VUR
          case 'ADD_MESSAGE_LOG':
            // verificar que la pantalla en la que se ejecuta el evemto tenga la raiz correspondiente
            if (appMessage.rootFolio === rootState.folios.graphFolioNumber) {
              commit('vur/ADD_MESSAGE_LOG', {
                fmiData: appMessage.fmiData,
              }, { root: true });
            }       
            break;
        default:
          break;
      }
    },
  }  
}

export default websocket;
