<template lang="pug">
v-card(
  class="mx-auto"
  max-width="80%")
  //- link escondido para descargar el reporte
  a(ref="downloadLink" hidden download="massive_report.xlsx")
  v-list-item(two-line)
    v-list-item-content
      v-list-item-title(class="text-h5") Calculadora Geodésica OnLine
  v-divider
  v-row(class="mt-2 mx-3")
    //- Visor geografico de las coordenadas
    v-col(cols="7")
      div(style="height: 500px; width: 100%")
        l-map(
          @click="mapClick"
          :zoom="zoom"
          :options="mapOptions"
          ref="myMap"
          :center="center"
          @update:center="centerUpdate"
          @update:zoom="zoomUpdate"
          @ready="lefletMapReady()")
          //- polygono de los linderos
          l-polygon(
            ref="polygon"
            :lat-lngs="polygonCoords"
            @ready="polygonReady"
            :color="polygonColor"
            :weight="4"
            :fill-color="polygonColor")
          //- los marcadores de las coordenadas
          l-marker(
            v-for="c in coordinates"
            :icon="icon"
            :lat-lng="latLng(c.lat, c.lon)"
            :key="`${c.lat}-${c.lon}`")
          //- control de las capas del mapa
          l-control-layers
          //- capas base y superpuestas del mapa
          l-wms-tile-layer(
            v-for="l in layers"
            :key="l.name"
            :base-url="l.url"
            :layer-type="l.type"
            :layers="l.layers"
            :transparent="l.transparent"
            format="image/png"
            :name="l.name")
      v-switch(
        v-model="enableDraw"
        inset
        :label="`Dibujar coordenadas: ${enableDraw ? 'SI' : 'NO'}`")
    v-col(class="text-left" cols="5")
      
      span(class="text-left")
        v-icon(color="red" size="30") mdi-numeric-1-circle
        | Seleccione sistema de entrada (aplica para coordenadas cargadas)
      crs-select(v-model="inputCrs")
      span(class="text-left")
        v-icon(color="red" size="30") mdi-numeric-2-circle
        | Seleccione sistema de destino
      crs-select(v-model="outputCrs")
      span(class="text-left")
        v-icon(color="red" size="30") mdi-numeric-3-circle
        | Introduzca coordenadas de forma manual
        | {{ `${inputCrs === null ? '(Debe seleccionar el sistema de entrada)' : 'ej: 7.452636'}` }}
      v-row
        v-col(cols="5")
          v-text-field(
            v-model="yCoord"
            :disabled="inputCrs === null"
            dense
            :label="yLabel"
            filled)
        v-col(cols="5")
          v-text-field(
            v-model="xCoord"
            :disabled="inputCrs === null"
            dense
            :label="xLabel"
            filled)
        v-col(cols="2")
          v-btn(
            class="mx-2"
            title="Agregar coordenada"
            @click="addManualCoord"
            fab
            dark
            color="green")
            v-icon(dark) mdi-plus
      span(class="text-left")
        v-icon(color="red" size="30") mdi-numeric-4-circle
        | Puede cargar coordenadas desde un archivo Excel (Los datos deben
        | conmenzar desde la primera fila, en la columna <b>A</b> la Norte o latitud
        | y en la columna <b>B</b> la Este o longitud)
      v-row(class="mt-2")
        v-col(cols="9")
          v-file-input(
            filled
            dense
            class="mx-4"
            v-model="excelFile"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            placeholder="Seleccione un archivo"
            prepend-icon="mdi-file-excel"
            ref="fileExcel"
            label="Listado números de coordenadas")
        v-col(cols="3")
          v-btn(
            class="mx-2"
            small
            title="Cargar coordenadas"
            @click="addMultipleCoord"
            dark
            color="green")
            | Cargar
  v-divider(class="my-3")
  //- Sección con la tabla de coordenadas
  coordinates-preview(
    class="mx-2"
    :coordinates="coordinates"
    @deleteCoordinate="deleteCoord"
    @focusCoordinate="focusCoord")
  v-divider(class="mt-3")

  v-card-actions
    v-btn(
      small
      color="red"
      class="ma-2 white--text"
      @click="coordinates = []")
      v-icon(left dark) mdi-broom
      | limpiar puntos
    v-spacer
    v-btn(
      small
      title="Descargar excel coordenadas"
      color="info"
      class="ma-2 white--text"
      @click="download")
      v-icon(left dark) mdi-download
      | Descargar Excel
    a(href="" id="export" ref="exportLink" hidden)
    v-btn(
      @click="downloadGeojson"
      :loading="false"
      title="Descargar GeoJSON de los puntos"
      class="info"
      small
      color="white")
      v-icon(left dark) mdi-map-marker-multiple-outline
      | Descargar geoson
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { latLng } from "leaflet";
import { makeid, downloadExcel } from '@/utils.js';
import mapMixin from '@/mixins/mapMixin.js';
import ExcelFileAction from '@/components/ui/ExcelFileAction';
import { excel2json } from 'js2excel';
import ReportPreview from '@/components/reports_cmp/ReportPreview';
import CrsSelect from '@/components/geos/CrsSelect';
import proj4 from 'proj4';
import {
  LMap,
  LWMSTileLayer,
  LControlLayers,
  LControl,
  LControlScale,
  LMarker,
  LPolygon,
  LTooltip,
} from "vue2-leaflet";
import CoordinatesPreview from '@/components/geos/CoordinatesPreview';

export default {
  name: 'CoordinateManager',

  components: {
    ExcelFileAction,
    ReportPreview,
    CrsSelect,
    LMap,
    LControlLayers,
    'l-wms-tile-layer': LWMSTileLayer,
    LControl,
    LControlScale,
    CoordinatesPreview,
    LMarker,
    LPolygon,
    LTooltip
  },

  mixins: [mapMixin],

  props: {
    // propiedad con coordenadas iniciales
    initialCoordinates: {
      type: Array,
      default: () => []
    },
    // propiedad para el sistema de referencia origen inicial
    initialOriginSrs: {
      type: String,
      default: ''
    },
    // propiedad para el sistema de referencia destino inicial
    initialTargetSrs: {
      type: String,
      default: ''
    },
    // propiedad para agregar el poligono inicial en caso linderos
    addInitialPolygon: {
      type: Boolean,
      default: false
    },
    // propiedad que indica si todas las funcionalidades estarán activas
    modeActive: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    // el arcghivo excel con los folios
    file: null,
    // estado que almacena el crs de entrada
    inputCrs: null,
    // estado que almacena el crs de entrada 
    outputCrs: null,
    // indica si se encontro error en el archivo excel
    rules: [
      value => !value || value.size < 2000000 || 'File size should be less than 2 MB!',
    ],
    // lista de objetos con las coordenadas
    coordinates: [],
    // coordenadas del polygono
    polygonCoords: [],
    // indica si se agregan coordenadas haciendo click
    enableDraw: true,
    // etiqueta de la coordenada X
    xLabel: 'Este',
    // etiqueta de la coordenada Y
    yLabel: 'Norte',
    // coordenada x del cuadro de texto
    xCoord: '',
    // coordenada y del cuadro de texto
    yCoord: '',
    // variable con el archivo excel
    excelFile: null
  }),

  computed: {
    ...mapGetters('geos', ['getProj4']),
    ...mapState('geos', {
      systems:'coordinateSystems',
      layers: 'layers'
    }),
  },

  watch: {
    initialCoordinates: {
      handler() {
        this.renderInitialData();
      }
    },
    inputCrs: {
      handler(newVal) {
        if (newVal === null) {
          this.xLabel = 'Este';
          this.yLabel = 'Norte';
        } else {
          const crs = this.systems.filter((x) => x.code === newVal)[0];
          this.xLabel = crs.type === 'geo' ? 'Longitud' : 'Este';
          this.yLabel = crs.type === 'geo' ? 'Latitud' : 'Norte';
        }
      },
    }
  },

  mounted() {
    // limpiar los datos
    this.coordinates = [];
    this.renderInitialData();
  },

  methods: {
    // metodo ṕara agregar una coordenada
    addCoordinate(coordinate, inputCrs, outputCrs) {
      // agragar proyeccion origen a proj4
      proj4.defs(inputCrs, this.getProj4(inputCrs));
      // agragar proyeccion destino a proj4
      proj4.defs(outputCrs, this.getProj4(outputCrs));
      // crear la transformacion
      const transformation = (coords) => proj4(inputCrs, outputCrs).forward(coords, true);
      const coords = transformation(coordinate);
      // variable para almacenar las coordenadas geograficas leaflet
      let coordsGeo = coords;
      if (outputCrs !== 'EPSG:4326') {
        const transformation4326 = (coords) => proj4(inputCrs, 'EPSG:4326').forward(coords, true);
        coordsGeo = transformation4326(coordinate);
      }
      // gernera el nombre de la coordenadas
      let name = '';
      let nameExists = true;
      let initialIndex = this.coordinates.length + 1;
      while (nameExists) {
        name = `VERTICE ${initialIndex}`;
        nameExists = this.coordinates.filter((x) => x.name == name).length > 0;
        initialIndex++;
      }
      const coord = {
        id: makeid(8),
        name,
        xOrigin: coordinate[0],
        yOrigin: coordinate[1],
        crsOrigin: inputCrs,
        xTarget: coords[0],
        yTarget: coords[1],
        crsTarget: outputCrs,
        lat: coordsGeo[1],
        lon: coordsGeo[0],
      };
      this.coordinates.push(coord);
      return coord;
    },
    // metodo para cargar los datos inciales
    renderInitialData() {
      // this.coordinates = [];
      if (this.initialOriginSrs !== '') {
        this.inputCrs = this.initialOriginSrs;
      }
      if (this.initialTargetSrs !== '') {
        this.outputCrs = this.initialTargetSrs;
      }
      // procesar las coordenadas iniciales
      for (let index = 0; index < this.initialCoordinates.length; index++) {
        const coordinate = this.initialCoordinates[index];
        this.addCoordinate(coordinate, this.inputCrs, this.outputCrs);
      }
      if (this.addInitialPolygon && this.coordinates.length >= 3) {
        const firstPoint = this.coordinates[0];
        const lastPoint = this.coordinates[this.coordinates.length - 1];
        let polygonCoords = [];
        polygonCoords = this.coordinates.map((x) => [x.lat, x.lon]);
        // si las coordenadas del punto incial son difrentes a las del punto final
        // agregar la primera coordenada para cerar el polygono
        if (firstPoint.lat !== lastPoint.lat && firstPoint.lon !== lastPoint.lon) {
          polygonCoords.push([firstPoint.lat, firstPoint.lon]);
        }
        this.polygonCoords = polygonCoords;
      }
    },
    // metodo para agregar coordena con click en el mapa
    mapClick(e) {
      if (this.enableDraw) {
        if (this.outputCrs === null) {
          alert('¡Debe seleccionar un sistema de coordenadas de destino!');
          return;
        }
        const { lat, lng } = e.latlng;
        this.addCoordinate([lng, lat], 'EPSG:4326', this.outputCrs);
      }
    },
    // metodo para eliminar una coordenada se lanza desde la tabla
    deleteCoord(coordId) {
      this.coordinates = this.coordinates.filter((x) => x.id !== coordId);
    },
    // metodo para centar una coordenada desde la tabla
    focusCoord(coord) {
      this.map.panTo(latLng(coord.lat, coord.lon));
    },
    // metodo para generar el archivo excel con las coordenadas
    download() {
      if (this.coordinates.length === 0) {
        alert('¡No hay coordenadas en el mapa para exportar!');
        return;
      }
      // hacer copia profunda de coordenadas 
      const coordinates = JSON.parse(JSON.stringify(this.coordinates));
      // eslint-disable-next-line
      var newCoordinates = coordinates.map(({ id, lat, lon, ...rest }) => rest);
      downloadExcel(newCoordinates, 'coords');
    },
    // metodo para adicionar una coordenada de froma manual
    addManualCoord() {
      if (this.outputCrs === null) {
        alert('¡Debe seleccionar un sistema de coordenadas de destino!');
        return;
      }
      const regex = /-?[0-9]{1,7}[,.]?[0-9]{0,1}/;
      if (!regex.test(this.xCoord) || !regex.test(this.yCoord)) {
        alert('¡Las coordenadas ingresadas no tienen el formato adecuado!');
        return;
      }
      const x = parseFloat(this.xCoord.replace(',', ''));
      const y = parseFloat(this.yCoord.replace(',', ''));
      const coord = this.addCoordinate([x, y], this.inputCrs, this.outputCrs);
      // limpiar los datos de la coordenada
      this.xCoord = '';
      this.yCoord = '';
      // centrar el mapa a la coordenada en cuestion
      this.map.panTo(latLng(coord.lat, coord.lon));
    },
    // metodo para cargar multiples coordenadas
    addMultipleCoord() {
      if (this.excelFile === null) {
        alert('¡Debe cargar un archivo!');
        return;
      }
      if (this.outputCrs === null || this.inputCrs === null) {
        alert('¡Debe seleccionar ambos sistemas de coordenadas!');
        return;
      }
      // encontrar el sistema de entrada
      const crs = this.systems.filter((x) => x.code === this.inputCrs)[0];
      excel2json([this.excelFile], (data) => {
        for (let index = 0; index < data['Hoja1'].length; index++) {
          const element = data['Hoja1'][index];
          const coordinate = Object.values(element).map((x) => parseFloat(x));
          if (crs.type === 'proj') {
            coordinate.reverse();
          }
          this.addCoordinate(coordinate, this.inputCrs, this.outputCrs);
        }
      }, 'excel2json')
    },
    // metodo para descargar un geojson con los puntos y el poligono del mapa
    downloadGeojson() {
      if (this.coordinates.length === 0) {
        alert('¡No hay coordendas en el mapa!');
        return;
      }
      const basicJson = {
        type: 'FeatureCollection',
        crs: {
          type: "name",
          properties: { 
            name: 'urn:ogc:def:crs:OGC:1.3:CRS84'
          }
        },
        features: [
        ]
      };
      // agregar los datos de los puntos
      const points = [];
      for (let index = 0; index < this.coordinates.length; index++) {
        const element = this.coordinates[index];
        points.push(
          {
            type: 'Feature',
            properties: {
              nombre: element.name,
              latitud: element.lat,
              longitud: element.lon,
              x_trans: element.xTarget,
              y_trans: element.yTarget
            },
            geometry: {
              type: 'Point',
              coordinates: [element.lon, element.lat]
            }
          }
        );
      }
      // basicJson.features[0].geometry.geometries = points;
      basicJson.features = points;
      const jsonFile  = JSON.stringify(basicJson, null, 2);
      const today = new Date();
      let formatDate = today.toISOString().replace('T', '_').replace('Z', '');
      var convertedData = 'application/json;charset=utf-8,' + encodeURIComponent(jsonFile);
      document.getElementById('export').setAttribute('href', 'data:' + convertedData); 
      document.getElementById('export').setAttribute('download',
        `coordenadas_geograficas_${formatDate}.geojson`);
      this.$refs.exportLink.click();
    },
    latLng
  }
};
</script>
